@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700,900");
@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700,900");
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video { margin: 0; padding: 0; border: 0; vertical-align: baseline; }

html { -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; }

img, fieldset, a img { border: none; }

input[type="text"], input[type="email"], input[type="tel"], textarea { -webkit-appearance: none; }

input[type="submit"], button { cursor: pointer; }

input[type="submit"]::-moz-focus-inner, button::-moz-focus-inner { padding: 0; border: 0; }

textarea { overflow: auto; }

input, button { margin: 0; padding: 0; border: 0; }

div, input, textarea, select, button, h1, h2, h3, h4, h5, h6, a, span, a:focus { outline: none; }

ul, ol { list-style-type: none; }

table { border-spacing: 0; border-collapse: collapse; width: 100%; }

html { box-sizing: border-box; }

*, *:before, *:after { box-sizing: inherit; }

@media (prefers-reduced-motion: reduce) { * { transition: none !important; } }

@font-face { font-family: second; src: url("../fonts/Verveine-Regular.woff2") format("woff2"), url("../fonts/Verveine-Regular.woff") format("woff"); font-weight: normal; font-style: normal; font-display: swap; }

/* Slider */
.slick-slider { position: relative; display: block; box-sizing: border-box; -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; -ms-touch-action: pan-y; touch-action: pan-y; -webkit-tap-highlight-color: transparent; }

.slick-list { position: relative; overflow: hidden; display: block; margin: 0; padding: 0; }

.slick-list:focus { outline: none; }

.slick-list.dragging { cursor: pointer; cursor: hand; }

.slick-slider .slick-track, .slick-slider .slick-list { -webkit-transform: translate3d(0, 0, 0); -moz-transform: translate3d(0, 0, 0); -ms-transform: translate3d(0, 0, 0); -o-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0); }

.slick-track { position: relative; left: 0; top: 0; display: block; margin-left: auto; margin-right: auto; }

.slick-track:before, .slick-track:after { content: ""; display: table; }

.slick-track:after { clear: both; }

.slick-loading .slick-track { visibility: hidden; }

.slick-slide { float: left; height: 100%; min-height: 1px; display: none; }

[dir="rtl"] .slick-slide { float: right; }

.slick-slide img { display: block; }

.slick-slide.slick-loading img { display: none; }

.slick-slide.dragging img { pointer-events: none; }

.slick-initialized .slick-slide { display: block; }

.slick-loading .slick-slide { visibility: hidden; }

.slick-vertical .slick-slide { display: block; height: auto; border: 1px solid transparent; }

.slick-arrow.slick-hidden { display: none; }

@font-face { font-family: second; src: url("../fonts/Verveine-Regular.woff2") format("woff2"), url("../fonts/Verveine-Regular.woff") format("woff"); font-weight: normal; font-style: normal; font-display: swap; }

.loader { background-color: #1b1b1b; position: fixed; z-index: 200; overflow: hidden; top: 0; left: 0; right: 0; bottom: 0; display: flex; justify-content: center; align-items: center; }

.loader__inside { display: flex; flex-direction: column; align-items: center; position: relative; }

.loader .countLoad { height: 80px; width: 80px; border-radius: 50%; display: flex; align-items: center; justify-content: center; border: 1px solid dodgerblue; }

@media only screen and (max-width: 767px) { .loader .countLoad { width: 60px; height: 60px; } }

@media only screen and (max-width: 767px) { .loader #js-count { font-size: 14px; } }

.loader #js-count::after { content: '%'; }

.loader__anim { position: relative; width: 350px; margin-top: 30px; }

@media only screen and (max-width: 767px) { .loader__anim { width: 250px; margin-top: 20px; } }

.loader__anim-img { position: absolute; max-width: 100%; width: 100%; left: 0; bottom: 0; max-height: initial; }

.loader__anim-wrap { overflow: hidden; position: absolute; bottom: 7px; left: 0; width: 100%; }

.loader__logo { max-width: 165px; height: 68px; margin-top: 20px; }

@media only screen and (max-width: 767px) { .loader__logo { max-width: 120px; height: auto; } }

@media only screen and (min-width: 1024px) { .super-wrap { overflow: hidden; width: 100vw; height: 100vh; position: relative; } }

body { color: white; }

body { background-color: #393939; font-size: 23px; line-height: 1.4; font-family: "Roboto", sans-serif; }

img { max-width: 100%; }

@media only screen and (min-width: 768px) { body .mobile { display: none; } }

@media only screen and (max-width: 767px) { body .tablet { display: none; } }

@media only screen and (min-width: 1025px) { body .tablet { display: none; } }

@media only screen and (max-width: 1024px) { body .desctop { display: none; } }

@media only screen and (max-width: 767px) { body .to-top { display: none; } }

@media only screen and (min-width: 1024px) { body .to-bottom { display: none; } }

@media only screen and (min-width: 1279px) { body .big { display: none; } }

a { text-decoration: none; }

.main { position: relative; }

.wrapper { background-size: 200% 100vh; background-position: 50% 0; background-attachment: fixed; }

@media only screen and (min-width: 768px) { .wrapper { background-size: cover; background-position: 0 0; } }

@media (min-width: 1024px) and (max-width: 1050px) { .wrapper { background-size: 100vw 100vh; background-repeat: repeat; } }

@media only screen and (min-width: 1024px) { .wrapper { width: 100vw; height: 100vh; overflow: hidden; } }

@media only screen and (max-width: 1023px) { .wrapper { overflow-x: hidden; overflow-y: auto; } }

.icon { display: inline-block; }

.fontV { font-family: second, roboto, arial, sans-serif; }

/*btns prev & next */
.btn-icons, .btn-icon, .btn-text-icon { display: block; position: absolute; background-color: transparent; border: none; }

.btn-icons img, .btn-icon img, .btn-text-icon img { width: 50px; display: inline-block; }

.btn-text-icon .bottom-icon { display: inline-block; vertical-align: middle; }

.btn-text-icon .left-text, .btn-text-icon .right-text { display: inline-block; padding-top: 9px; font-size: 22px; font-weight: 700; color: #fff; padding-bottom: 10px; margin-left: 15px; }

/*rocket */
.rocket { position: absolute; width: 125px; }

/*scroll btn */
.scroll-icon { display: block; margin: auto; max-width: 200px; top: calc(50% - 35px); right: 30px; position: absolute; }

@media only screen and (min-width: 1024px) { .scroll-icon:hover { cursor: pointer; }
  .scroll-icon:hover span { opacity: 0.77; } }

.scroll-icon span { display: inline-block; vertical-align: middle; color: white; transition: opacity 0.3s ease; }

.scroll-icon span:first-child { width: 70px; height: 70px; }

.scroll-icon span:first-child > svg { width: 100%; height: 100%; fill: white; }

.scroll-icon span:last-child { margin-left: 14px; font-size: 32px; }

.text-center { text-align: center; }

.btn-under.cloned { opacity: 0; }

.prev-page-under-6 { left: 30px; bottom: 18%; }

.prev-page-under-6 .bottom-icon img { transform: rotate(-90deg); }

@media only screen and (min-width: 1024px) { .prev-page-under-6 { left: -200px; } }

@media only screen and (max-width: 1023px) { .prev-page-under-6 { bottom: 100vw; } }

@media only screen and (max-width: 1023px) { .prev-page-under-6 { display: none; } }

.prev-page-under-7 { left: 30px; bottom: calc(50vh - (0.39 * 100vw) / 2.1); text-align: left; z-index: 6; }

@media (min-width: 1024px) and (max-width: 1025px) { .prev-page-under-7 { bottom: 17%; } }

.prev-page-under-7 .top-icon img { width: 120px; }

.prev-page-under-7 .bottom-icon img { transform: rotate(-90deg); }

@media only screen and (max-width: 1023px) { .prev-page-under-7 { bottom: auto; top: 453px; left: 50px; }
  .prev-page-under-7 .bottom-icon img { transform: rotate(-5deg); } }

@media only screen and (max-width: 1023px) { .prev-page-under-7 { display: none; } }

.prev-page-under-8 { z-index: 6; bottom: 7%; left: 30px; text-align: left; }

@media only screen and (max-width: 1023px) { .prev-page-under-8 { display: flex; flex-flow: row wrap; text-align: left; bottom: 26%; } }

@media only screen and (max-width: 1023px) { .prev-page-under-8 { display: none; } }

@media (min-width: 1500px) and (max-width: 1600px) and (max-height: 750px) { .prev-page-under-8 { display: flex; flex-flow: row wrap; }
  .prev-page-under-8 .left-text { display: block; flex: 1 1 100%; max-width: 100%; order: -1; } }

.prev-page-under-8 .bottom-icon { margin-bottom: 10px; }

.prev-page-under-8 .bottom-icon img { transform: rotate(-101deg); }

@media only screen and (max-width: 1023px) { .prev-page-under-8 .bottom-icon { order: 5; flex: 1 1 100%; } }

.prev-page-under-8 .top-icon { margin-left: -10px; }

@media only screen and (max-width: 1023px) { .prev-page-under-8 .top-icon { margin-left: -10px; margin-bottom: 12px; } }

.prev-page-under-8 .top-icon img { width: 120px; }

.prev-page-under-8 .left-text { display: inline-block; margin-left: 10px; padding-top: 9px; font-size: 22px; font-weight: 700; color: #fff; padding-bottom: 10px; margin-left: 15px; font-family: second, roboto, arial, sans-serif; }

@media only screen and (max-width: 1370px) { .prev-page-under-8 { bottom: 15%; }
  .prev-page-under-8 .bottom-icon, .prev-page-under-8 .top-icon { display: inline-block; vertical-align: middle; }
  .prev-page-under-8 .top-icon { margin-left: 10px; } }

.prev-page-under-8 .bottom-icon { display: inline-block; vertical-align: middle; }

.hidden { display: none !important; }

@media only screen and (max-width: 1023px) { .people-slider-wrapper, .portfolio-slider-wrapper { display: block; position: fixed; width: 100%; height: 100%; min-height: 100vh; top: 0; left: 0; background-image: url("../../img/bg-min.jpg"); background-size: cover; background-position: 0 0; transform: translateX(-100%); }
  .people-slider-wrapper.open, .portfolio-slider-wrapper.open { transform: translateX(0%); z-index: 102; } }

/*transforms*/
@media only screen and (min-width: 1024px) { #fullpage { position: relative; transform: translate3d(0, 0, 0); transition: transform .8s ease-in-out; width: 100vw; height: 100vh; }
  .start-fullpage .section.screen { position: absolute; }
  .start-fullpage .section.screen.first-screen { left: 0; top: 0; }
  .start-fullpage .section.screen.second-screen { left: 100%; top: 0; }
  .start-fullpage .section.screen.third-screen { left: 100%; top: 100%; }
  .start-fullpage .section.screen.four-screen { left: 100%; top: 200%; }
  .start-fullpage .section.screen.five-screen { left: 100%; top: 300%; }
  .start-fullpage .section.screen.six-screen { left: 200%; top: 300%; }
  .start-fullpage .section.screen.seven-screen { left: 300%; top: 300%; }
  .start-fullpage .section.screen.eight-screen { left: 300%; top: 400%; }
  .start-fullpage .section.screen.nine-screen { left: 400%; top: 400%; } }

@media only screen and (max-width: 1023px) { #fullpage { transform: none !important; } }

@media only screen and (max-width: 1023px) { html { overflow: auto; }
  html body { height: auto; }
  html .wrapper { height: auto; overflow-x: hidden; } }

.p10 { display: none; }

@media (min-width: 1280px) and (max-width: 1300px) { .p10 { display: block; } }

@keyframes move-vert { 0% { transform: translateY(0%); }
  12% { transform: translateY(0%); }
  12.5% { transform: translateY(20%); }
  16.5% { transform: translateY(0%); }
  15% { transform: translateY(0%); }
  64% { transform: translateY(0%); }
  65% { transform: translateY(20%); }
  69% { transform: translateY(0%); }
  0% { transform: translateY(0%); } }

.call-menu { width: 43px; height: 33px; position: fixed; top: 25px; left: 20px; z-index: 101; background: none; border: none; }

@media only screen and (max-width: 767px) { .call-menu { display: none; } }

@media only screen and (min-width: 1024px) { .call-menu { display: none; } }

.call-menu span { position: absolute; left: 0; top: 50%; transform: translateY(-50%); width: 100%; height: 5px; background-color: #fff; transition: background-color 0.2s ease; }

.call-menu i { position: absolute; left: 0; width: 100%; height: 5px; background-color: #fff; }

.call-menu i:first-child { top: -15px; }

.call-menu i:last-child { bottom: -15px; }

.tablet-top-logo { width: 185px; height: 34px; position: fixed; top: 20px; left: 120px; z-index: 99; display: none; }

@media (min-width: 768px) and (max-width: 1023px) { .tablet-top-logo { display: block; } }

.tablet-top-logo img { max-width: 100%; }

.menu-active .call-menu span { background-color: transparent; }

.navigation { display: flex; flex-flow: column wrap; position: fixed; z-index: 100; left: 0; }

@media only screen and (max-width: 767px) { .navigation { bottom: 0; } }

@media only screen and (min-width: 768px) { .navigation { top: 0; background-color: rgba(0, 0, 0, 0.65); width: 255px; padding: 9vh 0px 50px 30px; height: 100%; } }

@media (min-width: 768px) and (max-width: 1023px) { .navigation { transform: translateX(-100%); } }

@media only screen and (max-height: 800px) { .navigation { padding: 50px 0 50px 30px; } }

@media only screen and (max-height: 750px) { .navigation { padding: 50px 0 50px 30px; } }

@media (min-width: 735px) and (max-width: 800px) { .navigaton__menu { margin: auto; } }

@media only screen and (max-height: 730px) { .navigaton__menu { margin-top: 0; } }

.navigaton__logo { margin-bottom: 65px; padding-right: 30px; max-width: 100%; text-align: center; }

.navigaton__logo img { display: inline-block; max-width: 165px; height: 68px; }

@media only screen and (max-height: 750px) { .navigaton__logo { margin-bottom: 5vh; } }

.menu__inner { display: flex; width: 100%; flex-wrap: wrap; user-select: none; }

@media only screen and (max-width: 767px) { .menu__inner { flex-flow: row nowrap; background-color: #1D1D20; position: fixed; bottom: 0; left: 0; } }

.menu__item { position: relative; }

@media only screen and (max-width: 767px) { .menu__item .icon-wrap { display: block; margin: auto; } }

.menu__item .icon-wrap { width: 37px; height: 37px; text-align: center; position: relative; margin-right: 18px; }

@media only screen and (min-width: 768px) { .menu__item .icon-wrap { display: inline-block; vertical-align: middle; } }

.menu__item .icon-wrap svg { display: inline-block; max-width: 100%; max-height: 32px; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); }

.menu__item .icon-white { fill: #fff; transition: fill 0.3s ease; }

.menu__item::after { content: none; }

@media only screen and (min-width: 768px) { .menu__item::after { content: ""; position: absolute; width: 20px; height: 25px; display: block; right: -10px; top: 0; opacity: 0; transform: translateX(-15px); background-image: url("../img/apple-image.png"); transition: transform 0.3s ease 0.15s, opacity 0.3s ease; } }

@media only screen and (min-width: 768px) { .menu__item.is-active .icon-white { fill: #EF436E; }
  .menu__item.is-active .menu__link { color: #EF436E; }
  .menu__item.is-active .menu__link::after { transform: translateX(0); }
  .menu__item.is-active::after { opacity: 1; transform: translateX(0); } }

@media only screen and (max-width: 767px) { .menu__item.is-active { background-color: #F3C01C; } }

@media only screen and (max-width: 767px) { .menu__item { flex: 1 1 25%; max-width: 25%; text-align: center; padding: 5px 0; }
  .menu__item .icon { display: block; margin: 4px auto; } }

@media only screen and (min-width: 768px) { .menu__item { display: flex; flex-flow: row nowrap; flex: 1 1 100%; max-width: 100%; margin-bottom: 10px; position: relative; } }

@media only screen and (min-width: 1023px) { html:not(.tablet) .menu__item:hover .icon-white { fill: #EF436E; }
  html:not(.tablet) .menu__item:hover .menu__link { color: #EF436E; }
  html:not(.tablet) .menu__item:hover .menu__link::after { transform: translateX(0); }
  html:not(.tablet) .menu__item:hover::after { opacity: 1; transform: translateX(0); } }

.menu__link { font-family: "Roboto", sans-serif; font-weight: 400; color: white; font-size: 13px; line-height: 1.2; width: calc(100% - 40px); padding-bottom: 13px; padding-top: 5px; position: relative; overflow: hidden; transition: color 0.3s ease; }

@media only screen and (max-width: 767px) { .menu__link { display: block; text-align: center; width: 100%; margin-top: -4px; padding-bottom: 0; position: static; }
  .menu__link::after { content: none !important; }
  .menu__link::before { content: ''; background: none; position: absolute; width: 100%; height: 100%; top: 0; left: 0; z-index: 1; } }

@media only screen and (min-width: 768px) { .menu__link { font-size: 20px; } }

.menu__link::after { content: ""; position: absolute; bottom: 0; left: 0; width: 100%; height: 1px; background-color: #EF436E; transition: transform 0.3s ease; transform: translateX(-101%); }

.bottom-name { margin-top: auto; text-align: center; padding-right: 30px; }

.icon-wrap.icon-menu-1 > svg { width: 26px; height: 25px; }

.icon-wrap.icon-menu-2 > svg { width: 28px; height: 26px; }

.icon-wrap.icon-menu-3 > svg, .icon-wrap.icon-menu-4 > svg { width: 39px; height: 33px; }

.icon-wrap.icon-menu-5 > svg { width: 38px; height: 25px; }

.icon-wrap.icon-menu-6 > svg { width: 36px; height: 34px; }

/*all screen */
.screen { display: block; width: 100%; min-height: 100vh; text-align: center; position: relative; overflow: hidden; }

.screen .inner { position: relative; height: 100%; }

@media only screen and (min-width: 1024px) { .screen { padding-left: 260px; height: 100vh; } }

/*first screen */
.first-screen { padding-top: 75px; }

@media only screen and (min-width: 1024px) { .first-screen { padding-top: 9vh; } }

@media only screen and (min-width: 768px) { .first-screen { padding-top: 130px; } }

.first-screen .next-page { width: 150px; height: 100px; top: -21px; right: 0; }

.first-screen .next-page .top-icon img { width: 122px; }

.first-screen .next-page .bottom-icon img { transform: rotate(90deg); }

@media (min-width: 1024px) and (max-width: 1279px) { .first-screen .next-page { top: -12px; }
  .first-screen .next-page .top-icon img { width: 100%; }
  .first-screen .next-page .bottom-icon { text-align: right; padding-right: 20px; margin-top: 10px; } }

@media only screen and (min-width: 1280px) { .first-screen .next-page { top: 5%; } }

@media only screen and (min-width: 1400px) { .first-screen .next-page { top: 11%; } }

.first-screen .scroll-icon { position: relative; z-index: 10; top: auto; right: auto; }

.logo-wrapper { display: block; text-align: center; margin-bottom: 45px; }

@media only screen and (min-width: 768px) { .logo-wrapper { margin-bottom: 50px; } }

@media only screen and (min-width: 1024px) { .logo-wrapper { margin-bottom: 5vh; } }

.logo-wrapper img { width: 180px; display: inline-block; }

@media only screen and (min-width: 768px) { .logo-wrapper img { width: 300px; height: 120px; } }

.first-title { display: block; text-align: center; line-height: 1; font-size: 62px; color: white; margin-bottom: 30px; }

@media only screen and (max-width: 1023px) { .first-title { font-size: 62px; font-size: calc(32px + (62 - 32) * ((100vw - 320px) / (1023 - 320))); } }

@media only screen and (max-width: 1023px) and (max-width: 320px) { .first-title { font-size: 32px; } }

@media only screen and (max-width: 1023px) and (min-width: 1023px) { .first-title { font-size: 62px; } }

@media only screen and (max-width: 1023px) { .first-title .to-mob { display: block; margin: auto; }
  .first-title .to-desck { display: none; } }

@media only screen and (min-width: 1024px) { .first-title { font-size: 62px; font-size: calc(30px + (62 - 30) * ((100vw - 767px) / (1920 - 767))); } }

@media only screen and (min-width: 1024px) and (max-width: 767px) { .first-title { font-size: 30px; } }

@media only screen and (min-width: 1024px) and (min-width: 1920px) { .first-title { font-size: 62px; } }

@media only screen and (min-width: 1024px) { .first-title .to-mob { display: none; }
  .first-title .to-desck { margin: auto; display: block; } }

@media only screen and (min-width: 1300px) { .first-title br { display: none; } }

@media only screen and (max-width: 767px) { .first-title { padding-left: 30px; padding-right: 30px; } }

@media only screen and (min-width: 1366px) { .first-title { margin-bottom: 7vh; } }

.first-title .inner { max-width: 10em; display: inline-block; }

.letter { display: inline-block; min-width: 0.3em; }

.floor { position: absolute; bottom: 0; left: 0; right: 0; width: 100%; z-index: 5; }

.lapa-nieb { position: absolute; left: 20%; bottom: 16px; z-index: 10; }

.lapa-nieb__ear { width: 9%; position: absolute; top: -2%; right: 25%; height: auto !important; transform-origin: 50% bottom; transform: rotate(10deg); animation: niebieski1 2s linear infinite; }

.lapa-nieb__ear-static { width: 9%; position: absolute; top: -2%; left: 20%; height: auto !important; transform: rotate(-45deg); z-index: -1; }

.lapa-nieb__arm { position: absolute; left: 2px; width: 16%; height: auto !important; top: 5%; z-index: -1; transform-origin: 70% bottom; transform: rotate(10deg); animation: niebieski1 2.5s linear infinite .2s; }

@media only screen and (max-width: 767px) { .lapa-nieb { left: -126px; bottom: -65px; transform: rotate(9deg); } }

@media only screen and (min-width: 768px) { .lapa-nieb { left: calc(50% - 322px); } }

@media only screen and (min-width: 1023px) { .lapa-nieb { left: 37%; } }

@media only screen and (min-width: 1366px) { .lapa-nieb { left: 28%; } }

.lapa-nieb img { height: 400px; height: calc(200px + (400 - 200) * ((100vw - 767px) / (1500 - 767))); }

@media (max-width: 767px) { .lapa-nieb img { height: 200px; } }

@media (min-width: 1500px) { .lapa-nieb img { height: 400px; } }

@media only screen and (max-width: 600px) { .lapa-nieb img { height: 330px; } }

@media (min-width: 768px) and (max-width: 800px) { .lapa-nieb img { height: 350px; } }

@media only screen and (max-height: 770px) { .lapa-nieb img { max-height: 315px; } }

@media only screen and (max-height: 730px) { .lapa-nieb img { height: 275px; } }

@media (min-width: 731px) and (max-width: 750px) { .lapa-nieb img { height: 330px; } }

@keyframes niebieski1 { 0% { transform: rotate(10deg); }
  50% { transform: rotate(-10deg); }
  100% { transform: rotate(10deg); } }

.lapa-roma { position: absolute; bottom: 16px; z-index: 10; }

.lapa-roma__ear { position: absolute; top: -3%; right: 32%; height: auto !important; width: 10%; transform-origin: 50% bottom; animation: niebieski1 2s linear infinite; }

.lapa-roma__arm { position: absolute; top: 25%; left: 6px; width: 18%; height: auto !important; transform-origin: 90% bottom; animation: niebieski1 3s linear infinite .2s; }

@media only screen and (max-width: 767px) { .lapa-roma { right: -164px; bottom: -65px; transform: rotate(-9deg); } }

@media only screen and (min-width: 768px) { .lapa-roma { right: calc(50% - 374px); } }

@media only screen and (min-width: 1023px) { .lapa-roma { right: 10%; } }

.lapa-roma img { height: 420px; height: calc(200px + (420 - 200) * ((100vw - 767px) / (1500 - 767))); }

@media (max-width: 767px) { .lapa-roma img { height: 200px; } }

@media (min-width: 1500px) { .lapa-roma img { height: 420px; } }

@media only screen and (max-width: 600px) { .lapa-roma img { height: 345px; } }

@media (min-width: 768px) and (max-width: 800px) { .lapa-roma img { height: 350px; } }

@media only screen and (max-height: 770px) { .lapa-roma img { max-height: 330px; } }

@media only screen and (max-height: 730px) { .lapa-roma img { height: 300px; } }

@media (min-width: 731px) and (max-width: 750px) { .lapa-roma img { height: 340px; } }

.main-line { position: absolute; left: 0; right: 0; z-index: 6; bottom: 45%; }

@media only screen and (min-width: 1024px) { .main-line { bottom: 20%; } }

@media only screen and (max-width: 1023px) { .main-line { display: none; } }

#main-line { width: 100vw; }

.second-screen { padding-top: 9vh; }

@media (max-height: 1070px) and (min-width: 1023px) { .second-screen { padding-top: 5vh; } }

@media (min-width: 768px) and (max-width: 1023px) { .second-screen { padding-top: 120px; overflow: hidden; } }

@media only screen and (max-width: 767px) { .second-screen { padding-top: 55px; overflow: hidden; } }

@media only screen and (min-width: 1023px) { .second-screen { padding-left: 260px; } }

.second-screen .subtitle { font-size: 34px; display: block; text-align: center; margin-top: -15px; }

@media only screen and (max-width: 1023px) { .second-screen .subtitle { margin-top: 0; margin-bottom: 40px; } }

.second-screen .scroll-icon { z-index: 3; }

.second-screen .lapa-nieb2 { position: absolute; bottom: -125px; right: 0%; width: 470px; opacity: 0; text-align: left; display: flex; align-items: flex-start; }

.second-screen .lapa-nieb2__c { margin-left: -40px; }

@media only screen and (max-width: 767px) { .second-screen .lapa-nieb2__c { margin-left: -20px; } }

.second-screen .lapa-nieb2__c, .second-screen .lapa-nieb2__i2 { height: 50px; animation: r2 1.5s linear infinite; }

@media only screen and (max-width: 767px) { .second-screen .lapa-nieb2__c, .second-screen .lapa-nieb2__i2 { height: 20px; animation: r2 3s linear infinite; } }

.second-screen .lapa-nieb2__i1 { height: 40px; margin: 0 10px; transform: translateY(20px); animation: r2 1.5s linear infinite .7s; }

@media only screen and (max-width: 767px) { .second-screen .lapa-nieb2__i1 { height: 20px; margin: 0 5px; animation: r2 3s linear infinite 1.5s; } }

@media (min-width: 1024px) and (max-width: 1279px) { .second-screen .lapa-nieb2 { bottom: -185px; right: -10%; width: 423px; } }

@media (min-width: 768px) and (max-width: 1023px) { .second-screen .lapa-nieb2 { bottom: -245px; right: -108px; width: 500px; opacity: 1; display: none; } }

@media only screen and (max-width: 767px) { .second-screen .lapa-nieb2 { bottom: auto; right: auto; width: 200px; opacity: 1; top: 190px; left: -58px; transform: rotate(40deg); } }

.second-screen .lapa-nieb2__main { width: 66%; }

@media only screen and (max-width: 1280px) { .second-screen .lapa-nieb2__main { width: 55%; } }

.second-screen .next-bottom-page { position: absolute; bottom: 6%; right: 2%; display: flex; align-items: center; }

.second-screen .next-bottom-page .bottom-icon { width: 50px; transform: rotate(180deg); margin-left: 11px; }

@media only screen and (max-width: 1023px) { .second-screen .next-bottom-page { bottom: -40px; }
  .second-screen .next-bottom-page .bottom-icon { transform: rotate(130deg); } }

.second-screen .prev-page { bottom: 43%; left: 41%; }

@media (min-width: 1024px) and (max-width: 1279px) { .second-screen .prev-page { bottom: 35%; left: 29%; } }

@media only screen and (max-width: 1023px) { .second-screen .prev-page { left: 30px; bottom: 0; } }

.second-screen .bottom-icon { width: 50px; transform: rotate(-90deg); }

.second-screen .rocket { left: 10px; bottom: 13%; }

@media only screen and (max-width: 1023px) { .second-screen .rocket { left: calc(50% - 60px); bottom: -35px; display: none; } }

.second-top-inner { display: block; width: 100%; text-align: center; }

.title-second { width: 310px; height: 85px; display: inline-block; }

@media only screen and (max-width: 767px) { .title-second { width: 225px; height: 60px; } }

.third-text-wrapper { display: flex; flex-flow: row wrap; align-items: flex-start; }

@media only screen and (min-width: 1024px) { .third-text-wrapper { justify-content: center; } }

@media only screen and (max-width: 767px) { .third-text-wrapper { margin-top: 0; margin: 0 auto; max-width: 325px; padding: 40px 25px 0px; background-image: url("../img/path-valkea.svg"); background-repeat: no-repeat; background-size: 325px auto; background-position-y: 33px; } }

@media only screen and (max-width: 520px) { .third-text-wrapper { max-width: 320px; background-size: 309px auto; } }

.second-top-left { position: relative; margin-left: 2%; margin-top: -52px; width: 510px; height: 378px; text-align: left; }

@media (min-width: 1024px) and (max-width: 1280px) { .second-top-left { border: 2px dashed #EF436E; padding: 30px 30px 35px 45px; margin-top: 2%; max-width: 42%; } }

@media (min-width: 1281px) and (max-width: 1500px) { .second-top-left { margin-top: 2%; } }

@media (min-width: 1500px) and (max-width: 1680px) { .second-top-left { margin-left: -3%; } }

@media only screen and (min-width: 1281px) { .second-top-left { padding: 23px 113px 16px 30px; max-width: 46%; }
  .second-top-left::after { content: ''; position: absolute; top: 0; left: 0; background-image: url(../img/second-screen-text-bg.png); width: 100%; min-width: 520px; height: 100%; background-size: cover; background-repeat: no-repeat; } }

@media only screen and (min-width: 1366px) { .second-top-left { padding: 30px 113px 35px 30px; max-width: 476px; } }

@media only screen and (min-width: 1800px) { .second-top-left { max-width: 520px; margin-right: 1%; }
  .second-top-left::after { width: 585px; min-width: 585px; height: 426px; } }

@media (min-width: 768px) and (max-width: 1024px) { .second-top-left { width: 100%; height: auto; flex: 1 1 100%; max-width: 100%; display: flex; flex-flow: row nowrap; border: none; margin-top: 0; padding: 0 30px 20px; }
  .second-top-left .custom-text { font-size: 21px; font-size: calc(17px + (21 - 17) * ((100vw - 320px) / (1023 - 320))); } }

@media (min-width: 768px) and (max-width: 1024px) and (max-width: 320px) { .second-top-left .custom-text { font-size: 17px; } }

@media (min-width: 768px) and (max-width: 1024px) and (min-width: 1023px) { .second-top-left .custom-text { font-size: 21px; } }

@media (min-width: 768px) and (max-width: 1024px) { .second-top-left .custom-list { font-size: 29px; font-size: calc(20px + (29 - 20) * ((100vw - 320px) / (1023 - 320))); padding-left: 20px; } }

@media (min-width: 768px) and (max-width: 1024px) and (max-width: 320px) { .second-top-left .custom-list { font-size: 20px; } }

@media (min-width: 768px) and (max-width: 1024px) and (min-width: 1023px) { .second-top-left .custom-list { font-size: 29px; } }

@media (min-width: 768px) and (max-width: 1024px) { .second-top-left .tablet-left, .second-top-left .custom-list { flex: 1 1 50%; max-width: 50%; } }

@media only screen and (max-width: 767px) { .second-top-left { margin-top: 0; } }

@media only screen and (max-width: 1023px) { .second-top-left .custom-title { font-size: 40px; } }

@media only screen and (max-width: 1366px) { .second-screen .scroll-icon { display: none; } }

@media only screen and (min-width: 1580px) { .second-screen .custom-text { font-size: 24px; font-size: calc(18px + (24 - 18) * ((100vw - 1580px) / (1920 - 1580))); } }

@media only screen and (min-width: 1580px) and (max-width: 1580px) { .second-screen .custom-text { font-size: 18px; } }

@media only screen and (min-width: 1580px) and (min-width: 1920px) { .second-screen .custom-text { font-size: 24px; } }

@media only screen and (min-width: 1281px) { .second-screen .custom-text { max-width: 430px; } }

@media only screen and (max-width: 1023px) { .second-screen { min-height: 1px; } }

.second-middle-right { position: relative; flex: 1 1 50%; max-width: 50%; margin-left: 10%; text-align: left; padding-top: 70px; padding-right: 100px; }

@media only screen and (min-width: 1281px) { .second-middle-right { margin-left: 3%; } }

@media (min-width: 1024px) and (max-width: 1279px) { .second-middle-right { padding-right: 30px; max-width: 55%; margin-left: 55px; margin-right: auto; } }

@media (min-width: 1280px) and (max-width: 1366px) { .second-middle-right { max-width: 44%; margin-left: 5%; padding-top: 30px; } }

@media (min-width: 768px) and (max-width: 1023px) { .second-middle-right { max-width: 100%; margin-left: 40px; margin-bottom: 100px; padding-top: 0px; } }

@media only screen and (max-width: 767px) { .second-middle-right { width: 100%; margin: 0 auto; padding: 0; max-width: 270px; } }

@media only screen and (max-width: 1023px) { .second-middle-right { margin-bottom: 0; } }

.second-middle-right .custom-title { margin-bottom: 25px; font-size: 33px; }

@media only screen and (max-width: 1023px) { .second-middle-right .custom-title { font-size: 40px; } }

@media only screen and (max-width: 767px) { .second-middle-right .custom-title { text-align: center; } }

@media only screen and (max-width: 1023px) { .second-middle-right .custom-text { font-size: 19px; } }

@media only screen and (max-width: 767px) { .second-middle-right .custom-text { text-align: center; }
  .second-middle-right .custom-text .big-text { display: block; text-decoration: underline; } }

.second-line { position: absolute; width: 100%; right: 0; bottom: 19%; }

.second-line .second-line-mob { display: none; }

@media only screen and (min-width: 1025px) { .second-line { width: 65%; right: 41%; bottom: 0; } }

.second-line > svg { transform: translateY(50%); }

@media only screen and (max-height: 750px) { .second-line { bottom: -100px; }
  .second-line .next-bottom-page { bottom: calc(6% + 100px); right: 14%; } }

@media (min-width: 768px) and (max-width: 1023px) { .second-line { position: relative; margin-bottom: 65px; }
  .second-line .second-line-desctop { display: none; }
  .second-line .second-line-mob { display: block; } }

@media only screen and (max-width: 1023px) { .second-line { display: none; } }

.custom-title { font-size: 44px; font-weight: 900; line-height: 1.1; }

.custom-text { font-size: 17px; line-height: 1.5; margin-bottom: 1em; }

.custom-list { font-size: 24px; font-family: second, roboto, arial, sans-serif; color: #87BCD9; line-height: 1.3; }

.big-text { font-size: 125%; }

.big-text.red { color: #EF436E; }

.big-text.blue { color: #acdfea; }

.mt20 { margin-top: 20px; }

.third-screen .prev-page { left: 51.5%; top: 10px; }

.third-screen .prev-page .left-icon { display: inline-block; vertical-align: middle; }

.third-screen .prev-page .right-icon { display: inline-block; vertical-align: middle; }

.third-screen .prev-page .right-icon img { width: 81px; margin-left: 14px; }

@media only screen and (max-width: 1023px) { .third-screen .prev-page { display: none; } }

.third-screen .next-page { bottom: 14px; left: 51%; display: inline-block; }

.third-screen .next-page .left-text { padding-top: 9px; font-size: 22px; font-weight: 700; display: inline-block; vertical-align: middle; color: white; padding-bottom: 10px; margin-left: 15px; }

.third-screen .next-page .bottom-icon { display: inline-block; vertical-align: middle; }

.third-screen .next-page img { transform: rotate(155deg); }

@media only screen and (max-width: 1023px) { .third-screen .next-page { display: none; } }

@media (min-width: 768px) and (max-width: 1023px) { .third-screen { height: 1050px; } }

@media only screen and (max-width: 767px) { .third-screen { height: 880px; } }

@media only screen and (max-width: 600px) { .third-screen { height: 850px; } }

.bg-third { padding-left: 15px; position: relative; height: 100%; }

@media (min-width: 1024px) and (max-width: 1366px) { .bg-third .scroll-icon { display: none; } }

.third-line { position: absolute; height: 100%; left: 50%; transform: translateX(-50%); }

@media (min-width: 1024px) and (max-width: 1279px) { .third-line { max-width: 1000px; } }

@media only screen and (max-width: 1023px) { .third-line { display: none; } }

.third-line-mob { pointer-events: none; display: none; }

@media only screen and (max-width: 1023px) { .third-line-mob { position: absolute; display: block; height: 100%; left: 50%; top: 0; transform: translateX(-50%); z-index: 0; } }

.point { display: block; position: absolute; }

@media only screen and (max-width: 1023px) { .point { display: none; } }

@media (min-width: 1024px) and (max-width: 1279px) { .point.digital-point { width: 14%; top: 1%; left: 34%; } }

@media only screen and (min-width: 1280px) { .point.digital-point { width: 12%; top: 0%; left: 39%; } }

@media only screen and (min-width: 1600px) { .point.digital-point { max-width: 155px; top: 2%; left: 38%; } }

@media (min-width: 1024px) and (max-width: 1279px) { .point.event-point { width: 27%; top: 11%; left: -1%; } }

@media only screen and (min-width: 1280px) { .point.event-point { width: 25%; left: 7%; top: 10%; max-width: 340px; } }

@media only screen and (min-width: 1600px) { .point.event-point { left: 8%; } }

@media only screen and (min-width: 1800px) { .point.event-point { left: 15%; } }

@media (min-width: 1024px) and (max-width: 1279px) { .point.pr-point { width: 23%; top: 16%; right: 18%; } }

@media only screen and (min-width: 1280px) { .point.pr-point { width: 22%; right: 20%; top: 13%; max-width: 340px; } }

@media only screen and (min-width: 1600px) { .point.pr-point { right: 8%; } }

@media (min-width: 1024px) and (max-width: 1279px) { .point.csr-point { width: 14%; top: 43%; right: 10%; } }

@media only screen and (min-width: 1280px) { .point.csr-point { width: 12%; top: 40%; right: 16%; max-width: 170px; } }

@media only screen and (min-width: 1600px) { .point.csr-point { right: 14%; } }

@media only screen and (min-width: 1024px) { .point.uslugi-point { width: 27%; top: 44%; left: -1%; max-width: 270px; } }

@media only screen and (min-width: 1281px) { .point.uslugi-point { width: 25%; } }

@media only screen and (min-width: 1600px) { .point.uslugi-point { left: 2%; } }

@media only screen and (min-width: 1024px) { .point.strategia-point { width: 26%; bottom: 23%; left: 30%; } }

@media only screen and (min-width: 1281px) { .point.strategia-point { width: 24%; bottom: 26%; max-width: 250px; } }

@media only screen and (min-width: 1024px) { .point.zintegrowany-point { width: 26%; left: -11%; bottom: 12%; } }

@media only screen and (min-width: 1366px) { .point.zintegrowany-point { width: 23%; left: 0%; } }

@media only screen and (min-width: 1600px) { .point.zintegrowany-point { max-width: 300px; } }

@media only screen and (min-width: 1800px) { .point.zintegrowany-point { left: 10%; } }

@media (min-width: 1500px) and (max-width: 1540px) and (max-height: 725px) { .point.zintegrowany-point { bottom: 6%; left: 7%; width: 21%; } }

@media (min-width: 1050px) and (max-width: 1300px) and (max-height: 725px) { .point.zintegrowany-point { bottom: 10%; left: 2%; width: 21%; } }

@media only screen and (min-width: 1024px) { .point.custom-point { width: 31%; right: 1%; bottom: 18%; } }

@media only screen and (min-width: 1600px) { .point.custom-point { right: 20%; max-width: 320px; } }

@media only screen and (max-height: 750px) { .point.custom-point { bottom: 10%; width: 26%; } }

@media only screen and (min-width: 1024px) { .point.social-point { width: 23%; bottom: 3%; left: 20%; } }

@media only screen and (min-width: 1366px) { .point.social-point { width: 22%; bottom: 3%; left: 31%; max-width: 240px; } }

.mob-point { display: none; }

@media only screen and (max-width: 1023px) { .mob-point { display: inline-block; max-width: 49%; transform: scale(1.3); } }

.mob-point.event-point { min-width: 405px; min-width: calc(210px + (405 - 210) * ((100vw - 320px) / (768 - 320))); margin-left: -20px; }

@media (max-width: 320px) { .mob-point.event-point { min-width: 210px; } }

@media (min-width: 768px) { .mob-point.event-point { min-width: 405px; } }

.mob-point.csr-point { max-width: 30%; transform: scale(1.6) rotate(30deg); }

@media (min-width: 768px) and (max-width: 1023px) { .mob-point.csr-point { margin-left: 10%; } }

@media only screen and (max-width: 767px) { .mob-point.csr-point { margin-left: 5%; } }

.mob-point.digital-point { max-width: 30%; transform: scale(1.6) rotate(-30deg); }

@media (min-width: 768px) and (max-width: 1023px) { .mob-point.digital-point { margin-right: 15%; margin-top: -40px; } }

@media only screen and (max-width: 767px) { .mob-point.digital-point { margin-top: -20px; margin-right: 5%; } }

.mob-point.pr-point { width: 292px; margin-right: 10%; }

@media (min-width: 768px) and (max-width: 1023px) { .mob-point.pr-point { margin-top: -40px; } }

@media only screen and (max-width: 767px) { .mob-point.pr-point { margin-top: -20px; } }

.mob-point.strategia-point { width: 31%; max-width: 203px; transform: rotate(-12deg) scale(1.6); top: -45px; position: relative; left: 20px; left: calc(0px + (20 - 0) * ((100vw - 600px) / (768 - 600))); }

@media (max-width: 600px) { .mob-point.strategia-point { left: 0px; } }

@media (min-width: 768px) { .mob-point.strategia-point { left: 20px; } }

.mob-point.uslugi-point { transform: rotate(-12deg) scale(1.5); position: relative; right: 0; top: 30px; }

@media only screen and (min-width: 768px) { .mob-point.uslugi-point { top: 0; margin-left: 20%; } }

.mob-point.custom-point { transform: scale(1.5) rotate(-29deg); margin-left: -5%; margin-right: 12%; }

@media only screen and (max-width: 767px) { .mob-point.custom-point { max-width: 60%; margin-left: auto; margin-right: auto; } }

.mob-point.zintegrowany-point { position: relative; right: -10%; }

@media only screen and (max-width: 767px) { .mob-point.zintegrowany-point { right: auto; top: 24px; } }

.mob-point.social-point { position: relative; top: -24px; right: 21%; }

@media only screen and (max-width: 767px) { .mob-point.social-point { top: -74px; right: 10%; } }

.third-center-text { position: absolute; top: 44%; left: 0; font-size: 60px; font-size: calc(40px + (60 - 40) * ((100vw - 1024px) / (1980 - 1024))); font-weight: 700; margin-left: auto; display: block; width: 100%; }

@media (max-width: 1024px) { .third-center-text { font-size: 40px; } }

@media (min-width: 1980px) { .third-center-text { font-size: 60px; } }

.third-center-text .text-to-bottom { display: none; }

.third-center-text .text-to-top { font-size: 75px; font-family: second, roboto, arial, sans-serif; text-transform: uppercase; }

@media only screen and (max-width: 1023px) { .third-center-text { display: block; position: relative; padding-top: 150px; top: 0 !important; }
  .third-center-text .text-to-top { display: none; }
  .third-center-text .text-to-bottom { display: inline-block; width: 300px; max-width: 70%; } }

@media (min-width: 768px) and (max-width: 1023px) { .third-center-text { padding-top: 150px; } }

@media only screen and (max-width: 767px) { .third-center-text { padding: 79px 0 30px; } }

#rocket { left: 0; bottom: 20%; transform: rotate(-20deg); animation: r1 2s linear infinite; z-index: 10; }

@media only screen and (min-width: 1200px) { #rocket { bottom: 24%; } }

@media only screen and (max-width: 1023px) { #rocket { bottom: 43%; } }

@media only screen and (max-width: 1023px) { #rocket { display: none; } }

@keyframes r1 { 0% { transform: rotate(-20deg) translateY(0); }
  50% { transform: rotate(-20deg) translateY(30px); }
  100% { transform: rotate(-20deg) translateY(0); } }

#rocket3 { left: 40%; top: 20%; transform: rotate(134deg); animation: r3 2s linear infinite; }

@media only screen and (min-width: 1280px) { #rocket3 { left: 46%; top: 18%; } }

@media only screen and (max-width: 1023px) { #rocket3 { display: none; } }

@keyframes r3 { 0% { transform: rotate(134deg) translateY(0); }
  50% { transform: rotate(134deg) translateY(-20px); }
  100% { transform: rotate(134deg) translateY(0); } }

#rocket2 { left: 105px; bottom: 30%; animation: r2 2s linear infinite; }

@media only screen and (max-width: 1280px) { #rocket2 { left: 60px; } }

@keyframes r2 { 0% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0); } }

#rocket6 { animation: r2 2s linear infinite; }

#rocket4 { animation: r4 2s linear infinite; }

#rocket-free-portfolio { animation: r4 2s linear infinite; }

@keyframes r4 { 0% { transform: rotate(40deg) translateY(0); }
  50% { transform: rotate(40deg) translateY(-20px); }
  100% { transform: rotate(40deg) translateY(0); } }

#rocket5 { animation: r5 2s linear infinite; z-index: 10; }

@keyframes r5 { 0% { transform: rotate(115deg) translateY(0); }
  50% { transform: rotate(115deg) translateY(-20px); }
  100% { transform: rotate(115deg) translateY(0); } }

#rocket8 { animation: r8 2s linear infinite; z-index: 10; }

@keyframes r8 { 0% { transform: rotate(-106deg) translateY(0); }
  50% { transform: rotate(-106deg) translateY(-20px); }
  100% { transform: rotate(-106deg) translateY(0); } }

#rocket7 { animation: r7 2s linear infinite; }

@keyframes r7 { 0% { bottom: 14%; }
  50% { bottom: 16%; }
  100% { bottom: 14%; } }

.nine-screen .section-text { z-index: 10; }

.hidden-way { position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: -1; opacity: 0; }

/*four screen */
@keyframes rotateLR { 0% { transform: rotate(-10deg); }
  50% { transform: rotate(10deg); }
  100% { transform: rotate(-10deg); } }

.four-screen .section-title { width: 380px; height: auto; position: absolute; top: -174px; left: 69px; }

@media only screen and (min-width: 1280px) { .four-screen .section-title { width: 340px; height: auto; top: -152px; } }

@media only screen and (max-width: 1279px) { .four-screen .section-title { width: 280px; top: -103px; left: auto; right: 0; } }

@media only screen and (max-width: 767px) { .four-screen .section-title { max-width: 90%; margin: auto; display: block; position: relative; height: 122px; overflow: hidden; top: 0; margin-bottom: 10px; margin-top: 40px; }
  .four-screen .section-title img { position: absolute; bottom: 0; left: 0; } }

.four-screen .prev-page { top: 14px; left: 48%; }

@media only screen and (max-width: 1024px) { .four-screen .prev-page { left: 1%; top: 17%; display: flex; flex-flow: row wrap; }
  .four-screen .prev-page .bottom-icon { display: block; flex: 1 1 100%; order: 2; text-align: left; }
  .four-screen .prev-page .bottom-icon img { transform: rotate(-93deg); } }

@media only screen and (max-width: 1023px) { .four-screen .prev-page { display: none; } }

.four-screen .next-page { left: 55%; bottom: 15px; }

@media only screen and (max-width: 1700px) { .four-screen .next-page { left: 54%; } }

.four-screen .next-page .bottom-icon { transform: rotate(180deg); }

@media only screen and (max-width: 1024px) { .four-screen .next-page { left: auto; right: 40px; top: 26%; bottom: auto; display: flex; flex-flow: row wrap; }
  .four-screen .next-page .bottom-icon { flex: 1 1 100%; order: 2; text-align: left; }
  .four-screen .next-page .bottom-icon img { transform: rotate(-90deg); }
  .four-screen .next-page .left-text { text-align: right; flex: 1 1 100%; } }

@media only screen and (max-width: 1023px) { .four-screen .next-page { display: none; } }

.four-screen .lapa-nieb4 { pointer-events: none; position: absolute; }

.four-screen .lapa-nieb4 .to-top-img { width: 80%; transform: rotate(-10deg); transform-origin: 50% bottom; animation: rotateLR 2s linear infinite; }

@media (min-width: 768px) and (max-width: 1200px) { .four-screen .lapa-nieb4 { bottom: 70px; pointer-events: none; width: 100%; } }

@media only screen and (min-width: 1200px) { .four-screen .lapa-nieb4 { width: 90%; bottom: 65px; left: 5%; } }

@media only screen and (min-width: 1500px) { .four-screen .lapa-nieb4 { width: 84%; bottom: 0; left: 5%; } }

@media only screen and (min-width: 1700px) { .four-screen .lapa-nieb4 { width: 72%; bottom: 0; left: 9%; } }

@media (min-width: 1024px) and (max-height: 750px) { .four-screen .lapa-nieb4 { max-width: 120vh; left: calc(50% - 60vh); } }

.four-screen .lapa-nieb4 img { width: 100%; }

.four-screen .lapa-nieb4 .to-bottom-img { display: none; }

@media only screen and (max-width: 767px) { .four-screen .lapa-nieb4 { position: relative; width: 100%; left: 0; }
  .four-screen .lapa-nieb4 .to-top-img { display: none; }
  .four-screen .lapa-nieb4 .to-bottom-img { display: block; } }

.four-screen .text { width: 320px; display: inline-block; position: absolute; bottom: 0; padding-bottom: 45px; color: black; font-size: 21px; }

.four-screen .text .text-inner { height: 310px; padding: 25px 23px 35px; }

@media only screen and (max-width: 767px) { .four-screen .text .text-inner { height: auto; padding: 10px 9px 24px; font-size: 16px; }
  .four-screen .text .text-inner .red { font-size: 22px; } }

@media (min-width: 1280px) and (max-width: 1550px) { .four-screen .text { font-size: 17px; padding: 10px; }
  .four-screen .text .text-inner { height: 250px; } }

@media (min-width: 768px) and (max-width: 1279px) { .four-screen .text { padding-bottom: 0; width: 50%; height: 230px; font-size: 19px; }
  .four-screen .text .text-inner { vertical-align: text-top; } }

@media only screen and (max-width: 767px) { .four-screen .text { position: relative; left: auto; right: auto; top: auto; margin-top: -53px; } }

.four-screen .text-1 { left: 10%; }

@media (min-width: 1280px) and (max-width: 1550px) { .four-screen .text-1 { left: 0; } }

@media (min-width: 768px) and (max-width: 1279px) { .four-screen .text-1 { left: 0; } }

.four-screen .text-1 .text-inner { display: table-cell; vertical-align: middle; background-color: #F2BF25; }

@media only screen and (max-width: 767px) { .four-screen .text-1 { transform: rotate(4deg); } }

.four-screen .text-2 { right: 10%; }

@media (min-width: 1280px) and (max-width: 1550px) { .four-screen .text-2 { right: 0; } }

@media (min-width: 768px) and (max-width: 1279px) { .four-screen .text-2 { right: 0; } }

.four-screen .text-2 .text-inner { background-color: #A6DCE3; }

@media only screen and (max-width: 767px) { .four-screen .text-2 { transform: rotate(-4deg); } }

.four-screen .red { color: #EF436E; font-family: second, roboto, arial, sans-serif; font-size: 26px; }

.four-screen .underline { text-decoration: underline; }

.four-screen .rocket { top: 150px; left: 45%; transform: rotate(40deg); }

@media only screen and (max-width: 1024px) { .four-screen .rocket { left: 27%; transform: rotate(1deg); } }

@media only screen and (max-width: 1023px) { .four-screen .rocket { display: none; } }

.four-screen .scroll-icon { display: none; }

@media only screen and (min-width: 1280px) { .four-screen .scroll-icon { display: block; } }

@media (min-width: 1023px) and (max-width: 1279px) { .four-screen .scroll-icon { top: 20%; } }

.four-screen .left-right-line { display: none; }

@media only screen and (max-width: 1024px) { .four-screen .left-right-line { display: block; position: absolute; right: 0%; width: calc(100% + 260px); top: 200px; pointer-events: none; } }

@media only screen and (max-width: 1023px) { .four-screen .left-right-line { display: none; } }

@media only screen and (max-width: 1023px) { .four-screen.screen { height: 680px; min-height: auto; } }

@media only screen and (max-width: 767px) { .four-screen.screen { height: auto; } }

#four-line { width: 100%; height: 100vh; position: absolute; top: 0; left: 0; z-index: -1; display: none; }

@media only screen and (min-width: 1025px) { #four-line { display: block; } }

/*five screen */
.five-screen { display: block; }

@media (min-width: 768px) and (max-width: 1023px) { .five-screen.screen { height: 740px; min-height: auto; } }

@media only screen and (max-width: 767px) { .five-screen.screen { height: auto; min-height: auto; } }

.five-screen .section-title { width: 450px; position: absolute; top: -197px; left: 60px; }

@media only screen and (max-width: 1600px) { .five-screen .section-title { width: 355px; top: -174px; left: 60px; } }

@media (min-width: 768px) and (max-width: 1279px) { .five-screen .section-title { width: 280px; top: -99px; left: auto; right: -25%; } }

@media only screen and (max-width: 767px) { .five-screen .section-title { max-width: 100%; margin: auto; display: block; position: relative; height: 135px; overflow: hidden; top: 0; left: 0; margin-bottom: 10px; margin-top: 40px; }
  .five-screen .section-title img { position: absolute; bottom: 0; left: 0; } }

.five-screen .subtitle { text-transform: uppercase; font-size: 28px; padding-top: 22px; padding-bottom: 10px; }

.five-screen .subtext { font-size: 20px; line-height: 1.8; }

.five-screen .lapa-nieb5 { width: 85%; position: absolute; bottom: -164px; left: 8%; pointer-events: none; }

.five-screen .lapa-nieb5 img { width: 100%; }

.five-screen .lapa-nieb5__main-img { width: 30% !important; z-index: 2; position: relative; }

.five-screen .lapa-nieb5__img-1, .five-screen .lapa-nieb5__img-2, .five-screen .lapa-nieb5__img-3 { width: 30% !important; position: absolute; top: 0; transform: rotate(-10deg); }

.five-screen .lapa-nieb5__img-3 { right: 12%; top: 21%; animation: rotateLR 3s linear infinite; transform-origin: left 100%; z-index: 3; }

.five-screen .lapa-nieb5__img-2 { right: 21%; top: -26%; animation: rotateLR 3s linear infinite .4s; transform-origin: 20% bottom; z-index: 3; }

.five-screen .lapa-nieb5__img-1 { left: 15%; top: -13%; animation: rotateLR 2.5s linear infinite .2s; transform-origin: 80% bottom; z-index: 1; }

@media only screen and (min-width: 1700px) { .five-screen .lapa-nieb5 { width: 73%; left: 16%; } }

@media only screen and (min-width: 1900px) { .five-screen .lapa-nieb5 { width: 78%; left: 11%; } }

@media only screen and (max-width: 1600px) { .five-screen .lapa-nieb5 { bottom: -66px; } }

@media (min-width: 1024px) and (max-width: 1279px) { .five-screen .lapa-nieb5 { bottom: -23px; } }

@media only screen and (max-height: 750px) { .five-screen .lapa-nieb5 { bottom: -115px; width: 130vh; left: calc(50% - 65vh); } }

@media only screen and (max-width: 1023px) { .five-screen .lapa-nieb5 { width: 100%; bottom: -3px; } }

@media only screen and (max-width: 767px) { .five-screen .lapa-nieb5 { display: none; } }

.five-screen .text { position: absolute; bottom: 20px; left: 30px; font-size: 30px; max-width: 235px; }

@media only screen and (max-width: 767px) { .five-screen .text { display: none; } }

.five-screen .red { color: #EF436E; }

.five-screen .rocket { top: 24%; right: 21%; transform: rotate(115deg); }

@media only screen and (max-width: 1024px) { .five-screen .rocket { top: auto; bottom: 46%; right: 52%; transform: rotate(30deg); } }

@media only screen and (max-width: 1023px) { .five-screen .rocket { display: none; } }

.five-screen .prev-page { right: 22%; top: 20px; }

@media (min-width: 1300px) and (max-width: 1699px) { .five-screen .prev-page { right: 23%; } }

@media (min-width: 1024px) and (max-width: 1279px) { .five-screen .prev-page { right: auto; left: 30px; top: 48%; }
  .five-screen .prev-page .bottom-icon img { transform: rotate(-80deg); } }

@media only screen and (max-width: 1023px) { .five-screen .prev-page { top: 55%; left: 30px; right: auto; }
  .five-screen .prev-page .bottom-icon img { transform: rotate(-80deg); } }

@media only screen and (max-width: 1023px) { .five-screen .prev-page { display: none; } }

@media (min-width: 1280px) and (max-width: 1500px) { .five-screen .scroll-icon { top: 35%; } }

@media only screen and (max-width: 1200px) { .five-screen .scroll-icon { display: none; } }

.five-screen .next-page { right: 10px; bottom: auto; top: 570px; top: calc(400px + (570 - 400) * ((100vw - 1280px) / (1920 - 1280))); }

@media (max-width: 1280px) { .five-screen .next-page { top: 400px; } }

@media (min-width: 1920px) { .five-screen .next-page { top: 570px; } }

.five-screen .next-page .top-icon { text-align: right; margin-bottom: 10px; padding-right: 10px; }

.five-screen .next-page .top-icon img { transform: rotate(90deg); }

.five-screen .next-page .bottom-icon img { width: 120px; }

@media (min-width: 740px) and (max-width: 780px) { .five-screen .next-page { bottom: 13%; } }

@media only screen and (max-width: 1023px) { .five-screen .next-page { display: none; } }

.five-screen .mob-img-1 { display: block; width: 122%; position: relative; right: -10%; max-width: none; margin-top: 27px; }

.five-screen .mob-img-3 { display: block; width: 120%; max-width: none; margin-left: -10%; margin-top: -40px; }

.secton-text-block { position: absolute; top: 0; left: 10%; padding-top: 240px; max-width: 550px; }

@media only screen and (max-width: 1024px) { .secton-text-block { padding-top: 170px; } }

@media only screen and (max-width: 1600px) { .secton-text-block { left: 7%; padding-top: 170px; max-width: 481px; } }

@media only screen and (max-width: 767px) { .secton-text-block { position: relative; left: 0; right: 0; padding: 0 20px; } }

#five-line { position: absolute; height: 76%; max-height: 600px; max-height: calc(430px + (600 - 430) * ((100vw - 1280px) / (1920 - 1280))); right: 0px; pointer-events: none; }

@media (max-width: 1280px) { #five-line { max-height: 430px; } }

@media (min-width: 1920px) { #five-line { max-height: 600px; } }

@media only screen and (max-width: 1024px) { #five-line { transform: matrix(-1, 0, 0, -1, 0, 0) rotate(-45deg); width: 200%; right: -380px; top: 29%; } }

.five-line-small { display: block; position: absolute; bottom: -10%; width: calc(100% + 260px); right: 0; }

@media only screen and (min-width: 1025px) { .five-line-small { display: none; } }

@media only screen and (max-width: 1023px) { .five-line-small { display: none; } }

/*six screen*/
@media only screen and (max-width: 1023px) { .six-screen.screen { min-height: auto; height: auto; } }

.six-screen .six-line { position: absolute; bottom: 10%; left: 0; width: 100vw; }

@media only screen and (max-width: 1023px) { .six-screen .six-line { display: none; } }

.six-screen .six-line-mob { display: none; }

@media only screen and (max-width: 1023px) { .six-screen .six-line-mob { display: block; position: absolute; height: calc(100vw + 50px); z-index: -1; bottom: 0; left: 0; } }

@media only screen and (max-width: 767px) { .six-screen .six-line-mob { display: none; } }

.six-screen .section-text-block { max-width: 980px; margin: auto; padding-top: 9vh; }

@media only screen and (max-height: 750px) { .six-screen .section-text-block { padding-top: 3vh; } }

@media only screen and (max-width: 1023px) { .six-screen .section-text-block { margin-bottom: 60px; } }

@media only screen and (max-width: 767px) { .six-screen .section-text-block { padding-left: 20px; padding-right: 20px; } }

.six-screen .section-title { display: block; text-align: center; }

@media only screen and (min-width: 1024px) { .six-screen .section-title { font-size: 50px; } }

.six-screen .section-title img { width: 40vh; max-width: 360px; display: inline-block; }

.six-screen .subtitle { font-size: 48px; color: #EF436E; margin-bottom: 20px; }

@media only screen and (max-height: 750px) { .six-screen .subtitle { font-size: 36px; margin-bottom: 10px; } }

.six-screen .subtext { text-align: center; line-height: 1.9; font-size: 24px; font-size: calc(18px + (24 - 18) * ((100vw - 1280px) / (1980 - 1280))); }

@media (max-width: 1280px) { .six-screen .subtext { font-size: 18px; } }

@media (min-width: 1980px) { .six-screen .subtext { font-size: 24px; } }

@media only screen and (max-height: 750px) { .six-screen .subtext { font-size: 17px; } }

@media only screen and (max-width: 1280px) { .six-screen .subtext { line-height: 1.6; } }

.six-screen .rocket6 { width: 125px; position: absolute; bottom: 13%; left: 101px; }

@media only screen and (max-width: 1023px) { .six-screen .rocket6 { display: none; } }

.six-screen .next-page { right: 12px; bottom: 12%; }

.six-screen .next-page .top-icon img { width: 120px; }

.six-screen .next-page .bottom-icon img { transform: rotate(90deg); }

@media only screen and (max-width: 1023px) { .six-screen .next-page { bottom: 27px; } }

@media only screen and (max-width: 1023px) { .six-screen .next-page { display: none; } }

.six-screen .text { font-size: 40px; font-size: calc(30px + (40 - 30) * ((100vw - 1030px) / (1300 - 1030))); position: absolute; text-align: center; width: 102%; left: -1%; bottom: 0; z-index: 10; padding: 20px; box-shadow: inset -15px -43px 80px black; }

@media (max-width: 1030px) { .six-screen .text { font-size: 30px; } }

@media (min-width: 1300px) { .six-screen .text { font-size: 40px; } }

@media only screen and (max-width: 1023px) { .six-screen .text { display: none; } }

.section-img-team { width: 100%; height: 516px; height: 480px; height: calc(325px + (480 - 325) * ((100vw - 1024px) / (1980 - 1024))); position: absolute; bottom: 0; left: 0; }

@media (max-width: 1024px) { .section-img-team { height: 325px; } }

@media (min-width: 1980px) { .section-img-team { height: 480px; } }

@media only screen and (max-width: 1023px) { .section-img-team { display: none; } }

.team-member { position: absolute; }

.team-member::after { content: ''; position: absolute; display: block; width: 75%; padding-bottom: 100%; background-image: url(../img/stun.png); background-repeat: no-repeat; z-index: 2; transform: rotate(-33deg); opacity: 0; transition: opacity .3s ease; }

.team-member .is-active { z-index: -1; margin-left: 9%; top: -1%; }

.team-member.memb-2 .is-active { margin-left: 1%; top: 1%; }

.team-member.memb-3 .is-active { margin-left: 5%; top: 5%; }

.team-member.memb-4 .is-active { margin-left: 5%; top: -4%; }

.team-member.memb-5 .is-active { margin-left: 1%; top: -2%; }

.team-member.memb-6 .is-active { margin-left: 10%; top: -3%; }

.team-member.memb-8 .is-active { margin-left: -5%; top: 0; }

.team-member .is-active, .team-member .hover-block { position: absolute; opacity: 0; transition: opacity .3s ease; }

.team-member img { position: absolute; top: 0%; left: 50%; transform: translateX(-50%); width: 200%; max-width: none; pointer-events: none; }

.team-member img:not(.is-active), .team-member a:not(.hover-block) { z-index: 1; position: relative; }

.team-member:hover { z-index: 10; }

.team-member:hover::after, .team-member:hover .is-active, .team-member:hover .hover-block { opacity: 1; }

.hover-block { top: 50%; left: 50%; transform: translate(-50%, -50%); background-color: #F2BF25; color: black; width: 260px; width: calc(180px + (260 - 180) * ((100vw - 1024px) / (1500 - 1024))); font-size: 28px; font-size: 26px; font-size: calc(22px + (26 - 22) * ((100vw - 1024px) / (1500 - 1024))); font-weight: 700; padding: 36px 15px 20px; padding-top: 36px; padding-top: calc(15px + (36 - 15) * ((100vw - 1024px) / (1800 - 1024))); z-index: 3; }

@media (max-width: 1024px) { .hover-block { width: 180px; } }

@media (min-width: 1500px) { .hover-block { width: 260px; } }

@media (max-width: 1024px) { .hover-block { font-size: 22px; } }

@media (min-width: 1500px) { .hover-block { font-size: 26px; } }

@media (max-width: 1024px) { .hover-block { padding-top: 15px; } }

@media (min-width: 1800px) { .hover-block { padding-top: 36px; } }

@media only screen and (min-width: 1550px) { .hover-block { height: 150px; } }

.hover-block .red { color: #EF436E; font-size: 24px; font-size: 23px; font-size: calc(18px + (23 - 18) * ((100vw - 1024px) / (1500 - 1024))); }

@media (max-width: 1024px) { .hover-block .red { font-size: 18px; } }

@media (min-width: 1500px) { .hover-block .red { font-size: 23px; } }

.team-z-1 { z-index: 1; }

.team-z-2 { z-index: 2; }

.team-z-3 { z-index: 3; }

.team-z-4 { z-index: 4; }

.memb-1 { top: 0; left: 0; width: 12.5%; }

.memb-1 img { width: 327%; left: 87%; }

.memb-1 .hover-block { left: 67%; top: 48%; }

@media only screen and (max-width: 1600px) { .memb-1 .hover-block { top: 43%; } }

@media only screen and (max-width: 1366px) { .memb-1 .hover-block { top: 49%; } }

.memb-1::after { top: 18%; left: -16%; z-index: 2; width: 64%; }

.memb-2 { top: -2%; left: 12.5%; width: 12.5%; }

.memb-2 img { width: 233%; }

.memb-2 .hover-block { left: 50%; top: 47%; }

.memb-2::after { top: 19%; left: -22%; width: 65%; }

.memb-3 { top: -2%; width: 12.5%; left: 25%; }

.memb-3 img { top: -16px; left: 34%; width: 257%; }

.memb-3 img.is-active { top: -18px; }

.memb-3 .hover-block { left: 30%; top: 47%; }

.memb-3::after { top: 22%; left: -37%; width: 65%; }

.memb-4 { top: 0; width: 10.5%; left: 37.5%; }

.memb-4 img { width: 377%; top: -76px; }

@media only screen and (max-width: 1280px) { .memb-4 img { top: -36px; } }

.memb-4 .hover-block { left: 39%; top: 45%; }

.memb-4::after { top: 17%; left: -61%; }

.memb-5 { top: 0; width: 10.5%; left: 47%; }

.memb-5 img { width: 279%; left: 28%; }

.memb-5 .hover-block { left: 30%; top: 45%; }

@media only screen and (max-width: 1700px) { .memb-5 .hover-block { top: 50%; } }

.memb-5::after { top: 20%; left: -80%; }

.memb-6 { top: 0; width: 10.5%; left: 57.5%; }

.memb-6 img { width: 405%; left: 47%; top: -34px; }

.memb-6 .hover-block { left: 26%; top: 42%; }

.memb-6::after { top: 15%; left: -66%; }

@media only screen and (max-width: 1290px) { .memb-6 .is-active { top: -4% !important; } }

@media only screen and (max-width: 1100px) { .memb-6 .is-active { top: -6% !important; } }

.memb-7 { top: 0; width: 10.5%; left: 65.5%; }

.memb-7 .hover-block { left: 51%; top: 50%; }

.memb-7 img { width: 304%; left: 51%; top: -6px; }

.memb-7::after { top: 23%; left: -49%; }

.memb-8 { top: 0; width: 12.5%; left: 76%; }

.memb-8 img { width: 250%; top: -60px; left: 33%; }

.memb-8 .hover-block { left: 68%; top: 44%; }

.memb-8::after { top: 18%; left: -28%; width: 65%; }

@media only screen and (max-width: 1290px) { .memb-8 .is-active { top: -2% !important; } }

@media only screen and (max-width: 1100px) { .memb-8 .is-active { top: -4% !important; } }

.team-mobile { display: none; }

@media only screen and (max-width: 1023px) { .team-mobile { display: flex; width: 100%; flex-flow: row wrap; justify-content: flex-start; text-decoration: none; } }

.team-mobile__item { flex: 1 1 33.3333%; max-width: 33.3333%; position: relative; }

@media only screen and (max-width: 767px) { .team-mobile__item { flex: 1 1 50%; max-width: 50%; }
  .team-mobile__item img { height: 100%; width: 100%; object-fit: cover; } }

.team-mobile__item .member-photo { height: 100%; display: block; max-width: none; object-fit: cover; }

.team-mobile__item .member-name { position: absolute; display: block; width: 180px; padding: 7px 0 18px; bottom: 20px; left: 50%; transform: translateX(-50%); text-align: center; font-size: 23px; line-height: 1.22; font-weight: 600; background-image: url("../img/txt-card-yellow-box-ipad.png"); background-repeat: no-repeat; background-size: 120%; background-position: 29% 50%; color: #1D1D20; text-decoration: none; z-index: 2; }

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) { .team-mobile__item .member-name { background-image: url("../img/txt-card-yellow-box-ipad@2x.png"); } }

@media only screen and (max-width: 767px) { .team-mobile__item .member-name { font-size: 14px; width: 120px; bottom: 8px; background-size: 100%; background-position: 29% 16%; pointer-events: none; } }

/* seven line*/
@media only screen and (max-width: 1023px) { .seven-screen.screen { height: auto; min-height: auto; } }

@media (min-width: 768px) and (max-width: 1023px) { .seven-screen.screen { background-image: url(../img/floor-min.jpg); background-repeat: no-repeat; background-position: bottom center; background-size: contain; } }

@media only screen and (max-width: 767px) { .seven-screen.screen .inner { display: none; } }

@media only screen and (max-width: 1023px) { .seven-screen .inner { padding-top: 110px; height: 550px; } }

.seven-screen .section-title { max-width: 650px; color: #81C7C0; }

@media only screen and (min-width: 1024px) { .seven-screen .section-title { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 10; font-size: 46px; font-size: calc(33px + (46 - 33) * ((100vw - 1024px) / (1920 - 1024))); } }

@media only screen and (min-width: 1024px) and (max-width: 1024px) { .seven-screen .section-title { font-size: 33px; } }

@media only screen and (min-width: 1024px) and (min-width: 1920px) { .seven-screen .section-title { font-size: 46px; } }

@media (min-width: 1024px) and (max-width: 1279px) { .seven-screen .section-title { top: 44%; } }

@media only screen and (max-width: 1023px) { .seven-screen .section-title { display: block; margin: auto; font-size: 47px; font-size: calc(45px + (47 - 45) * ((100vw - 768px) / (1023 - 768))); position: relative; } }

@media only screen and (max-width: 1023px) and (max-width: 768px) { .seven-screen .section-title { font-size: 45px; } }

@media only screen and (max-width: 1023px) and (min-width: 1023px) { .seven-screen .section-title { font-size: 47px; } }

.seven-screen .next-page:not(.next-page2) { z-index: 6; bottom: 8%; right: 50%; }

@media only screen and (max-width: 1023px) { .seven-screen .next-page:not(.next-page2) { bottom: 30px; right: 33%; display: none; } }

.seven-screen .next-page:not(.next-page2) .left-text { font-family: second, roboto, arial, sans-serif; font-size: 22px; font-weight: 500; }

.seven-screen .next-page:not(.next-page2) .bottom-icon { margin-left: 10px; }

.seven-screen .next-page:not(.next-page2) .bottom-icon img { transform: rotate(180deg); }

.seven-screen .next-page2 { z-index: 6; bottom: calc(50vh - (0.39 * 100vw) / 2.1); right: 30px; }

@media (min-width: 1024px) and (max-width: 1279px) { .seven-screen .next-page2 { bottom: calc(50vh - (.39 * 100vw) / 2.5); right: 5px; } }

@media only screen and (max-width: 1023px) { .seven-screen .next-page2 { bottom: 200px; display: none; } }

.seven-screen .next-page2 .left-text { font-family: second, roboto, arial, sans-serif; font-size: 22px; font-weight: 500; }

.seven-screen .next-page2 .bottom-icon { margin-left: 10px; }

.seven-screen .next-page2 .bottom-icon img { transform: rotate(90deg); }

.seven-screen .rocket { left: 15%; bottom: 14%; z-index: 10; width: 188px; transform: rotate(-10deg); }

@media only screen and (max-width: 1023px) { .seven-screen .rocket { left: -9px; bottom: 250px; z-index: 10; width: 164px; transform: rotate(66deg); display: none; } }

@media only screen and (max-width: 767px) { .seven-screen .rocket { display: none; } }

@media only screen and (max-width: 1279px) { .seven-screen .scroll-icon { display: none; } }

@media only screen and (max-width: 1023px) { .seven-screen .floor { bottom: auto; top: 550px; z-index: 0; } }

@media only screen and (max-width: 767px) { .seven-screen .floor { display: none; } }

.inner-portfolio-link { display: none; }

@media only screen and (max-width: 1023px) { .inner-portfolio-link { display: block; position: relative; height: 1050px; margin-top: -55px; padding-top: 150px; }
  .inner-portfolio-link .prev-page { display: none; position: absolute; top: 35px; right: 38%; } }

@media only screen and (max-width: 767px) { .inner-portfolio-link { margin-top: 0; padding-top: 50px; height: 1150px; }
  .inner-portfolio-link .prev-page { display: none; } }

@media only screen and (max-width: 330px) { .inner-portfolio-link { height: 1065px; } }

h2.inner-portfolio-title { display: block; margin: 0 auto 40px 10%; text-align: left; font-family: second, roboto, arial, sans-serif; color: #FFFFFF; font-size: 75px; }

@media only screen and (max-width: 767px) { h2.inner-portfolio-title { font-size: 52px; margin: 0 auto 40px; text-align: center; }
  h2.inner-portfolio-title span { display: inline-block; padding: 10px 20px; border: 4px dashed #ef436e; border-radius: 80px; } }

@media only screen and (max-width: 767px) { .portfolio-small-list { margin-top: 200px; } }

.portfolio-small-list ul { max-width: 550px; margin: auto; display: flex; flex-flow: row wrap; }

.portfolio-small-list li { flex: 1 1 33.3333%; max-width: 33.3333%; padding: 0 8px 30px; }

@media only screen and (max-width: 767px) { .portfolio-small-list li { flex: 1 1 50%; max-width: 50%; } }

.portfolio-small-list .portfolio-small-item-name { position: absolute; pointer-events: none; }

.portfolio-small-list .item-inner { width: 100%; height: 100%; position: relative; }

.portfolio-small-list .portfolio-small-item-name { position: absolute; display: block; width: 125px; padding: 14px 0 18px; text-align: center; font-size: 16px; line-height: 1.22; font-weight: 600; background-image: url(../img/txt-card-yellow-box-ipad.png); background-repeat: no-repeat; background-size: 120%; background-position: 29% 50%; color: #1d1d20; text-decoration: none; z-index: 2; }

.portfolio-small-list li:nth-child(1) .portfolio-small-item-name, .portfolio-small-list li:nth-child(4) .portfolio-small-item-name, .portfolio-small-list li:nth-child(5) .portfolio-small-item-name { top: -10px; left: 15%; transform: rotate(-4deg); }

.portfolio-small-list li:nth-child(2) .portfolio-small-item-name, .portfolio-small-list li:nth-child(3) .portfolio-small-item-name, .portfolio-small-list li:nth-child(6) .portfolio-small-item-name, .portfolio-small-list li:nth-child(7) .portfolio-small-item-name { top: -10px; right: 0; transform: rotate(6deg); }

.ipl-line { position: absolute; top: 0; left: 0; z-index: 0; }

@media only screen and (max-width: 1023px) { .ipl-line { display: none; } }

.lapa-nieb-7_2 { position: absolute; bottom: -115px; width: 470px; right: -50px; }

.lapa-nieb-7_2__ruka { position: absolute; left: 0; z-index: 1; width: 27%; transform: rotate(10deg); transform-origin: 100% bottom; animation: niebieski1 1.5s linear infinite; }

.lapa-nieb-7_2__main { width: 70%; position: relative; z-index: 2; }

@media only screen and (max-width: 1023px) { .lapa-nieb-7_2 { bottom: 8px; width: 357px; } }

@media only screen and (max-width: 767px) { .lapa-nieb-7_2 { bottom: auto; top: 172px; right: -43px; width: 250px; } }

#seven-line { position: absolute; bottom: calc(50% - (100vw * 0.39) / 2); left: 0; width: 100%; z-index: 6; pointer-events: none; }

@media only screen and (max-width: 1279px) { #seven-line { left: -12%; width: 124%; } }

@media only screen and (max-width: 1025px) { #seven-line { bottom: 17%; } }

@media only screen and (max-width: 1023px) { #seven-line { display: none; } }

.seven-line-mob { display: none; }

@media only screen and (max-width: 1023px) { .seven-line-mob { display: block; position: absolute; bottom: 0; right: 0; z-index: -1; } }

.lapa-nieb7 { position: absolute; top: 55%; right: -7%; width: 300px; pointer-events: none; }

.lapa-nieb7__ogon { position: absolute; top: 54%; width: 25% !important; left: 27%; transform-origin: 90% 10%; animation: niebieski1 2s linear infinite; }

.lapa-nieb7 img { width: 100%; }

@media (min-width: 1024px) and (max-width: 1279px) { .lapa-nieb7 { display: none; } }

@media only screen and (max-height: 740px) { .lapa-nieb7 { width: 72%; } }

@media only screen and (max-width: 1023px) { .lapa-nieb7 { top: 167px; right: -6%; width: 351px; } }

/*eight line*/
.eight-screen .slide-next { z-index: 20; }

@media only screen and (max-width: 1023px) { .eight-screen { display: none; } }

/*nine line*/
@media only screen and (max-width: 1023px) { .nine-screen.screen { min-height: auto; height: 1050px; } }

@media only screen and (max-width: 767px) { .nine-screen.screen { height: 710px; } }

.nine-screen .mob-text { display: none; }

@media only screen and (max-width: 1023px) { .nine-screen .mob-text { display: block; font-family: second, roboto, arial, sans-serif; color: #EF436E; font-size: 75px; margin: 110px auto 25px; text-transform: uppercase; } }

@media only screen and (max-width: 767px) { .nine-screen .mob-text { margin-top: 60px; font-size: 52px; } }

.nine-screen .section-text { max-width: 500px; }

@media only screen and (min-width: 1024px) { .nine-screen .section-text { position: absolute; top: 35%; left: 50%; transform: translate(-50%, -50%); } }

@media only screen and (max-width: 1023px) { .nine-screen .section-text { margin: 0 auto; } }

.nine-screen .section-text h2 { font-size: 62px; font-size: calc(40px + (62 - 40) * ((100vw - 1023px) / (1980 - 1023))); }

@media (max-width: 1023px) { .nine-screen .section-text h2 { font-size: 40px; } }

@media (min-width: 1980px) { .nine-screen .section-text h2 { font-size: 62px; } }

@media only screen and (max-width: 1023px) { .nine-screen .section-text h2 { font-size: 53px; max-width: 500px; width: 100%; display: block; margin: 0 auto 30px; line-height: 1.1; } }

@media only screen and (max-width: 767px) { .nine-screen .section-text h2 { font-size: 34px; } }

.nine-screen .section-text .subtitle { font-size: 27px; font-size: calc(17px + (27 - 17) * ((100vw - 1023px) / (1980 - 1023))); margin-bottom: 10px; }

@media (max-width: 1023px) { .nine-screen .section-text .subtitle { font-size: 17px; } }

@media (min-width: 1980px) { .nine-screen .section-text .subtitle { font-size: 27px; } }

.nine-screen .section-text .subtitle a { color: white; }

.nine-screen .section-text .subtitle a:hover { cursor: pointer; text-decoration: underline; }

@media only screen and (max-width: 1023px) { .nine-screen .section-text .subtitle { font-size: 22px; margin-bottom: 20px; } }

@media only screen and (max-width: 767px) { .nine-screen .section-text .subtitle { font-size: 20px; } }

.nine-screen .section-text .undertitle { font-size: 22px; font-size: 22px; font-size: calc(17px + (22 - 17) * ((100vw - 1023px) / (1980 - 1023))); }

@media (max-width: 1023px) { .nine-screen .section-text .undertitle { font-size: 17px; } }

@media (min-width: 1980px) { .nine-screen .section-text .undertitle { font-size: 22px; } }

@media all and (max-height: 800px) { .nine-screen .section-text { top: 34%; } }

@media (min-height: 1000px) and (max-height: 1060px) { .nine-screen .section-text { top: 44%; } }

@media only screen and (max-width: 1289px) { .nine-screen .section-text { top: 43%; } }

@media only screen and (min-width: 1440px) { .nine-screen .section-text { top: 350px; } }

.nine-screen .rocket { width: 218px; left: 30%; bottom: 58%; transform: rotate(-106deg); }

@media only screen and (max-width: 1279px) { .nine-screen .rocket { display: none; } }

.nine-screen .last-line { width: 86%; bottom: 10%; left: 0; position: absolute; pointer-events: none; }

@media only screen and (min-width: 1024px) { .nine-screen .last-line { z-index: 6; } }

@media only screen and (max-width: 1279px) { .nine-screen .last-line { display: none; } }

@media (min-width: 1300px) and (max-width: 1439px) { .nine-screen .last-line { bottom: 15%; } }

@media only screen and (min-width: 1440px) { .nine-screen .last-line { max-height: 77%; } }

@media only screen and (max-width: 1023px) { .nine-screen .last-line { display: none; } }

@media (min-width: 1281px) and (max-height: 810px) { .nine-screen .last-line { max-height: 85%; } }

@media (min-width: 1024px) and (max-width: 1281px) and (max-height: 810px) { .nine-screen .last-line { width: 91%; bottom: 16%; } }

.last-line-mob { display: none; }

@media only screen and (max-width: 1023px) { .last-line-mob { display: block; position: absolute; bottom: 27%; } }

@media only screen and (max-width: 1023px) { .last-line-mob { display: none; } }

.section-free { position: relative; }

@media only screen and (min-width: 1024px) { .section-free { position: absolute; top: 0; left: 0; width: 100%; height: 100%; overflow: hidden; background-image: url(../../img/bg-min.jpg); }
  .section-free .inner { position: relative; height: 100%; } }

@media only screen and (max-width: 1023px) { .section-free { overflow: hidden; }
  .section-free .inner { display: flex; flex-flow: column wrap; } }

@media only screen and (max-width: 1023px) and (max-width: 1024px) { .section-free .prev-page { display: none; } }

@media only screen and (min-width: 1024px) { .section-free { padding-left: 260px; height: 100vh; } }

.section-free .prev-page { z-index: 10; top: 40px; left: 30px; text-align: left; }

.section-free .prev-page .left-icon { transform: rotate(-90deg); }

.section-free .prev-page .right-icon { width: 120px; margin-left: 15px; }

.portfolio-element { top: 9%; z-index: 0; height: 70%; position: absolute; z-index: 0; pointer-events: none; user-select: none; }

@media only screen and (max-width: 1024px) { .portfolio-element { display: none; } }

.close-section-free { z-index: 10; top: 20px; right: 40px; }

.close-section-free img { width: 62px; height: 62px; }

@media only screen and (max-width: 767px) { .close-section-free { top: 20px; right: 20px; }
  .close-section-free img { width: 50px; height: 50px; } }

.team-member-name { position: absolute; top: -141px; left: calc(50% - 250px); padding-top: 248px; text-align: center; width: 609px; z-index: 5; background-image: url(../img/hands-card.png); background-repeat: no-repeat; background-size: cover; height: 410px; }

.team-member-name .name { font-size: 51px; font-weight: 700; color: black; }

@media only screen and (max-width: 1600px) { .team-member-name { transform: scale(0.8); } }

@media only screen and (max-width: 1400px) { .team-member-name { transform: scale(0.7); } }

@media (min-width: 768px) and (max-width: 1024px) { .team-member-name { top: -44px; left: 37%; padding-top: 141px; width: 386px; height: 192px; transform: scale(1); }
  .team-member-name .name { font-size: 27px; } }

@media only screen and (max-width: 767px) { .team-member-name { top: -65px; left: 50%; padding-top: 111px; width: 300px; height: 158px; transform: scale(1) translateX(-50%); }
  .team-member-name .name { font-size: 24px; } }

.top-member-mob { display: none; }

@media only screen and (max-width: 1023px) { .top-member-mob { display: flex; flex: 1 1 100%; width: 100%; flex-flow: row nowrap; position: relative; align-items: flex-start; justify-content: space-between; height: 80px; position: absolute; top: 50%; z-index: 10; }
  .top-member-mob .member-prev, .top-member-mob .member-pseudo-prev, .top-member-mob .member-next, .top-member-mob .member-pseudo-next { display: block; width: 50px; flex: 1 1 50px; max-width: 100px; border: none; background-color: transparent; }
  .top-member-mob .member-prev img, .top-member-mob .member-pseudo-prev img, .top-member-mob .member-next img, .top-member-mob .member-pseudo-next img { width: 50px; }
  .top-member-mob .member-prev img, .top-member-mob .member-pseudo-prev img { transform: rotate(-90deg); }
  .top-member-mob .member-next img, .top-member-mob .member-pseudo-next img { transform: rotate(90deg); }
  .top-member-mob .member-line { flex: 1 1 calc(100% - 100px); max-width: calc(100% - 100px); } }

@media only screen and (max-width: 767px) { .top-member-mob { position: absolute; top: 49vh; z-index: 25; }
  .top-member-mob .member-prev, .top-member-mob .member-pseudo-prev { text-align: left; }
  .top-member-mob .member-prev img, .top-member-mob .member-pseudo-prev img { display: inline-block; }
  .top-member-mob .member-next, .top-member-mob .member-pseudo-next { text-align: right; }
  .top-member-mob .member-next img, .top-member-mob .member-pseudo-next img { display: inline-block; }
  .top-member-mob .member-prev img, .top-member-mob .member-pseudo-prev img, .top-member-mob .member-next img, .top-member-mob .member-pseudo-next img { width: 40px; } }

.team-member-img-full { bottom: -20px; z-index: 6; max-height: 80%; }

.team-member-img-full.left { left: 0px; }

.team-member-img-full.right { right: 15%; }

@media only screen and (min-width: 1025px) { .team-member-img-full { position: absolute; } }

@media only screen and (max-width: 1280px) { .team-member-img-full img { position: relative; z-index: 1; } }

@media only screen and (max-width: 1024px) { .team-member-img-full { width: 250px; margin: auto; display: block; }
  .team-member-img-full img { width: 100%; } }

.team-member-img-small { top: 160px; width: 650px; height: 450px; z-index: 6; top: 16%; }

.team-member-img-small.left { left: 20px; }

.team-member-img-small.right { right: 20px; }

@media only screen and (max-width: 1600px) { .team-member-img-small.right { width: 556px; height: auto; top: 19%; } }

@media only screen and (max-width: 1400px) { .team-member-img-small.right { width: 400px; top: 27%; right: 11%; } }

@media only screen and (max-width: 1280px) { .team-member-img-small.right { z-index: 0; } }

@media only screen and (min-width: 1025px) { .team-member-img-small { position: absolute; } }

@media only screen and (max-width: 1024px) { .team-member-img-small { width: 43%; top: 236px; height: auto; max-width: 90%; } }

.team-member-text { font-size: 18px; color: white; line-height: 28px; top: 68%; left: 50%; max-width: 40%; }

@media only screen and (max-width: 1024px) { .team-member-text { top: 215px; left: 3%; width: 100%; max-width: 90%; } }

@media only screen and (min-width: 1025px) { .team-member-text { position: absolute; } }

.team-member-title { font-size: 36px; font-family: second, roboto, arial, sans-serif; color: #EF436E; margin-bottom: 0.5em; text-transform: uppercase; line-height: 1.2; }

@media only screen and (max-width: 767px) { .team-member-title { font-size: 28px; } }

@media only screen and (max-width: 1024px) { .team-member__info { display: flex; flex-flow: row wrap; position: relative; padding: 150px 20px 30px; }
  .team-member__info .mob-right, .team-member__info .mob-left { flex: 1 1 50%; max-width: 50%; position: relative; }
  .team-member__info .team-member-img-small { width: 100%; }
  .team-member__info .team-member-img-small ~ .team-member-img-full { margin-top: 70px; }
  .team-member__info .team-member-text { padding-left: 20px; } }

@media only screen and (max-width: 767px) { .team-member__info { overflow-y: auto; max-height: 100vh; padding-top: 85px; }
  .team-member__info .mob-right, .team-member__info .mob-left { flex: 1 1 100%; max-width: 100%; position: relative; } }

.people { transition: transform .3s ease-in-out; }

.people .top-member-mob { top: 16vh; width: 100%; z-index: 15; }

.people .top-member-mob .member-prev, .people .top-member-mob .member-pseudo-prev { margin-left: 10px; }

.people .top-member-mob .member-next, .people .top-member-mob .member-pseudo-next { margin-right: 10px; }

@media only screen and (min-width: 1500px) { .people-1 .team-member-img-small.right { width: 400px; right: 13%; top: 19%; } }

@media only screen and (max-width: 767px) { .people-1 .team-member-img-small.right { margin: auto; }
  .people-1 .team-member-img-small.right img { margin: auto; } }

.people-2 .team-member-img-small.right { right: 11%; width: 400px; top: 5%; z-index: 0; }

@media (min-width: 1025px) and (max-width: 1500px) { .people-2 .team-member-img-small.right { display: none; } }

@media (min-width: 1501px) and (max-width: 1599px) { .people-2 .team-member-img-small.right { right: 1%; width: 319px; top: 12%; }
  .people-2 .team-member-img-small.right img { width: 100%; } }

@media only screen and (min-width: 1600px) { .people-2 .team-member-img-small.right { width: 415px; }
  .people-2 .team-member-img-small.right img { width: 100%; } }

@media only screen and (max-width: 1024px) { .people-2 .team-member-img-small.right { position: absolute; top: 0; z-index: 0; width: 100%; } }

@media only screen and (max-width: 1023px) { .people-2 .team-member-img-small.right { z-index: -1; } }

@media only screen and (max-width: 767px) { .people-2 .team-member-img-small.right { width: 100%; position: static; margin: 23px auto 0; transform: none; } }

.people-2 .team-member-img-full { left: 7%; }

@media only screen and (max-width: 767px) { .people-2 .team-member-img-full { display: none; } }

@media (min-width: 1280px) and (max-height: 800px) { .people-2 .team-member-text { max-width: 55%; left: 42%; } }

@media only screen and (max-width: 767px) { .people-2 .team-member-text { width: 100%; max-width: 100%; } }

@media (min-width: 1051px) and (max-width: 1601px) { .people-3 .team-member-img-small.right { width: 620px; height: auto; top: 0%; z-index: 0; right: 10%; } }

@media (min-width: 1024px) and (max-width: 1050px) { .people-3 .team-member-img-small.right { top: -16%; right: -12%; width: 422px; max-width: none; } }

@media only screen and (max-width: 1023px) { .people-3 .team-member-img-small.right { z-index: -1; } }

@media (min-width: 1280px) and (max-height: 801px) { .people-3 .team-member-img-small.right { width: 550px !important; right: 0 !important; } }

@media (min-width: 1500px) and (max-height: 750px) { .people-3 .team-member-img-small.right { width: 510px !important; right: 0 !important; } }

@media (min-width: 1500px) and (max-height: 750px) { .people-3 .team-member-text { max-width: 55%; width: 55%; left: 40%; } }

@media (min-width: 1279px) and (max-width: 1499px) and (max-height: 750px) { .people-3 .team-member-text { max-width: 50%; width: 50%; left: 47%; } }

@media (min-width: 768px) and (max-width: 1023px) { .people-3 .team-member-text { padding-top: 100px; } }

@media only screen and (max-width: 767px) { .people-3 .team-member-text { padding: 40px 0 0; margin: auto; } }

.people-4 .team-member-img-small.right { position: absolute; top: 0; z-index: 0; }

@media (min-width: 1023px) and (max-width: 1050px) { .people-4 .team-member-img-small.right { top: -17%; } }

@media (min-width: 768px) and (max-width: 1023px) { .people-4 .team-member-img-small.right img { min-width: 400px; } }

@media only screen and (max-width: 767px) { .people-4 .team-member-img-small.right { margin: auto; max-width: 100%; display: block; } }

.people-4 .team-member-img-full.left { margin-top: 122px; }

@media only screen and (max-width: 1023px) { .people-4 .team-member-img-full.left { margin-top: 213px; } }

@media only screen and (max-width: 767px) { .people-4 .team-member-img-full.left { display: none; } }

@media (min-width: 1280px) and (max-height: 801px) { .people-4 .team-member-text { margin-top: -100px; } }

@media (min-width: 768px) and (max-width: 1023px) { .people-4 .team-member-text { padding-top: 140px; } }

@media only screen and (max-width: 1023px) { .people-4 .mob-left { padding-right: 40px; } }

@media only screen and (max-width: 767px) { .people-4 .mob-left { margin-top: 40px; padding-right: 0; } }

@media only screen and (max-width: 1600px) { .people-5 .team-member-img-small.right, .people-5 .team-member-img-small.left, .people-7 .team-member-img-small.right, .people-7 .team-member-img-small.left { max-width: 64vh; z-index: 0; } }

.people-6 .team-member-img-full { width: 100%; }

@media only screen and (max-width: 1370px) { .people-7 .team-member-text { top: 55%; left: 36%; } }

@media only screen and (max-width: 1600px) { .people-8 .team-member-img-small.right { top: 19%; max-width: 60vh; right: 10%; z-index: 0; } }

@media only screen and (min-width: 1800px) { .people-8 .team-member-img-small.right { top: 20%; right: 20%; } }

.start-slider-people.people-slider-wrapper { width: 800vw; height: 100vh; position: absolute; left: 0; top: 0; transform: translate3d(0, 100%, 0); transition: transform .4s ease-in-out; z-index: 99; }

.start-slider-people.people-slider-wrapper .people { width: 100vw; }

.start-slider-people.people-slider-wrapper [data-member="1"] { left: 0; }

.start-slider-people.people-slider-wrapper [data-member="2"] { left: 100vw; }

.start-slider-people.people-slider-wrapper [data-member="3"] { left: 200vw; }

.start-slider-people.people-slider-wrapper [data-member="4"] { left: 300vw; }

.start-slider-people.people-slider-wrapper [data-member="5"] { left: 400vw; }

.start-slider-people.people-slider-wrapper [data-member="6"] { left: 500vw; }

.start-slider-people.people-slider-wrapper [data-member="7"] { left: 600vw; }

.start-slider-people.people-slider-wrapper [data-member="8"] { left: 700vw; }

.start-slider-works.portfolio-slider-wrapper { width: 700vw; height: 100vh; position: absolute; left: 0; top: 0; transform: translate3d(0, 100%, 0); transition: transform .4s ease-in-out; z-index: 99; }

.start-slider-works.portfolio-slider-wrapper .portfolio-item { width: 100vw; }

.start-slider-works.portfolio-slider-wrapper [data-work="1"] { left: 0; }

.start-slider-works.portfolio-slider-wrapper [data-work="2"] { left: 100vw; }

.start-slider-works.portfolio-slider-wrapper [data-work="3"] { left: 200vw; }

.start-slider-works.portfolio-slider-wrapper [data-work="4"] { left: 300vw; }

.start-slider-works.portfolio-slider-wrapper [data-work="5"] { left: 400vw; }

.start-slider-works.portfolio-slider-wrapper [data-work="6"] { left: 500vw; }

.start-slider-works.portfolio-slider-wrapper [data-work="7"] { left: 600vw; }

.team-member-credo { font-size: 40px; position: absolute; text-align: center; width: 102%; left: -1%; bottom: 0; z-index: 10; padding: 20px; font-family: second, roboto, arial, sans-serif; box-shadow: inset -15px -43px 80px black; }

@media only screen and (max-width: 1024px) { .team-member-credo { display: none; } }

@media only screen and (min-width: 1025px) { .people-1 .team-member-img-full img { width: 720px; } }

@media only screen and (max-width: 767px) { .people-1 .mob-left { margin-top: 40px; } }

@media only screen and (min-width: 1025px) { .people-2 .team-member-name { position: absolute; top: -152px; left: calc(50% - 303px); padding-top: 252px; }
  .people-2 .team-member-img-full img { width: 365px; }
  .people-2 .team-member-img-small { top: 3%; width: 440px; }
  .people-2 .team-member-text { top: auto; bottom: 100px; } }

@media only screen and (min-width: 1800px) { .people-2 .team-member-img-small.right { width: 520px; } }

@media only screen and (max-width: 1023px) { .people-2 .team-member-img-small.right { left: 50%; transform: translateX(-50%); padding-left: 27px; }
  .people-2 .team-member-img-small.right img { width: 100%; } }

@media only screen and (min-width: 1025px) { .people-3 .team-member-name, .people-6 .team-member-name, .people-8 .team-member-name { position: absolute; top: -152px; left: calc(50% - 303px); padding-top: 252px; }
  .people-3 .team-member-img-full img, .people-6 .team-member-img-full img, .people-8 .team-member-img-full img { width: 380px; }
  .people-3 .team-member-img-small, .people-6 .team-member-img-small, .people-8 .team-member-img-small { top: 3%; width: 550px; }
  .people-3 .team-member-text, .people-6 .team-member-text, .people-8 .team-member-text { top: auto; bottom: 100px; } }

@media only screen and (min-width: 1280px) { .people-3 .team-member-img-full.left { left: 7%; } }

@media only screen and (min-width: 1025px) { .people-3 .team-member-img-small { width: 42%; } }

@media only screen and (max-width: 1025px) { .people-3 .team-member-img-small { position: absolute; } }

@media only screen and (max-width: 767px) { .people-3 .team-member-img-small { position: static; width: 100%; }
  .people-3 .team-member-img-full { display: none !important; }
  .people-3 .mob-right { order: 5; max-width: 100%; padding: 0; margin-top: 186px; } }

@media (min-width: 768px) and (max-width: 1023px) { .people-3 .team-member-img-small.right > img { max-width: 483px; margin: auto; position: absolute; left: -82px; top: -69px; } }

@media only screen and (max-width: 767px) { .people-3 .team-member-img-small.right { max-width: 100%; margin: auto; position: absolute; left: 50%; top: 10px; transform: translateX(-50%); } }

@media only screen and (max-width: 1023px) { .people-3 .team-member-img-full.left { margin-top: 138px; } }

@media (min-width: 768px) and (max-width: 1023px) { .people-3 .team-member__info .mob-right { padding-left: 40px; } }

@media only screen and (min-width: 1025px) { .people-4 .team-member-img-full img { width: 780px; } }

@media only screen and (max-width: 1400px) { .people-4 .team-member-img-full img { width: 545px; } }

@media (min-width: 1050px) { .people-4 .team-member-img-full.left { left: -74px; bottom: 50px; } }

@media only screen and (min-width: 1280px) { .people-4 .team-member-img-small.right { top: 140px; right: 7%; } }

@media (min-width: 1050px) and (max-height: 899px) { .people-4 .team-member-img-small.right { top: 78px; right: -1%; } }

@media (min-width: 1500px) and (min-height: 750px) and (max-height: 899px) { .people-4 .team-member-text { top: 56%; } }

@media (min-width: 1400px) and (min-height: 750px) and (max-height: 900px) { .people-4 .team-member-text { top: 56%; } }

@media (min-width: 1279px) and (max-height: 749px) { .people-4 .team-member-text { width: 55%; max-width: 55%; left: 44%; top: auto; bottom: 100px; } }

@media only screen and (max-width: 767px) { .people-4 .team-member-img-small.right { position: static; margin-bottom: -76px; } }

@media only screen and (min-width: 1025px) { .people-5 .team-member-img-full img { width: 340px; }
  .people-5 .team-member-text { max-width: 300px; } }

@media only screen and (min-width: 1600px) { .people-5 .team-member-text { left: 35%; max-width: 300px; } }

@media only screen and (max-width: 1600px) { .people-5 .team-member-text { left: calc(36% - 150px); } }

@media only screen and (min-width: 1025px) { .people-6 .team-member-img-full img { width: 740px; } }

@media only screen and (max-width: 1600px) { .people-6 .team-member-img-full img { width: 545px; } }

.people-6 .team-member-img-small.right { max-width: 60vh; z-index: 0; right: 7%; top: 17%; }

@media only screen and (max-width: 1300px) { .people-6 .team-member-img-small.right { top: 19%; } }

@media (min-width: 1500px) and (max-height: 750px) { .people-6 .team-member-img-small.right { right: 3%; top: 12%; } }

@media only screen and (min-width: 1025px) { .people-7 .team-member-img-full { right: 4%; }
  .people-7 .team-member-img-full img { width: 340px; }
  .people-7 .team-member-text { max-width: 273px; top: 43%; } }

.portfolio .inner { display: flex; flex-flow: column wrap; }

.portfolio .prev-page { right: 43%; top: 7%; }

@media (min-width: 1024px) and (max-height: 750px) { .portfolio .prev-page { right: 43%; top: 1%; z-index: 10; } }

.portfolio .prev-page .bottom-icon, .portfolio .prev-page .top-icon { display: inline-block; vertical-align: middle; }

.portfolio .prev-page .top-icon img { width: 120px; }

.portfolio .prev-page .left-text { display: inline-block; vertical-align: middle; font-family: second, roboto, arial, sans-serif; font-size: 22px; color: white; font-weight: 700; margin-left: 10px; }

.portfolio .next-page { top: 10%; right: 30px; text-align: right; }

.portfolio .next-page .left-text { display: block; font-family: second, roboto, arial, sans-serif; font-size: 30px; }

.portfolio .next-page .bottom-icon img { transform: rotate(90deg); }

.portfolio .rocket { top: 28%; left: 39%; transform: rotate(45deg); }

@media only screen and (max-width: 1023px) { .portfolio .rocket { display: none; } }

.portfolio-small { position: absolute; top: 13%; left: 0; z-index: 0; width: 100%; }

.to-row-3 { display: flex; flex-flow: row wrap; max-width: 94%; margin: auto; }

.to-row-3 .col { max-width: 33.3333%; flex: 1 1 33.3333%; max-height: 310px; z-index: 7; }

.to-row-3 .col:last-child { position: relative; }

.to-row-3 .col:last-child::after { content: ''; z-index: -1; position: absolute; bottom: -6px; right: 10%; width: 119px; height: 125px; background-image: url("../img/stun.png"); background-repeat: no-repeat; transform: rotate(150deg); }

@media only screen and (min-width: 1500px) { .to-row-3 .col:last-child::after { bottom: -30px; } }

@media only screen and (min-width: 1800px) { .to-row-3 .col:last-child::after { bottom: -74px; } }

@media only screen and (max-width: 1290px) { .to-row-3 .col img { transform: scale(1.4); } }

.two-work { position: relative; z-index: 1; height: 100%; }

.two-work a:not(:last-child) { position: absolute; width: 90%; height: 85%; z-index: 1; left: 5%; z-index: 1; }

@media only screen and (min-width: 1800px) { .two-work a:not(:last-child) { height: 100%; } }

.two-work a:last-child { position: absolute; width: 90%; height: 82%; bottom: -5%; right: 0; z-index: 2; }

@media only screen and (min-width: 1800px) { .two-work a:last-child { height: 85%; } }

.two-work a:last-child img { margin-top: -10%; }

.two-work a { overflow: hidden; }

.two-work a:hover { z-index: 3; }

.two-works-title { position: absolute; top: 0; left: 0; width: 50%; z-index: 4; }

.rocket-free-portfolio { top: 26%; left: 38%; transform: rotate(30deg); }

.portfolio-item .inner { display: flex; align-items: center; }

@media only screen and (max-width: 1279px) { .portfolio-item .inner { flex-flow: row wrap; } }

.portfolio-item .prev-page { z-index: 15; }

.portfolio-item .prev-page .bottom-icon { display: inline-block; vertical-align: middle; }

.portfolio-item .prev-page .bottom-icon img { transform: rotate(-90deg); }

.portfolio-item .prev-page .top-icon { display: inline-block; vertical-align: middle; font-size: 30px; color: white; margin-left: 10px; }

@media only screen and (max-width: 1279px) { .portfolio-item .prev-page { display: none; } }

.portfolio-item .only-close { top: 40px; right: 40px; z-index: 15; }

.portfolio-item .only-close img { width: 75px; height: 75px; }

@media only screen and (max-width: 767px) { .portfolio-item .only-close { top: 20px; right: 20px; }
  .portfolio-item .only-close img { width: 40px; height: 40px; } }

.portfolio-item .portfolio__title { background-image: url(../img/portfolio-item-title-bg.png); background-size: contain; position: absolute; top: 0; right: 20%; width: 343px; height: 200px; z-index: 9; padding-top: 98px; text-align: center; font-family: second, roboto, arial, sans-serif; font-size: 30px; color: #ABDFD5; user-select: none; }

@media (min-width: 1024px) and (max-height: 750px) { .portfolio-item .portfolio__title { top: -32px; } }

@media only screen and (max-width: 1279px) { .portfolio-item .portfolio__title { background-image: none; display: block; text-align: center; position: relative; width: 100%; right: 0; z-index: 0; } }

@media only screen and (max-width: 1023px) { .portfolio-item .portfolio__title { max-height: 140px; } }

@media only screen and (max-width: 767px) { .portfolio-item .portfolio__title { height: 156px; padding-top: 21px; top: 0; margin: 20px auto 40px; } }

.portfolio-item .portfolio-elem { display: block; position: absolute; left: 0; top: 10%; width: 100%; }

@media only screen and (max-width: 1279px) { .portfolio-item .portfolio-elem { display: none; } }

.portfolio-item .rocket { bottom: 19%; left: 54%; transform: rotate(-25deg); width: 150px; }

@media only screen and (max-width: 1025px) { .portfolio-item .rocket { display: none; } }

.portfolio-item .plane-img { position: absolute; top: -5%; right: -6%; height: 178px; }

@media only screen and (max-width: 1025px) { .portfolio-item .plane-img { display: none; } }

@media (min-width: 768px) and (max-width: 1279px) { .portfolio-item .top-member-mob { margin-top: -120px; } }

@media only screen and (max-width: 1023px) { .portfolio-item .top-member-mob img { margin: auto; } }

@media (min-width: 1023px) and (max-width: 1050px) { .portfolio-item .top-member-mob { position: absolute; top: 50%; left: 0; z-index: 10; width: 100%; display: flex; justify-content: space-between; flex: 1 1 100%; flex-flow: row nowrap; align-items: flex-start; height: 80px; }
  .portfolio-item .top-member-mob .member-prev, .portfolio-item .top-member-mob .member-pseudo-prev, .portfolio-item .top-member-mob .member-next, .portfolio-item .top-member-mob .member-pseudo-next { display: block; width: 50px; flex: 1 1 50px; max-width: 100px; border: none; background-color: transparent; }
  .portfolio-item .top-member-mob .member-prev img, .portfolio-item .top-member-mob .member-pseudo-prev img, .portfolio-item .top-member-mob .member-next img, .portfolio-item .top-member-mob .member-pseudo-next img { width: 50px; }
  .portfolio-item .top-member-mob .member-prev img, .portfolio-item .top-member-mob .member-pseudo-prev img { transform: rotate(-90deg); }
  .portfolio-item .top-member-mob .member-next img, .portfolio-item .top-member-mob .member-pseudo-next img { transform: rotate(90deg); }
  .portfolio-item .top-member-mob .member-line { flex: 1 1 calc(100% - 100px); max-width: calc(100% - 100px); }
  .portfolio-item .top-member-mob .member-prev, .portfolio-item .top-member-mob .member-pseudo-prev { text-align: left; padding-left: 30px; max-width: 100px; }
  .portfolio-item .top-member-mob .member-next, .portfolio-item .top-member-mob .member-pseudo-next { text-align: right; padding-right: 30px; max-width: 100px; } }

@media only screen and (max-width: 767px) { .portfolio-item .top-member-mob { margin-top: -186px; } }

@media (min-width: 1024px) and (max-width: 1050px) { .section-free.portfolio-item .inner::after { content: ''; display: block; position: absolute; width: 50px; height: 50px; z-index: 10; right: 90px; top: 350px; background-image: url("../img/move_vertical_arrows_blue.png"); background-size: cover; animation-name: move-vert; animation-duration: 10s; animation-iteration-count: infinite; } }

@media only screen and (max-width: 767px) { .section-free.portfolio-item .inner::after { content: ''; display: block; position: absolute; width: 50px; height: 50px; z-index: 10; right: 26px; top: 197px; background-image: url("../img/move_vertical_arrows_blue.png"); background-size: cover; animation-name: move-vert; animation-duration: 10s; animation-iteration-count: infinite; } }

@media only screen and (min-width: 1700px) { .section-free.portfolio-item .portfolio-info .left { min-width: 800px; } }

@media only screen and (max-width: 1279px) { .section-free.portfolio-item .portfolio-info { padding-top: 0; margin-top: -50px; flex-flow: row wrap; }
  .section-free.portfolio-item .portfolio-info .left, .section-free.portfolio-item .portfolio-info .right { padding: 30px; width: 100%; max-width: 100%; flex: 1 1 100%; }
  .section-free.portfolio-item .portfolio-info .left img, .section-free.portfolio-item .portfolio-info .right img { max-width: 100%; } }

@media only screen and (max-width: 1050px) { .section-free.portfolio-item .portfolio-info { max-height: calc(100vh - 200px); overflow-y: auto; max-width: 80%; margin: auto; } }

@media only screen and (max-width: 767px) { .section-free.portfolio-item .portfolio-info { margin-top: -90px; max-width: 80%; padding: 0; max-height: calc(100vh - 130px); }
  .section-free.portfolio-item .portfolio-info .left, .section-free.portfolio-item .portfolio-info .right { padding: 10px; }
  .section-free.portfolio-item .portfolio-info .right { padding-top: 30px; } }

.portfolio-info { padding: 50px; display: flex; flex-flow: row nowrap; justify-content: center; position: relative; z-index: 2; }

@media only screen and (max-width: 767px) { .portfolio-info { padding: 10px; } }

.portfolio-info .left { flex: 1 1 500px; padding-right: 30px; }

.portfolio-info .left img { width: auto; display: block; }

.portfolio-info .right { padding-top: 100px; flex: 1 1 30%; max-width: 30%; margin-right: 10%; min-width: 300px; }

@media only screen and (max-width: 1023px) { .portfolio-info .right { margin-right: 0; } }

@media only screen and (max-width: 767px) { .portfolio-info .right { min-width: 1%; } }

.portfolio-info .right-inner { background-image: url("../img/portfolio-text-bg.jpg"); background-size: contain; background-repeat: no-repeat; padding: 45px; color: white; width: 385px; height: 586px; font-size: 15px; }

@media only screen and (max-width: 1279px) { .portfolio-info .right-inner { background-image: none; padding: 0; width: 100%; height: auto; } }

.portfolio-info .right-inner .subtitle { font-size: 17px; text-transform: uppercase; margin-bottom: 20px; }

.portfolio-info .right-inner .subtitle:not(:first-child) { margin-top: 20px; }

@media (min-width: 1024px) and (max-height: 750px) { .portfolio-info { align-items: center; }
  .portfolio-info .left { max-height: 59vh; position: relative; top: -100px; }
  .portfolio-info .right-inner { margin-bottom: -40px; } }

.item1 .portfolio-info .left { padding-left: 150px; flex: 1 1 100%; }

@media only screen and (max-height: 750px) { .item1 .portfolio-info .left { top: 0; } }

@media only screen and (max-width: 1279px) { .item1 .portfolio-info .left img { display: block; width: 100%; position: static; } }

.item1 .portfolio-info .left img:not(:last-child) { max-height: 32vh; margin-bottom: 3vh; margin-left: auto; }

@media only screen and (max-width: 1279px) { .item1 .portfolio-info .left img:not(:last-child) { display: none; } }

.item1 .portfolio-info .left .animate3 { position: absolute; bottom: -5%; right: 48%; width: 600px; max-width: 55%; }

@media (min-width: 1024px) and (max-width: 1279px) { .item1 .portfolio-info .left .animate3 { position: static; max-width: 400px; margin: 0 auto -65px; } }

@media only screen and (max-width: 1023px) { .item1 .portfolio-info .left .animate3 { position: static; margin-bottom: -60px; } }

.item2 .portfolio-info .left { padding-top: 50px; }

.item2 .portfolio-info .left .animate1 { margin-bottom: 3vh; }

.item3 .portfolio-info { align-items: center; }

@media only screen and (max-height: 750px) { .item3 .portfolio-info .left { top: 0; } }

.item3 .portfolio-info .left img { display: inline-block; width: 45%; margin-left: 3%; }

.item4 .portfolio-info { align-items: flex-end; }

@media only screen and (max-width: 1279px) { .item4 .portfolio-info .left { margin: auto; max-width: 400px !important; } }

.item4 .left { text-align: center; }

@media only screen and (min-width: 1300px) { .item4 .left { min-width: 650px; } }

.item4 .left img { max-width: 470px; margin: 0 auto 30px; }

@media only screen and (max-width: 767px) { .item4 .left img { margin-bottom: 20px; }
  .item4 .left img:last-child { margin-bottom: 0; } }

.item5 .portfolio-info { align-items: center; }

@media (min-width: 1024px) and (max-width: 1279px) { .item5 .portfolio-info .left img { margin: 0 auto -50px; max-width: 400px !important; } }

.item5 .left img { margin: 86px 0 0; }

@media only screen and (max-width: 1023px) { .item5 .left img { margin: 0; } }

.item6 .portfolio-info { align-items: center; }

@media only screen and (max-width: 1300px) { .item6 .portfolio-info .left { top: 0; } }

.item6 .left { min-width: 660px; }

@media (min-width: 1024px) and (max-height: 810px) { .item6 .left { top: 0; } }

.item6 .left img { max-width: 427px; margin: auto; }

@media only screen and (max-width: 1300px) { .item6 .left img { max-width: 330px; } }

@media (min-width: 1024px) and (max-width: 1279px) { .item6 .left img { max-height: 400px; } }

@media (min-width: 1024px) and (max-height: 810px) { .item6 .left img { max-width: none; min-width: 1px; max-height: 500px; } }

@media only screen and (max-width: 1300px) { .item6 .left { max-width: 50%; flex: 1 1 50%; min-width: 0; padding: 40px; } }

.item7 .portfolio-info { align-items: center; }

.item7 .portfolio-info .left { top: 0; }

@media only screen and (max-width: 1300px) { .item7 .portfolio-info .left { top: 0; } }

.item7 .portfolio-info .right-inner { font-size: 15px; font-size: calc(13px + (15 - 13) * ((100vw - 1280px) / (1980 - 1280))); }

@media (max-width: 1280px) { .item7 .portfolio-info .right-inner { font-size: 13px; } }

@media (min-width: 1980px) { .item7 .portfolio-info .right-inner { font-size: 15px; } }

.item7 .portfolio-info .right-inner .subtitle { font-size: 15px; text-align: center; }

.item7 .left img { width: calc(49% - 30px); float: left; position: static; margin: 0 30px 30px 0; }

@media only screen and (max-width: 767px) { .item7 .left img { width: 100%; float: none; margin: 0 auto 20px; } }

.item7 .left::after { content: ''; display: table; width: 100%; clear: both; }

/*poma*/
@media screen and (max-width: 1380px) { .team-member-text { font-size: 16px; line-height: 1.2; }
  .people-1 .team-member-img-full img { width: 618px; } }

.team-member:hover { z-index: 11; }
