@import "../fonts/fonts";

@include font(second, Verveine-Regular);

.loader {
    background-color: #1b1b1b;
    position: fixed;
    z-index: 200;
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &__inside {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative; }

    .countLoad {
        height: 80px;
        width: 80px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid dodgerblue;
        @include r(767) {
            width: 60px;
            height: 60px; } }
    #js-count {
        @include r(767) {
            font-size: 14px; }
        &::after {
            content: '%'; } }
    &__anim {
        position: relative;
        width: 350px;
        margin-top: 30px;
        @include r(767) {
            width: 250px;
            margin-top: 20px; } }
    &__anim-img {
        position: absolute;
        max-width: 100%;
        width: 100%;
        left: 0;
        bottom: 0;
        max-height: initial; }
    &__anim-wrap {
        overflow: hidden;
        position: absolute;
        bottom: 7px;
        left: 0;
        width: 100%; }
    &__logo {
        max-width: 165px;
        height: 68px;
        margin-top: 20px;
        @include r(767) {
            max-width: 120px;
            height: auto; } } }

.super-wrap {
    @include rmin(1024) {
        overflow: hidden;
        width: 100vw;
        height: 100vh;
        position: relative; } }
body {
    color: white; }

body {
    background-color: #393939;
    font-size: 23px;
    line-height: 1.4;
    font-family: $fontR; }

img {
    max-width: 100%; }

body {
    .mobile {
        @include rmin(768) {
            display: none; } }

    .tablet {
        @include r(767) {
            display: none; }


        @include rmin(1025) {
            display: none; } }

    .desctop {
        @include r(1024) {
            display: none; } }

    .to-top {
        @include r(767) {
            display: none; } }

    .to-bottom {
        @include rmin(1024) {
            display: none; } }
    .big {
        @include rmin(1279) {
            display: none; } } }
a {
    text-decoration: none; }
.main {
    position: relative; }
.wrapper {
    background-size: 200% 100vh;
    background-position: 50% 0;
    background-attachment: fixed;
    @include rmin(768) {
        background-size: cover;
        background-position: 0 0; }
    @include rIn(1024, 1050) {
        background-size: 100vw 100vh;
        background-repeat: repeat; }
    @include rmin(1024) {
        width: 100vw;
        height: 100vh;
        overflow: hidden; }
    @include r(1023) {
        overflow-x: hidden;
        overflow-y: auto; } }

.icon {
    display: inline-block; }

.fontV {
    font-family: $fontV; }

/*btns prev & next */

.btn-icons,
.btn-icon,
.btn-text-icon {
    display: block;
    position: absolute;
    background-color: transparent;
    border: none;

    img {
        width: 50px;
        display: inline-block; } }

// .btn-icons,
// .btn-icon
.btn-text-icon {
    .bottom-icon {
        display: inline-block;
        vertical-align: middle; }

    .left-text,
    .right-text {
        display: inline-block;
        padding-top: 9px;
        font-size: 22px;
        font-weight: 700;
        color: #fff;
        padding-bottom: 10px;
        margin-left: 15px; } }

/*rocket */
.rocket {
    position: absolute;
    width: 125px; }

/*scroll btn */
.scroll-icon {
    display: block;
    margin: auto;
    max-width: 200px;
    top: calc(50% - 35px);
    right: 30px;
    position: absolute;

    @include rmin(1024) {
        &:hover {
            cursor: pointer;

            span {
                opacity: 0.77; } } }


    span {
        display: inline-block;
        vertical-align: middle;
        color: white;

        @include tr(opacity 0.3s ease);

        &:first-child {
            width: 70px;
            height: 70px;

            & > svg {
                width: 100%;
                height: 100%;
                fill: white; } }

        &:last-child {
            margin-left: 14px;
            font-size: 32px; } } }
.text-center {
    text-align: center; }
.btn-under.cloned {
    opacity: 0; }

.prev-page-under-6 {
    left: 30px;
    bottom: 18%;
    .bottom-icon img {
        transform: rotate(-90deg); }
    @include rmin(1024) {
        left: -200px; }
    @include r(1023) {
        bottom: 100vw; }
    @include r(1023) {
        display: none; } }
.prev-page-under-7 {
    left: 30px;
    bottom: calc(50vh - (0.39 * 100vw) / 2.1);
    text-align: left;
    z-index: 6;
    @include rIn(1024,1025) {
        bottom: 17%; }
    .top-icon img {
        width: 120px; }
    .bottom-icon img {
        transform: rotate(-90deg); }
    @include r(1023) {
        bottom: auto;
        top: 453px;
        left: 50px;
        .bottom-icon img {
            transform: rotate(-5deg); } }
    @include r(1023) {
        display: none; } }
.prev-page-under-8 {
    z-index: 6;
    bottom: 7%;
    left: 30px;
    text-align: left;
    @include r(1023) {
        display: flex;
        flex-flow: row wrap;
        text-align: left;
        bottom: 26%; }
    @include r(1023) {
        display: none; }
    @media (min-width: 1500px) and (max-width: 1600px) and (max-height: 750px) {
        display: flex;
        flex-flow: row wrap;
        .left-text {
            display: block;
            flex: 1 1 100%;
            max-width: 100%;
            order: -1; } }
    .bottom-icon {
        margin-bottom: 10px;
        img {
            transform: rotate(-101deg); }
        @include r(1023) {
            order: 5;
            flex: 1 1 100%; } }
    .top-icon {
        margin-left: -10px;
        @include r(1023) {
            margin-left: -10px;
            margin-bottom: 12px; }
        img {
            width: 120px; } }
    .left-text {
        display: inline-block;
        margin-left: 10px;
        padding-top: 9px;
        font-size: 22px;
        font-weight: 700;
        color: #fff;
        padding-bottom: 10px;
        margin-left: 15px;
        font-family: $fontV; }
    @include r(1370) {
        bottom: 15%;
        .bottom-icon,
        .top-icon {
            display: inline-block;
            vertical-align: middle; }
        .top-icon {
            margin-left: 10px; } }
    .bottom-icon {
        display: inline-block;
        vertical-align: middle; } }
.hidden {
    display: none !important; }

.people-slider-wrapper,
.portfolio-slider-wrapper {
    @include r(1023) {
        display: block;
        position: fixed;
        width: 100%;
        height: 100%;
        min-height: 100vh;
        top: 0;
        left: 0;
        background-image: url("../../img/bg-min.jpg");
        background-size: cover;
        background-position: 0 0;
        // opacity: 0
        // z-index: -100
        transform: translateX(-100%);
        &.open {
            transform: translateX(0%);
            z-index: 102; } } }



/*transforms*/
@include rmin(1024) {
    #fullpage {
        position: relative;
        transform: translate3d(0,0,0);
        transition: transform .8s ease-in-out;
        width: 100vw;
        height: 100vh; }

    .start-fullpage .section.screen {
        position: absolute;
        &.first-screen {
            left: 0;
            top: 0; }
        &.second-screen {
            left: 100%;
            top: 0; }
        &.third-screen {
            left: 100%;
            top: 100%; }
        &.four-screen {
            left: 100%;
            top: 200%; }
        &.five-screen {
            left: 100%;
            top: 300%; }
        &.six-screen {
            left: 200%;
            top: 300%; }
        &.seven-screen {
            left: 300%;
            top: 300%; }
        &.eight-screen {
            left: 300%;
            top: 400%; }
        &.nine-screen {
            left: 400%;
            top: 400%; } } }
@include r(1023) {
    #fullpage {
        transform: none !important; } }




//// tablet & mobile rules
@include r(1023) {
    html {
        overflow: auto;
        body {
            height: auto; }
        .wrapper {
            height: auto;
            overflow-x: hidden; } } }
.p10 {
    display: none;
    @include rIn(1280, 1300) {
        display: block; } }



/// scrollbar


// [data-simplebar]
//   position: relative
//   z-index: 0
//   overflow: hidden !important
//   max-height: inherit
//   -webkit-overflow-scrolling: touch

// [data-simplebar="init"]
//   display: flex

// .simplebar-scroll-content
//   overflow-x: hidden !important
//   overflow-y: scroll
//   min-width: 100% !important
//   max-height: inherit !important
//   box-sizing: content-box !important

// .simplebar-content
// 	overflow-y: hidden !important
// 	overflow-x: scroll
// 	box-sizing: border-box !important
// 	min-height: 100% !important
// .scrollEnabled .simplebar-content
// 	padding-right: 14px
// .simplebar-track
//   z-index: 1
//   position: absolute
//   right: 0
//   bottom: 0
//   width: 11px

// .simplebar-scrollbar
//   position: absolute
//   right: 2px
//   width: 9px
//   min-height: 10px
//   &:before
//     position: absolute
//     content: ""
//     background-color: $menu-link-color
//     border-radius: 7px
//     left: 0
//     right: 0
//     opacity: 0
//     transition: opacity 0.2s linear

// .simplebar-track
// 	&.vertical
// 		background-color: #ffffff
// 		width: 14px
//   &:hover .simplebar-scrollbar:before, .simplebar-scrollbar.visible:before
//     opacity: 1
//     transition: opacity 0 linear
//   &.vertical
//     top: 0
//     .simplebar-scrollbar:before
//       top: 2px
//       bottom: 2px
//   &.horizontal
//     left: 0
//     width: auto
//     height: 11px
//     .simplebar-scrollbar
//       &:before
//         height: 100%
//         left: 2px
//         right: 2px
//       right: auto
//       top: 2px
//       height: 7px
//       min-height: 0
//       min-width: 10px
//       width: auto
