@keyframes move-vert {
    0% {
        transform: translateY(0%); }
    12% {
        transform: translateY(0%); }
    12.5% {
        transform: translateY(20%); }
    16.5% {
        transform: translateY(0%); }
    15% {
        transform: translateY(0%); }
    64% {
        transform: translateY(0%); }
    65% {
        transform: translateY(20%); }
    69% {
        transform: translateY(0%); }
    0% {
        transform: translateY(0%); } }

.call-menu {
    width: 43px;
    height: 33px;
    position: fixed;
    top: 25px;
    left: 20px;
    z-index: 101;
    background: none;
    border: none;

    @include r(767) {
        display: none; }

    @include rmin(1024) {
        display: none; }

    span {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        height: 5px;
        background-color: #fff;

        @include tr(background-color 0.2s ease); }

    i {
        position: absolute;
        left: 0;
        width: 100%;
        height: 5px;
        background-color: #fff; }

    i:first-child {
        top: -15px; }

    i:last-child {
        bottom: -15px; } }

.tablet-top-logo {
    width: 185px;
    height: 34px;
    position: fixed;
    top: 20px;
    left: 120px;
    z-index: 99;
    display: none;
    @include rIn(768, 1023) {
        display: block; }
    img {
        max-width: 100%; } }

.menu-active .call-menu span {
    background-color: transparent; }

// nav block
.navigation {
    display: flex;
    flex-flow: column wrap;
    position: fixed;
    z-index: 100;
    left: 0;

    @include r(767) {
        bottom: 0; }

    @include rmin(768) {
        top: 0;
        background-color: rgba(0, 0, 0, 0.65);
        width: 255px;
        padding: 9vh 0px 50px 30px;
        height: 100%; }

    @include rIn(768, 1023) {
        transform: translateX(-100%); }
    @include rh(800) {
        padding: 50px 0 50px 30px; }
    @include rh(750) {
        padding: 50px 0 50px 30px; } }

.navigaton__menu {
    @include rhIn(735, 800) {
        margin: auto; }
    @include rh(730) {
        margin-top: 0; } }

.navigaton__logo {
    margin-bottom: 65px;
    padding-right: 30px;
    max-width: 100%;
    text-align: center;

    img {
        display: inline-block;
        max-width: 165px;
        height: 68px; }

    @include rh(750) {
        margin-bottom: 5vh; } }

.menu__inner {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    user-select: none;

    @include r(767) {
        flex-flow: row nowrap;
        background-color: #1D1D20;
        position: fixed;
        bottom: 0;
        left: 0; } }

.menu__item {
    position: relative;

    .icon-wrap {
        @include r(767) {
            display: block;
            margin: auto; } } }

.menu__item {
    .icon-wrap {
        width: 37px;
        height: 37px;
        text-align: center;
        position: relative;
        margin-right: 18px;

        @include rmin(768) {
            display: inline-block;
            vertical-align: middle; }
        svg {
            display: inline-block;
            max-width: 100%;
            max-height: 32px;
            @include vcenter; } }
    .icon-white {
        fill: #fff;
        @include tr(fill 0.3s ease); }
    &::after {
        content: none;

        @include rmin(768) {
            content: "";
            position: absolute;
            width: 20px;
            height: 25px;
            display: block;
            right: -10px;
            top: 0;
            opacity: 0;
            transform: translateX(-15px);

            @include image("apple-image.png");

            transition: transform 0.3s ease 0.15s, opacity 0.3s ease; } }
    &.is-active {
        @include rmin(768) {
            .icon-white {
                fill: $menu-link-color; }

            .menu__link {
                color: $menu-link-color;

                &::after {
                    transform: translateX(0); } }

            &::after {
                opacity: 1;
                transform: translateX(0); } }
        @include r(767) {
            background-color: #F3C01C; } }

    @include r(767) {
        flex: 1 1 25%;
        max-width: 25%;
        text-align: center;
        padding: 5px 0;
        .icon {
            display: block;
            margin: 4px auto; } }

    @include rmin(768) {
        display: flex;
        flex-flow: row nowrap;
        flex: 1 1 100%;
        max-width: 100%;
        margin-bottom: 10px;
        position: relative; } }

html:not(.tablet) {
    .menu__item {
        @include rmin(1023) {
            &:hover {
                .icon-white {
                    fill: $menu-link-color; }

                .menu__link {
                    color: $menu-link-color;

                    &::after {
                        transform: translateX(0); } }

                &::after {
                    opacity: 1;
                    transform: translateX(0); } } } } }

.menu__link {
    font-family: $fontR;
    font-weight: 400;
    color: white;
    font-size: 13px;
    line-height: 1.2;
    width: calc(100% - 40px);
    padding-bottom: 13px;
    padding-top: 5px;
    position: relative;
    overflow: hidden;
    @include r(767) {
        display: block;
        text-align: center;
        width: 100%;
        margin-top: -4px;
        padding-bottom: 0;
        position: static;
        &::after {
            content: none !important; }
        &::before {
            content: '';
            background: none;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 1; } }
    @include rmin(768) {
        font-size: 20px; }
    @include tr(color 0.3s ease);

    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: $menu-link-color;

        @include tr(transform 0.3s ease);

        transform: translateX(-101%); } }

.bottom-name {
    margin-top: auto;
    text-align: center;
    padding-right: 30px; }

.icon-wrap {
    &.icon-menu-1 {
        & > svg {
            width: 26px;
            height: 25px; } }

    &.icon-menu-2 {
        & > svg {
            width: 28px;
            height: 26px; } }

    &.icon-menu-3,
    &.icon-menu-4 {
        & > svg {
            width: 39px;
            height: 33px; } }

    &.icon-menu-5 {
        & > svg {
            width: 38px;
            height: 25px; } }

    &.icon-menu-6 {
        & > svg {
            width: 36px;
            height: 34px; } } }

/*all screen */
.screen {
    display: block;
    width: 100%;
    min-height: 100vh;
    text-align: center;
    position: relative;
    overflow: hidden;

    .inner {
        position: relative;
        height: 100%; }

    @include rmin(1024) {
        padding-left: 260px;
        height: 100vh; } }

/*first screen */
.first-screen {
    padding-top: 75px;

    @include rmin(1024) {
        padding-top: 9vh; }

    @include rmin(768) {
        padding-top: 130px; }

    .next-page {
        width: 150px;
        height: 100px;
        top: -21px;
        right: 0;
        .top-icon {
            img {
                width: 122px; } }

        .bottom-icon {
            img {
                transform: rotate(90deg); } }

        @include rIn(1024, 1279) {
            top: -12px;
            .top-icon img {
                width: 100%; }
            .bottom-icon {
                text-align: right;
                padding-right: 20px;
                margin-top: 10px; } }
        @include rmin(1280) {
            top: 5%; }
        @include rmin(1400) {
            top: 11%; } }

    .scroll-icon {
        position: relative;
        z-index: 10;
        top: auto;
        right: auto; } }

.logo-wrapper {
    display: block;
    text-align: center;
    margin-bottom: 45px;

    @include rmin(768) {
        margin-bottom: 50px; }

    @include rmin(1024) {
        margin-bottom: 5vh; }

    img {
        width: 180px;
        display: inline-block;

        @include rmin(768) {
            width: 300px;
            height: 120px; } } }

.first-title {
    display: block;
    text-align: center;
    line-height: 1;
    font-size: 62px;
    @include r(1023) {
        @include css-lock(32, 62, 320, 1023);
        .to-mob {
            display: block;
            margin: auto; }
        .to-desck {
            display: none; } }

    @include rmin(1024) {
        @include css-lock(30, 62, 767, 1920);
        .to-mob {
            display: none; }
        .to-desck {
            margin: auto;
            display: block; } }

    color: white;
    margin-bottom: 30px;
    @include rmin(1300) {
        br {
            display: none; } }
    @include r(767) {
        padding-left: 30px;
        padding-right: 30px; }

    @include rmin(1366) {
        margin-bottom: 7vh; }

    .inner {
        max-width: 10em;
        display: inline-block; } }

.letter {
    display: inline-block;
    min-width: 0.3em; }

.floor {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 5; }

.lapa-nieb {
    position: absolute;
    left: 20%;
    bottom: 16px;
    z-index: 10;

    &__ear {
        width: 9%;
        position: absolute;
        top: -2%;
        right: 25%;
        height: auto !important;
        transform-origin: 50% bottom;
        transform: rotate(10deg);
        animation: niebieski1 2s linear infinite; }

    &__ear-static {
        width: 9%;
        position: absolute;
        top: -2%;
        left: 20%;
        height: auto !important;
        transform: rotate(-45deg);
        z-index: -1; }

    &__arm {
        position: absolute;
        left: 2px;
        width: 16%;
        height: auto !important;
        top: 5%;
        z-index: -1;
        transform-origin: 70% bottom;
        transform: rotate(10deg);
        animation: niebieski1 2.5s linear infinite .2s; }

    @include r(767) {
        left: -126px;
        bottom: -65px;
        transform: rotate(9deg); }

    @include rmin(768) {
        left: calc(50% - 322px); }

    @include rmin(1023) {
        left: 37%; }

    @include rmin(1366) {
        left: 28%; }

    img {
        @include css-lock(200, 400, 767, 1500, height, px);
        @include r(600) {
            height: 330px; }

        @include rIn(768, 800) {
            height: 350px; }
        @include rh(770) {
            max-height: 315px; } }
    @include rh(730) {
        img {
            height: 275px; } }
    @include rhIn(731, 750) {
        img {
            height: 330px; } } }

@keyframes niebieski1 {
    0% {
        transform: rotate(10deg); }
    50% {
        transform: rotate(-10deg); }
    100% {
        transform: rotate(10deg); } }

.lapa-roma {
    position: absolute;
    bottom: 16px;
    z-index: 10;

    &__ear {
        position: absolute;
        top: -3%;
        right: 32%;
        height: auto !important;
        width: 10%;
        transform-origin: 50% bottom;
        animation: niebieski1 2s linear infinite; }

    &__arm {
        position: absolute;
        top: 25%;
        left: 6px;
        width: 18%;
        height: auto !important;
        transform-origin: 90% bottom;
        animation: niebieski1 3s linear infinite .2s; }

    @include r(767) {
        right: -164px;
        bottom: -65px;
        transform: rotate(-9deg); }

    @include rmin(768) {
        right: calc(50% - 374px); }

    @include rmin(1023) {
        right: 10%; }

    img {
        @include css-lock(200, 420, 767, 1500, height, px);
        @include r(600) {
            height: 345px; }

        @include rIn(768, 800) {
            height: 350px; }
        @include rh(770) {
            max-height: 330px; } }

    @include rh(730) {
        img {
            height: 300px; } }
    @include rhIn(731, 750) {
        img {
            height: 340px; } } }

.main-line {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 6;
    bottom: 45%;

    @include rmin(1024) {
        bottom: 20%; }
    @include r(1023) {
        display: none; } }

#main-line {
    width: 100vw; }

////second screen
.second-screen {
    padding-top: 9vh;
    @media (max-height: 1070px) and (min-width: 1023px) {
        padding-top: 5vh; }
    @include rIn(768, 1023) {
        padding-top: 120px;
        overflow: hidden; }
    @include r(767) {
        padding-top: 55px;
        overflow: hidden; }

    @include rmin(1023) {
        padding-left: 260px; }

    .subtitle {
        font-size: 34px;
        display: block;
        text-align: center;
        margin-top: -15px;
        @include r(1023) {
            margin-top: 0;
            margin-bottom: 40px; } }
    .scroll-icon {
        z-index: 3; }

    .lapa-nieb2 {
        position: absolute;
        bottom: -125px;
        right: 0%;
        width: 470px;
        opacity: 0;
        text-align: left;
        display: flex;
        align-items: flex-start;

        &__c {
            margin-left: -40px;
            @include r(767) {
                margin-left: -20px; } }
        &__c,
        &__i2 {
            height: 50px;
            animation: r2 1.5s linear infinite;
            @include r(767) {
                height: 20px;
                animation: r2 3s linear infinite; } }

        &__i1 {
            height: 40px;
            margin: 0 10px;
            transform: translateY(20px);
            animation: r2 1.5s linear infinite .7s;
            @include r(767) {
                height: 20px;
                margin: 0 5px;
                animation: r2 3s linear infinite 1.5s; } }

        @include rIn(1024, 1279) {
            bottom: -185px;
            right: -10%;
            width: 423px; }
        @include rIn(768, 1023) {
            bottom: -245px;
            right: -108px;
            width: 500px;
            opacity: 1;
            display: none; }
        @include r(767) {
            bottom: auto;
            right: auto;
            width: 200px;
            opacity: 1;
            top: 190px;
            left: -58px;
            transform: rotate(40deg); }
        &__main {
            width: 66%;
            @include r(1280) {
                width: 55%; } } }

    .next-bottom-page {
        position: absolute;
        bottom: 6%;
        right: 2%;
        display: flex;
        align-items: center;

        .bottom-icon {
            width: 50px;
            transform: rotate(180deg);
            margin-left: 11px; }

        @include r(1023) {
            bottom: -40px;
            .bottom-icon {
                transform: rotate(130deg); } } }

    .prev-page {
        bottom: 43%;
        left: 41%;
        @include rIn(1024, 1279) {
            bottom: 35%;
            left: 29%; }
        @include r(1023) {
            left: 30px;
            bottom: 0; } }

    .bottom-icon {
        width: 50px;
        transform: rotate(-90deg); }

    .rocket {
        left: 10px;
        bottom: 13%;
        @include r(1023) {
            left: calc(50% - 60px);
            bottom: -35px;
            display: none; } } }

.second-top-inner {
    display: block;
    width: 100%;
    text-align: center; }

.title-second {
    width: 310px;
    height: 85px;
    display: inline-block;
    @include r(767) {
        width: 225px;
        height: 60px; } }

.third-text-wrapper {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    @include rmin(1024) {
        justify-content: center; }
    @include r(767) {
        margin-top: 0;
        margin: 0 auto;
        max-width: 325px;
        padding: 40px 25px 0px;
        background-image: url('../img/path-valkea.svg');
        background-repeat: no-repeat;
        background-size: 325px auto;
        background-position-y: 33px; }
    @include r(520) {
        max-width: 320px;
        background-size: 309px auto; } }

.second-top-left {
    position: relative;
    margin-left: 2%;
    margin-top: -52px;
    width: 510px;
    height: 378px;
    text-align: left;
    @include rIn(1024, 1280) {
        border: 2px dashed #EF436E;
        padding: 30px 30px 35px 45px;
        margin-top: 2%;
        max-width: 42%; }
    @include rIn(1281, 1500) {
        margin-top: 2%; }
    @include rIn(1500, 1680) {
        margin-left: -3%; }
    @include rmin(1281) {
        padding: 23px 113px 16px 30px;
        max-width: 46%;
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            background-image: url(../img/second-screen-text-bg.png);
            width: 100%;
            min-width: 520px;
            height: 100%;
            background-size: cover;
            background-repeat: no-repeat; } }
    @include rmin(1366) {
        padding: 30px 113px 35px 30px;
        max-width: 476px; }
    @include rmin(1800) {
        max-width: 520px;
        margin-right: 1%;
        &::after {
            width: 585px;
            min-width: 585px;
            height: 426px; } }
    @include rIn(768, 1024) {
        width: 100%;
        height: auto;
        flex: 1 1 100%;
        max-width: 100%;
        display: flex;
        flex-flow: row nowrap;
        border: none;
        margin-top: 0;
        padding: 0 30px 20px;
        .custom-text {
            @include css-lock(17, 21, 320, 1023); }
        .custom-list {
            @include css-lock(20, 29, 320, 1023);
            padding-left: 20px; }
        .tablet-left,
        .custom-list {
            flex: 1 1 50%;
            max-width: 50%; } }
    @include r(767) {
        margin-top: 0; }

    .custom-title {
        @include r(1023) {
            font-size: 40px; } } }

.second-screen {
    .scroll-icon {
        @include r(1366) {
            display: none; } }
    .custom-text {
        @include rmin(1580) {
            @include css-lock(18, 24, 1580, 1920); }
        @include rmin(1281) {
            max-width: 430px; } }
    @include r(1023) {
        min-height: 1px; } }

.second-middle-right {
    position: relative;
    flex: 1 1 50%;
    max-width: 50%;
    margin-left: 10%;
    text-align: left;
    padding-top: 70px;
    padding-right: 100px;
    @include rmin(1281) {
        margin-left: 3%; }
    @include rIn(1024, 1279) {
        padding-right: 30px;
        max-width: 55%;
        margin-left: 55px;
        margin-right: auto; }
    @include rIn(1280, 1366) {
        max-width: 44%;
        margin-left: 5%;
        padding-top: 30px; }
    @include rIn(768, 1023) {
        max-width: 100%;
        margin-left: 40px;
        margin-bottom: 100px;
        padding-top: 0px; }
    @include r(767) {
        width: 100%;
        margin: 0 auto;
        padding: 0;
        max-width: 270px; }
    @include r(1023) {
        margin-bottom: 0; }
    .custom-title {
        margin-bottom: 25px;
        font-size: 33px;
        @include r(1023) {
            font-size: 40px; }
        @include r(767) {
            text-align: center; } }
    .custom-text {
        @include r(1023) {
            font-size: 19px; }
        @include r(767) {
            text-align: center;
            .big-text {
                display: block;
                text-decoration: underline; } } } }

.second-line {
    position: absolute;
    width: 100%;
    right: 0;
    bottom: 19%;
    .second-line-mob {
        display: none; }
    @include rmin(1025) {
        width: 65%;
        right: 41%;
        bottom: 0; }
    & > svg {
        transform: translateY(50%); }
    @include rh(750) {
        bottom: -100px;
        .next-bottom-page {
            bottom: calc(6% + 100px);
            right: 14%; } }
    @include rIn(768, 1023) {
        position: relative;
        margin-bottom: 65px;
        .second-line-desctop {
            display: none; }
        .second-line-mob {
            display: block; } }
    @include r(1023) {
        display: none; } }

.custom-title {
    font-size: 44px;
    font-weight: 900;
    line-height: 1.1; }

.custom-text {
    font-size: 17px;
    line-height: 1.5;
    margin-bottom: 1em; }

.custom-list {
    font-size: 24px;
    font-family: $fontV;
    color: #87BCD9;
    line-height: 1.3; }

.big-text {
    font-size: 125%;

    &.red {
        color: #EF436E; }

    &.blue {
        color: #acdfea; } }

.mt20 {
    margin-top: 20px; }

//////third screen
.third-screen {
    .prev-page {
        left: 51.5%;
        top: 10px;

        .left-icon {
            display: inline-block;
            vertical-align: middle; }

        .right-icon {
            display: inline-block;
            vertical-align: middle;

            img {
                width: 81px;
                margin-left: 14px; } }
        @include r(1023) {
            display: none; } }
    .next-page {
        bottom: 14px;
        left: 51%;
        display: inline-block;

        .left-text {
            padding-top: 9px;
            font-size: 22px;
            font-weight: 700;
            display: inline-block;
            vertical-align: middle;
            color: white;
            padding-bottom: 10px;
            margin-left: 15px; }

        .bottom-icon {
            display: inline-block;
            vertical-align: middle; }

        img {
            transform: rotate(155deg); }
        @include r(1023) {
            display: none; } }
    @include rIn(768, 1023) {
        height: 1050px; }
    @include r(767) {
        height: 880px; }
    @include r(600) {
        height: 850px; } }

.bg-third {
    padding-left: 15px;
    position: relative;
    height: 100%;
    .scroll-icon {
        @include rIn(1024, 1366) {
            display: none; } } }

.third-line {
    position: absolute;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
    @include rIn(1024, 1279) {
        max-width: 1000px; }
    @include r(1023) {
        display: none; } }

.third-line-mob {
    pointer-events: none;
    display: none;
    @include r(1023) {
        position: absolute;
        display: block;
        height: 100%;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        z-index: 0; } }

.point {
    display: block;
    position: absolute;
    @include r(1023) {
        display: none; }
    &.digital-point {
        @include rIn(1024, 1279) {
            width: 14%;
            top: 1%;
            left: 34%; }
        @include rmin(1280) {
            width: 12%;
            top: 0%;
            left: 39%; }
        @include rmin(1600) {
            max-width: 155px;
            top: 2%;
            left: 38%; } }

    &.event-point {
        @include rIn(1024, 1279) {
            width: 27%;
            top: 11%;
            left: -1%; }
        @include rmin(1280) {
            width: 25%;
            left: 7%;
            top: 10%;
            max-width: 340px; }
        @include rmin(1600) {
            left: 8%; }
        @include rmin(1800) {
            left: 15%; } }

    &.pr-point {
        @include rIn(1024, 1279) {
            width: 23%;
            top: 16%;
            right: 18%; }
        @include rmin(1280) {
            width: 22%;
            right: 20%;
            top: 13%;
            max-width: 340px; }
        @include rmin(1600) {
            right: 8%; } }
    &.csr-point {
        @include rIn(1024, 1279) {
            width: 14%;
            top: 43%;
            right: 10%; }
        @include rmin(1280) {
            width: 12%;
            top: 40%;
            right: 16%;
            max-width: 170px; }
        @include rmin(1600) {
            right: 14%; } }

    &.uslugi-point {
        @include rmin(1024) {
            width: 27%;
            top: 44%;
            left: -1%;
            max-width: 270px; }
        @include rmin(1281) {
            width: 25%; }
        @include rmin(1600) {
            left: 2%; } }
    &.strategia-point {
        @include rmin(1024) {
            width: 26%;
            bottom: 23%;
            left: 30%; }
        @include rmin(1281) {
            width: 24%;
            bottom: 26%;
            max-width: 250px; } }

    &.zintegrowany-point {
        @include rmin(1024) {
            width: 26%;
            left: -11%;
            bottom: 12%; }
        @include rmin(1366) {
            width: 23%;
            left: 0%; }
        @include rmin(1600) {
            max-width: 300px; }
        @include rmin(1800) {
            left: 10%; }
        @media (min-width: 1500px) and (max-width: 1540px) and (max-height: 725px) {
            bottom: 6%;
            left: 7%;
            width: 21%; }
        @media (min-width: 1050px) and (max-width: 1300px) and (max-height: 725px) {
            bottom: 10%;
            left: 2%;
            width: 21%; } }
    &.custom-point {
        @include rmin(1024) {
            width: 31%;
            right: 1%;
            bottom: 18%; }
        @include rmin(1600) {
            right: 20%;
            max-width: 320px; }
        @include rh(750) {
            bottom: 10%;
            width: 26%; } }
    &.social-point {
        @include rmin(1024) {
            width: 23%;
            bottom: 3%;
            left: 20%; }
        @include rmin(1366) {
            width: 22%;
            bottom: 3%;
            left: 31%;
            max-width: 240px; } } }

.mob-point {
    display: none;
    @include r(1023) {
        display: inline-block;
        max-width: 49%;
        transform: scale(1.3); } }

.mob-point {
    &.event-point {
        @include css-lock(210, 405, 320, 768, min-width);
        margin-left: -20px; }
    &.csr-point {
        max-width: 30%;
        transform: scale(1.6) rotate(30deg);
        @include rIn(768, 1023) {
            margin-left: 10%; }
        @include r(767) {
            margin-left: 5%; } }
    &.digital-point {
        max-width: 30%;
        transform: scale(1.6) rotate(-30deg);
        @include rIn(768, 1023) {
            margin-right: 15%;
            margin-top: -40px; }
        @include r(767) {
            margin-top: -20px;
            margin-right: 5%; } }
    &.pr-point {
        width: 292px;
        margin-right: 10%;
        @include rIn(768, 1023) {
            margin-top: -40px; }
        @include r(767) {
            margin-top: -20px; } }
    &.strategia-point {
        width: 31%;
        max-width: 203px;
        transform: rotate(-12deg) scale(1.6);
        top: -45px;
        position: relative;
        @include css-lock(0, 20, 600, 768, left); }
    &.uslugi-point {
        transform: rotate(-12deg) scale(1.5);
        position: relative;
        right: 0;
        top: 30px;
        @include rmin(768) {
            top: 0;
            margin-left: 20%; } }
    &.custom-point {
        transform: scale(1.5) rotate(-29deg);
        margin-left: -5%;
        margin-right: 12%;
        @include r(767) {
            max-width: 60%;
            margin-left: auto;
            margin-right: auto; } }
    &.zintegrowany-point {
        position: relative;
        right: -10%;
        @include r(767) {
            right: auto;
            top: 24px; } }
    &.social-point {
        position: relative;
        top: -24px;
        right: 21%;
        @include r(767) {
            top: -74px;
            right: 10%; } } }

.third-center-text {
    position: absolute;
    top: 44%;
    left: 0;
    @include css-lock(40, 60, 1024, 1980);
    font-weight: 700;
    margin-left: auto;
    display: block;
    width: 100%;
    .text-to-bottom {
        display: none; }
    .text-to-top {
        font-size: 75px;
        font-family: $fontV;
        text-transform: uppercase; }
    @include r(1023) {
        display: block;
        position: relative;
        padding-top: 150px;
        top: 0 !important;
        .text-to-top {
            display: none; }
        .text-to-bottom {
            display: inline-block;
            width: 300px;
            max-width: 70%; } }
    @include rIn(768, 1023) {
        padding-top: 150px; }
    @include r(767) {
        padding: 79px 0 30px; } }

#rocket {
    left: 0;
    bottom: 20%;
    transform: rotate(-20deg);
    animation: r1 2s linear infinite;
    z-index: 10;
    @include rmin(1200) {
        bottom: 24%; }
    @include r(1023) {
        bottom: 43%; }
    @include r(1023) {
        display: none; } }

@keyframes r1 {
    0% {
        transform: rotate(-20deg) translateY(0); }
    50% {
        transform: rotate(-20deg) translateY(30px); }
    100% {
        transform: rotate(-20deg) translateY(0); } }

#rocket3 {
    left: 40%;
    top: 20%;
    transform: rotate(134deg);
    animation: r3 2s linear infinite;
    @include rmin(1280) {
        left: 46%;
        top: 18%; }
    @include r(1023) {
        display: none; } }

@keyframes r3 {
    0% {
        transform: rotate(134deg) translateY(0); }
    50% {
        transform: rotate(134deg) translateY(-20px); }
    100% {
        transform: rotate(134deg) translateY(0); } }

#rocket2 {
    left: 105px;
    bottom: 30%;
    animation: r2 2s linear infinite;
    @include r(1280) {
        left: 60px; } }

@keyframes r2 {
    0% {
        transform: translateY(0); }
    50% {
        transform: translateY(-20px); }
    100% {
        transform: translateY(0); } }

#rocket6 {
    animation: r2 2s linear infinite; }

#rocket4 {
    animation: r4 2s linear infinite; }

#rocket-free-portfolio {
    animation: r4 2s linear infinite; }

@keyframes r4 {
    0% {
        transform: rotate(40deg) translateY(0); }
    50% {
        transform: rotate(40deg) translateY(-20px); }
    100% {
        transform: rotate(40deg) translateY(0); } }

#rocket5 {
    animation: r5 2s linear infinite;
    z-index: 10; }

@keyframes r5 {
    0% {
        transform: rotate(115deg) translateY(0); }
    50% {
        transform: rotate(115deg) translateY(-20px); }
    100% {
        transform: rotate(115deg) translateY(0); } }

#rocket8 {
    animation: r8 2s linear infinite;
    z-index: 10; }

@keyframes r8 {
    0% {
        transform: rotate(-106deg) translateY(0); }
    50% {
        transform: rotate(-106deg) translateY(-20px); }
    100% {
        transform: rotate(-106deg) translateY(0); } }

#rocket7 {
    animation: r7 2s linear infinite; }

@keyframes r7 {
    0% {
        bottom: 14%; }
    50% {
        bottom: 16%; }
    100% {
        bottom: 14%; } }

.nine-screen .section-text {
    z-index: 10; }

.hidden-way {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0; }

/*four screen */

@keyframes rotateLR {
    0% {
        transform: rotate(-10deg); }
    50% {
        transform: rotate(10deg); }
    100% {
        transform: rotate(-10deg); } }

.four-screen {
    .section-title {
        width: 380px;
        height: auto;
        position: absolute;
        top: -174px;
        left: 69px;
        @include rmin(1280) {
            width: 340px;
            height: auto;
            top: -152px; }
        @include r(1279) {
            width: 280px;
            top: -103px;
            left: auto;
            right: 0; }
        @include r(767) {
            max-width: 90%;
            margin: auto;
            display: block;
            position: relative;
            height: 122px;
            overflow: hidden;
            top: 0;
            margin-bottom: 10px;
            margin-top: 40px;
            img {
                position: absolute;
                bottom: 0;
                left: 0; } } }
    .prev-page {
        top: 14px;
        left: 48%;
        @include r(1024) {
            left: 1%;
            top: 17%;
            display: flex;
            flex-flow: row wrap;
            .bottom-icon {
                display: block;
                flex: 1 1 100%;
                order: 2;
                text-align: left;
                img {
                    transform: rotate(-93deg); } } }
        @include r(1023) {
            display: none; } }
    .next-page {
        left: 55%;
        bottom: 15px;
        @include r(1700) {
            left: 54%; }
        .bottom-icon {
            transform: rotate(180deg); }
        @include r(1024) {
            left: auto;
            right: 40px;
            top: 26%;
            bottom: auto;
            display: flex;
            flex-flow: row wrap;
            .bottom-icon {
                flex: 1 1 100%;
                order: 2;
                text-align: left;
                img {
                    transform: rotate(-90deg); } }
            .left-text {
                text-align: right;
                flex: 1 1 100%; } }
        @include r(1023) {
            display: none; } }
    .lapa-nieb4 {
        pointer-events: none;
        position: absolute;

        .to-top-img {
            width: 80%;
            transform: rotate(-10deg);
            transform-origin: 50% bottom;
            animation: rotateLR 2s linear infinite; }

        @include rIn(768, 1200) {
            bottom: 70px;
            pointer-events: none;
            width: 100%; }
        @include rmin(1200) {
            width: 90%;
            bottom: 65px;
            left: 5%; }
        @include rmin(1500) {
            width: 84%;
            bottom: 0;
            left: 5%; }
        @include rmin(1700) {
            width: 72%;
            bottom: 0;
            left: 9%; }
        @media (min-width: 1024px) and (max-height: 750px) {
            max-width: 120vh;
            left: calc(50% - 60vh); }
        img {
            width: 100%; }
        .to-bottom-img {
            display: none; }
        @include r(767) {
            position: relative;
            width: 100%;
            left: 0;
            .to-top-img {
                display: none; }
            .to-bottom-img {
                display: block; } } }

    .text {
        width: 320px;
        display: inline-block;
        position: absolute;
        bottom: 0;
        padding-bottom: 45px;
        color: black;
        font-size: 21px;
        .text-inner {
            height: 310px;
            padding: 25px 23px 35px;
            @include r(767) {
                height: auto;
                padding: 10px 9px 24px;
                font-size: 16px;
                .red {
                    font-size: 22px; } } }
        @include rIn(1280, 1550) {
            font-size: 17px;
            padding: 10px;
            .text-inner {
                height: 250px; } }
        @include rIn(768, 1279) {
            padding-bottom: 0;
            width: 50%;
            height: 230px;
            font-size: 19px;
            .text-inner {
                vertical-align: text-top; } }
        @include r(767) {
            position: relative;
            left: auto;
            right: auto;
            top: auto;
            margin-top: -53px; } }

    .text-1 {
        left: 10%;
        @include rIn(1280, 1550) {
            left: 0; }
        @include rIn(768, 1279) {
            left: 0; }
        .text-inner {
            display: table-cell;
            vertical-align: middle;
            background-color: #F2BF25; }
        @include r(767) {
            transform: rotate(4deg); } }
    .text-2 {
        right: 10%;
        @include rIn(1280, 1550) {
            right: 0; }
        @include rIn(768, 1279) {
            right: 0; }
        .text-inner {
            background-color: #A6DCE3; }
        @include r(767) {
            transform: rotate(-4deg); } }
    .red {
        color: #EF436E;
        font-family: $fontV;
        font-size: 26px; }

    .underline {
        text-decoration: underline; }

    .rocket {
        top: 150px;
        left: 45%;
        transform: rotate(40deg);
        @include r(1024) {
            left: 27%;
            transform: rotate(1deg); }
        @include r(1023) {
            display: none; } }
    .scroll-icon {
        display: none;
        @include rmin(1280) {
            display: block; }
        @include rIn(1023, 1279) {
            top: 20%; } }
    .left-right-line {
        display: none;
        @include r(1024) {
            display: block;
            position: absolute;
            right: 0%;
            width: calc(100% + 260px);
            top: 200px;
            pointer-events: none; }
        @include r(1023) {
            display: none; } }
    &.screen {
        @include r(1023) {
            height: 680px;
            min-height: auto; }
        @include r(767) {
            height: auto; } } }

#four-line {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    display: none;
    @include rmin(1025) {
        display: block; } }

/*five screen */
.five-screen {
    display: block;
    &.screen {
        @include rIn(768, 1023) {
            // height: 870px
            height: 740px;
            min-height: auto; }
        @include r(767) {
            height: auto;
            min-height: auto; } }
    .section-title {
        width: 450px;
        position: absolute;
        top: -197px;
        left: 60px;
        @include r(1600) {
            width: 355px;
            top: -174px;
            left: 60px; }
        @include rIn(768, 1279) {
            width: 280px;
            top: -99px;
            left: auto;
            right: -25%; }
        @include r(767) {
            max-width: 100%;
            margin: auto;
            display: block;
            position: relative;
            height: 135px;
            overflow: hidden;
            top: 0;
            left: 0;
            margin-bottom: 10px;
            margin-top: 40px;
            img {
                position: absolute;
                bottom: 0;
                left: 0; } } }
    .subtitle {
        text-transform: uppercase;
        font-size: 28px;
        padding-top: 22px;
        padding-bottom: 10px; }
    .subtext {
        font-size: 20px;
        line-height: 1.8; }
    .lapa-nieb5 {
        width: 85%;
        position: absolute;
        bottom: -164px;
        left: 8%;
        pointer-events: none;
        img {
            width: 100%; }
        &__main-img {
            width: 30% !important;
            z-index: 2;
            position: relative; }
        &__img-1,
        &__img-2,
        &__img-3 {
            width: 30% !important;
            position: absolute;
            top: 0;
            transform: rotate(-10deg); }

        &__img-3 {
            right: 12%;
            top: 21%;
            animation: rotateLR 3s linear infinite;
            transform-origin: left 100%;
            z-index: 3; }

        &__img-2 {
            right: 21%;
            top: -26%;
            animation: rotateLR 3s linear infinite .4s;
            transform-origin: 20% bottom;
            z-index: 3; }

        &__img-1 {
            left: 15%;
            top: -13%;
            animation: rotateLR 2.5s linear infinite .2s;
            transform-origin: 80% bottom;
            z-index: 1; }

        @include rmin(1700) {
            width: 73%;
            left: 16%; }
        @include rmin(1900) {
            width: 78%;
            left: 11%; }
        @include r(1600) {
            bottom: -66px; }
        @include rIn(1024, 1279) {
            bottom: -23px; }
        @include rh(750) {
            bottom: -115px;
            width: 130vh;
            left: calc(50% - 65vh); }
        @include r(1023) {
            width: 100%;
            bottom: -3px; }
        @include r(767) {
            display: none; } }
    .text {
        position: absolute;
        bottom: 20px;
        left: 30px;
        font-size: 30px;
        max-width: 235px;
        @include r(767) {
            display: none; } }
    .red {
        color: #EF436E; }
    .rocket {
        top: 24%;
        right: 21%;
        transform: rotate(115deg);
        @include r(1024) {
            top: auto;
            bottom: 46%;
            right: 52%;
            transform: rotate(30deg); }
        @include r(1023) {
            display: none; } }
    .prev-page {
        right: 22%;
        top: 20px;
        @include rIn(1300, 1699) {
            right: 23%; }
        @include rIn(1024, 1279) {
            right: auto;
            left: 30px;
            top: 48%;
            .bottom-icon img {
                transform: rotate(-80deg); } }
        @include r(1023) {
            top: 55%;
            left: 30px;
            right: auto;
            .bottom-icon img {
                transform: rotate(-80deg); } }
        @include r(1023) {
            display: none; } }
    .scroll-icon {
        @include rIn(1280, 1500) {
            top: 35%; }
        @include r(1200) {
            display: none; } }
    .next-page {
        right: 10px;
        bottom: auto;
        @include css-lock(400, 570, 1280, 1920, top, px);
        .top-icon {
            text-align: right;
            margin-bottom: 10px;
            padding-right: 10px;
            img {
                transform: rotate(90deg); } }
        .bottom-icon img {
            width: 120px; }
        @include rhIn(740, 780) {
            bottom: 13%; }
        @include rh(730);
        // bottom: 16%
        @include r(1280);
        // top: 530px
        @include r(1023);
        // top: 5%
        @include r(1023) {
            display: none; } }
    .mob-img-1 {
        display: block;
        width: 122%;
        position: relative;
        right: -10%;
        max-width: none;
        margin-top: 27px; }
    .mob-img-3 {
        display: block;
        width: 120%;
        max-width: none;
        margin-left: -10%;
        margin-top: -40px; } }

.secton-text-block {
    position: absolute;
    top: 0;
    left: 10%;
    padding-top: 240px;
    max-width: 550px;
    @include r(1024) {
        padding-top: 170px; }
    @include r(1600) {
        left: 7%;
        padding-top: 170px;
        max-width: 481px; }
    @include r(767) {
        position: relative;
        left: 0;
        right: 0;
        padding: 0 20px; } }

#five-line {
    position: absolute;
    height: 76%;
    // max-height: 600px
    @include css-lock(430, 600, 1280, 1920, max-height, px);
    right: 0px;
    pointer-events: none;
    @include r(1365);
    // right: -40%
    // right: -400px
    // top: -42px
    @include r(1024) {
        transform: matrix(-1, 0, 0, -1, 0, 0) rotate(-45deg);
        width: 200%;
        right: -380px;
        top: 29%; } }

.five-line-small {
    display: block;
    position: absolute;
    bottom: -10%;
    width: calc(100% + 260px);
    right: 0;
    @include rmin(1025) {
        display: none; }
    @include r(1023) {
        display: none; } }

/*six screen*/
.six-screen {
    &.screen {
        @include r(1023) {
            min-height: auto;
            height: auto; } }
    .six-line {
        position: absolute;
        bottom: 10%;
        left: 0;
        width: 100vw;
        @include r(1023) {
            display: none; } }
    .six-line-mob {
        display: none;
        @include r(1023) {
            display: block;
            position: absolute;
            height: calc(100vw + 50px);
            z-index: -1;
            bottom: 0;
            left: 0; }
        @include r(767) {
            display: none; } }
    .section-text-block {
        max-width: 980px;
        margin: auto;
        padding-top: 9vh;
        @include rh(750) {
            padding-top: 3vh; }
        @include r(1023) {
            margin-bottom: 60px; }
        @include r(767) {
            padding-left: 20px;
            padding-right: 20px; } }
    .section-title {
        display: block;
        text-align: center;
        @include rmin(1024) {
            font-size: 50px; }
        img {
            width: 40vh;
            max-width: 360px;
            display: inline-block; } }
    .subtitle {
        font-size: 48px;
        color: #EF436E;
        margin-bottom: 20px;
        @include rh(750) {
            font-size: 36px;
            margin-bottom: 10px; } }
    .subtext {
        // font-size: 24px
        text-align: center;
        line-height: 1.9;
        @include css-lock(18, 24, 1280, 1980);
        @include rh(750) {
            font-size: 17px; }
        @include r(1280) {
            line-height: 1.6; } }
    .rocket6 {
        width: 125px;
        position: absolute;
        bottom: 13%;
        left: 101px;
        @include r(1023) {
            display: none; } }
    .next-page {
        right: 12px;
        bottom: 12%;
        .top-icon img {
            width: 120px; }
        .bottom-icon img {
            transform: rotate(90deg); }
        @include r(1023) {
            bottom: 27px; }
        @include r(1023) {
            display: none; } }
    .text {
        // font-size: 40px
        @include css-lock(30, 40, 1030, 1300);
        position: absolute;
        text-align: center;
        width: 102%;
        left: -1%;
        bottom: 0;
        z-index: 10;
        padding: 20px;
        box-shadow: inset -15px -43px 80px black;
        @include r(1023) {
            display: none; } } }

.section-img-team {
    width: 100%;
    height: 516px;
    @include css-lock(325, 480, 1024, 1980, height, px);
    position: absolute;
    bottom: 0;
    left: 0;
    @include r(1023) {
        display: none; } }

.team-member {
    position: absolute;
    &::after {
        content: '';
        position: absolute;
        display: block;
        width: 75%;
        padding-bottom: 100%;
        background-image: url(../img/stun.png);
        background-repeat: no-repeat;
        z-index: 2;
        transform: rotate(-33deg);
        opacity: 0;
        transition: opacity .3s ease; }
    .is-active {
        z-index: -1;
        margin-left: 9%;
        top: -1%; }

    &.memb-2 .is-active {
        margin-left: 1%;
        top: 1%; }

    &.memb-3 .is-active {
        margin-left: 5%;
        top: 5%; }

    &.memb-4 .is-active {
        margin-left: 5%;
        top: -4%; }

    &.memb-5 .is-active {
        margin-left: 1%;
        top: -2%; }

    &.memb-6 .is-active {
        margin-left: 10%;
        top: -3%; }

    &.memb-8 .is-active {
        margin-left: -5%;
        top: 0; }

    .is-active,
    .hover-block {
        position: absolute;
        opacity: 0;
        transition: opacity .3s ease; }
    img {
        position: absolute;
        top: 0%;
        left: 50%;
        transform: translateX(-50%);
        width: 200%;
        max-width: none;
        pointer-events: none; }
    img:not(.is-active),
    a:not(.hover-block) {
        z-index: 1;
        position: relative; }
    &:hover {
        z-index: 10;
        &::after,
        .is-active,
        .hover-block {
            opacity: 1; } } }

.hover-block {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #F2BF25;
    color: black;
    // width: 260px
    @include css-lock(180, 260, 1024, 1500, width, px);
    // height: 150px
    font-size: 28px;
    @include css-lock(22, 26, 1024, 1500);
    font-weight: 700;
    padding: 36px 15px 20px;
    @include css-lock(15, 36, 1024, 1800, padding-top, px);
    z-index: 3;
    @include rmin(1550) {
        height: 150px; }
    .red {
        color: #EF436E;
        font-size: 24px;
        @include css-lock(18, 23, 1024, 1500); } }

.team-z-1 {
    z-index: 1; }

.team-z-2 {
    z-index: 2; }

.team-z-3 {
    z-index: 3; }

.team-z-4 {
    z-index: 4; }

.memb-1 {
    top: 0;
    left: 0;
    width: 12.5%;
    img {
        width: 327%;
        left: 87%; }
    .hover-block {
        left: 67%;
        top: 48%;
        @include r(1600) {
            top: 43%; }
        @include r(1366) {
            top: 49%; } }
    // top: 55%
    // +css-lock(300, 360, 1024, 1800, top, px )

    &::after {
        top: 18%;
        left: -16%;
        z-index: 2;
        width: 64%; } }

.memb-2 {
    top: -2%;
    left: 12.5%;
    width: 12.5%;
    img {
        width: 233%; }
    .hover-block {
        left: 50%;
        // top: 50%
        top: 47%; }
    &::after {
        top: 19%;
        left: -22%;
        width: 65%; } }

.memb-3 {
    top: -2%;
    width: 12.5%;
    left: 25%;
    img {
        top: -16px;
        left: 34%;
        width: 257%; }
    img.is-active {
        top: -18px; }
    .hover-block {
        left: 30%;
        top: 47%; }
    &::after {
        top: 22%;
        left: -37%;
        width: 65%; } }

.memb-4 {
    top: 0;
    width: 10.5%;
    left: 37.5%;
    img {
        width: 377%;
        top: -76px;
        @include r(1280) {
            top: -36px; } }
    .hover-block {
        left: 39%;
        // top: 53%
        top: 45%; }
    &::after {
        top: 17%;
        left: -61%; } }

.memb-5 {
    top: 0;
    width: 10.5%;
    left: 47%;
    img {
        width: 279%;
        left: 28%; }
    .hover-block {
        left: 30%;
        top: 45%;
        @include r(1700) {
            top: 50%; } }
    &::after {
        top: 20%;
        left: -80%; } }

.memb-6 {
    top: 0;
    width: 10.5%;
    left: 57.5%;
    img {
        width: 405%;
        left: 47%;
        top: -34px; }
    .hover-block {
        left: 26%;
        top: 42%; }
    &::after {
        top: 15%;
        left: -66%; }
    @include r(1290) {
        .is-active {
            top: -4% !important; } }
    @include r(1100) {
        .is-active {
            top: -6% !important; } } }

.memb-7 {
    top: 0;
    width: 10.5%;
    left: 65.5%;
    .hover-block {
        left: 51%;
        top: 50%; }
    img {
        width: 304%;
        left: 51%;
        top: -6px; }
    &::after {
        top: 23%;
        left: -49%; } }

.memb-8 {
    top: 0;
    width: 12.5%;
    left: 76%;
    img {
        width: 250%;
        top: -60px;
        left: 33%; }
    .hover-block {
        left: 68%;
        top: 44%; }
    &::after {
        top: 18%;
        left: -28%;
        width: 65%; }
    @include r(1290) {
        .is-active {
            top: -2% !important; } }
    @include r(1100) {
        .is-active {
            top: -4% !important; } } }

.team-mobile {
    display: none;
    @include r(1023) {
        display: flex;
        width: 100%;
        flex-flow: row wrap;
        justify-content: flex-start;
        text-decoration: none; } }

.team-mobile__item {
    flex: 1 1 33.3333%;
    max-width: 33.3333%;
    position: relative;
    @include r(767) {
        flex: 1 1 50%;
        max-width: 50%;
        img {
            height: 100%;
            width: 100%;
            object-fit: cover; } }
    .member-photo {
        height: 100%;
        display: block;
        max-width: none;
        object-fit: cover; }
    .member-name {
        position: absolute;
        display: block;
        width: 180px;
        padding: 7px 0 18px;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        font-size: 23px;
        line-height: 1.22;
        font-weight: 600;
        background-image: url('../img/txt-card-yellow-box-ipad.png');
        background-repeat: no-repeat;
        background-size: 120%;
        background-position: 29% 50%;
        @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
            background-image: url('../img/txt-card-yellow-box-ipad@2x.png'); }
        color: #1D1D20;
        text-decoration: none;
        z-index: 2;
        @include r(767) {
            font-size: 14px;
            width: 120px;
            bottom: 8px;
            background-size: 100%;
            background-position: 29% 16%;
            pointer-events: none; } } }

/* seven line*/
.seven-screen {
    &.screen {
        @include r(1023) {
            height: auto;
            min-height: auto; }
        @include rIn(768, 1023) {
            background-image: url(../img/floor-min.jpg);
            background-repeat: no-repeat;
            background-position: bottom center;
            background-size: contain; }
        @include r(767) {
            .inner {
                display: none; } } }
    .inner {
        @include r(1023) {
            // padding-top: 150px
            // height: 950px
            padding-top: 110px;
            height: 550px; } }
    .section-title {
        max-width: 650px;
        color: #81C7C0;
        @include rmin(1024) {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 10;
            @include css-lock(33, 46, 1024, 1920); }
        @include rIn(1024, 1279) {
            top: 44%; }
        @include r(1023) {
            display: block;
            margin: auto;
            @include css-lock(45, 47, 768, 1023);
            position: relative; } }
    .next-page:not(.next-page2) {
        z-index: 6;
        bottom: 8%;
        right: 50%;
        @include r(1023) {
            bottom: 30px;
            right: 33%;
            display: none; }
        .left-text {
            font-family: $fontV;
            font-size: 22px;
            font-weight: 500; }
        .bottom-icon {
            margin-left: 10px;
            img {
                transform: rotate(180deg); } } }
    .next-page2 {
        z-index: 6;
        bottom: calc(50vh - (0.39 * 100vw) / 2.1);
        right: 30px;
        @include rIn(1024, 1279) {
            bottom: calc(50vh - (.39 * 100vw) / 2.5);
            right: 5px; }
        @include r(1023) {
            bottom: 200px;
            display: none; }

        .left-text {
            font-family: $fontV;
            font-size: 22px;
            font-weight: 500; }
        .bottom-icon {
            margin-left: 10px;
            img {
                transform: rotate(90deg); } } }
    .rocket {
        left: 15%;
        bottom: 14%;
        z-index: 10;
        width: 188px;
        transform: rotate(-10deg);
        @include r(1023) {
            left: -9px;
            bottom: 250px;
            z-index: 10;
            width: 164px;
            transform: rotate(66deg);
            display: none; }
        @include r(767) {
            display: none; } }
    .scroll-icon {
        @include r(1279) {
            display: none; } }
    .floor {
        @include r(1023) {
            bottom: auto;
            top: 550px;
            z-index: 0; }
        @include r(767) {
            display: none; } } }

.inner-portfolio-link {
    display: none;
    @include r(1023) {
        display: block;
        position: relative;
        height: 1050px;
        margin-top: -55px;
        padding-top: 150px;
        .prev-page {
            display: none;
            position: absolute;
            top: 35px;
            right: 38%; } }
    @include r(767) {
        margin-top: 0;
        padding-top: 50px;
        height: 1150px;
        .prev-page {
            display: none; } }
    @include r(330) {
        height: 1065px; } }

h2.inner-portfolio-title {
    display: block;
    margin: 0 auto 40px 10%;
    text-align: left;
    font-family: $fontV;
    color: #FFFFFF;
    font-size: 75px;
    @include r(767) {
        font-size: 52px;
        margin: 0 auto 40px;
        text-align: center;
        span {
            display: inline-block;
            padding: 10px 20px;
            border: 4px dashed #ef436e;
            border-radius: 80px; } } }

.portfolio-small-list {
    @include r(767) {
        margin-top: 200px; }
    ul {
        max-width: 550px;
        margin: auto;
        display: flex;
        flex-flow: row wrap; }
    li {
        flex: 1 1 33.3333%;
        max-width: 33.3333%;
        padding: 0 8px 30px;
        @include r(767) {
            flex: 1 1 50%;
            max-width: 50%; } }
    .portfolio-small-item-name {
        position: absolute;
        pointer-events: none; }
    .item-inner {
        width: 100%;
        height: 100%;
        position: relative; }
    .portfolio-small-item-name {
        position: absolute;
        display: block;
        width: 125px;
        padding: 14px 0 18px;
        text-align: center;
        font-size: 16px;
        line-height: 1.22;
        font-weight: 600;
        background-image: url(../img/txt-card-yellow-box-ipad.png);
        background-repeat: no-repeat;
        background-size: 120%;
        background-position: 29% 50%;
        color: #1d1d20;
        text-decoration: none;
        z-index: 2; }
    li:nth-child(1),
    li:nth-child(4),
    li:nth-child(5) {
        .portfolio-small-item-name {
            top: -10px;
            left: 15%;
            transform: rotate(-4deg); } }
    li:nth-child(2),
    li:nth-child(3),
    li:nth-child(6),
    li:nth-child(7) {
        .portfolio-small-item-name {
            top: -10px;
            right: 0;
            transform: rotate(6deg); } } }

.ipl-line {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    @include r(1023) {
        display: none; } }

.lapa-nieb-7_2 {
    position: absolute;
    bottom: -115px;
    width: 470px;
    right: -50px;
    &__ruka {
        position: absolute;
        left: 0;
        z-index: 1;
        width: 27%;
        transform: rotate(10deg);
        transform-origin: 100% bottom;
        animation: niebieski1 1.5s linear infinite; }
    &__main {
        width: 70%;
        position: relative;
        z-index: 2; }
    @include r(1023) {
        bottom: 8px;
        width: 357px; }
    @include r(767) {
        bottom: auto;
        top: 172px;
        right: -43px;
        width: 250px; } }

#seven-line {
    position: absolute;
    bottom: calc(50% - (100vw * 0.39) / 2);
    left: 0;
    width: 100%;
    z-index: 6;
    pointer-events: none;
    @include r(1279) {
        left: -12%;
        width: 124%; }
    @include r(1025) {
        bottom: 17%; }
    @include r(1023) {
        display: none; } }

.seven-line-mob {
    display: none;
    @include r(1023) {
        display: block;
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: -1; } }

.lapa-nieb7 {
    position: absolute;
    top: 55%;
    right: -7%;
    width: 300px;
    pointer-events: none;
    &__ogon {
        position: absolute;
        top: 54%;
        width: 25% !important;
        left: 27%;
        transform-origin: 90% 10%;
        animation: niebieski1 2s linear infinite; }
    img {
        width: 100%; }
    @include rIn(1024, 1279) {
        display: none; }
    @include rh(740) {
        width: 72%; }
    @include r(1023) {
        top: 167px;
        right: -6%;
        width: 351px; } }
// top: 370px
// right: 28%
// width: 450px


/*eight line*/
.eight-screen {
    .slide-next {
        z-index: 20; }
    @include r(1023) {
        display: none; } }

/*nine line*/
.nine-screen {
    &.screen {
        @include r(1023) {
            min-height: auto;
            height: 1050px; }
        @include r(767) {
            height: 710px; } }

    .mob-text {
        display: none;
        @include r(1023) {
            display: block;
            font-family: $fontV;
            color: #EF436E;
            font-size: 75px;
            margin: 110px auto 25px;
            text-transform: uppercase; }
        @include r(767) {
            margin-top: 60px;
            font-size: 52px; } }
    .section-text {
        max-width: 500px;
        @include rmin(1024) {
            position: absolute;
            top: 35%;
            left: 50%;
            transform: translate(-50%, -50%); }
        @include r(1023) {
            margin: 0 auto; }
        h2 {
            // font-size: 62px
            @include css-lock(40, 62, 1023, 1980);
            @include r(1023) {
                font-size: 53px;
                max-width: 500px;
                width: 100%;
                display: block;
                margin: 0 auto 30px;
                line-height: 1.1; }
            @include r(767) {
                font-size: 34px; } }

        .subtitle {
            // font-size: 27px
            @include css-lock(17, 27, 1023, 1980);
            margin-bottom: 10px;
            a {
                color: white;
                &:hover {
                    cursor: pointer;
                    text-decoration: underline; } }
            @include r(1023) {
                font-size: 22px;
                margin-bottom: 20px; }
            @include r(767) {
                font-size: 20px; } }
        .undertitle {
            font-size: 22px;
            @include css-lock(17, 22, 1023, 1980); }
        @media all and (max-height: 800px) {
            top: 34%; }
        @media (min-height: 1000px) and (max-height: 1060px) {
            top: 44%; }
        @include r(1289) {
            top: 43%; }
        @include rmin(1440) {
            top: 350px; } }
    .rocket {
        width: 218px;
        left: 30%;
        bottom: 58%;
        transform: rotate(-106deg);
        @include r(1279) {
            display: none; } }
    .last-line {
        width: 86%;
        bottom: 10%;
        left: 0;
        position: absolute;
        pointer-events: none;
        @include rmin(1024) {
            z-index: 6; }
        @include r(1279) {
            display: none; }
        @include rIn(1300, 1439) {
            bottom: 15%; }
        @include rmin(1440) {
            max-height: 77%; }
        @include r(1023) {
            display: none; }
        @media (min-width: 1281px) and (max-height: 810px) {
            max-height: 85%; }
        @media (min-width: 1024px) and (max-width: 1281px) and (max-height: 810px) {
            width: 91%;
            bottom: 16%; } } }

.last-line-mob {
    display: none;
    @include r(1023) {
        display: block;
        position: absolute;
        bottom: 27%; }
    @include r(1023) {
        display: none; } }

.section-free {
    position: relative;
    @include rmin(1024) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        background-image: url(../../img/bg-min.jpg);
        .inner {
            position: relative;
            height: 100%; } }
    @include r(1023) {
        overflow: hidden;
        .inner {
            display: flex;
            flex-flow: column wrap; }
        .prev-page {
            @include r(1024) {
                display: none; } } }

    @include rmin(1024) {
        padding-left: 260px;
        height: 100vh; }
    .prev-page {
        z-index: 10;
        top: 40px;
        left: 30px;
        text-align: left;
        .left-icon {
            transform: rotate(-90deg); }
        .right-icon {
            width: 120px;
            margin-left: 15px; } } }

.portfolio-element {
    top: 9%;
    z-index: 0;
    height: 70%;
    position: absolute;
    z-index: 0;
    pointer-events: none;
    user-select: none;
    @include r(1024) {
        display: none; } }

.close-section-free {
    z-index: 10;
    top: 20px;
    right: 40px;
    img {
        width: 62px;
        height: 62px; }
    @include r(767) {
        top: 20px;
        right: 20px;
        img {
            width: 50px;
            height: 50px; } } }

.team-member-name {
    position: absolute;
    top: -141px;
    left: calc(50% - 250px);
    padding-top: 248px;
    text-align: center;
    width: 609px;
    z-index: 5;
    background-image: url(../img/hands-card.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: 410px;
    .name {
        font-size: 51px;
        font-weight: 700;
        color: black; }
    @include r(1600) {
        transform: scale(.8); }
    @include r(1400) {
        transform: scale(.7); }
    @include rIn(768, 1024) {
        top: -44px;
        left: 37%;
        padding-top: 141px;
        width: 386px;
        height: 192px;
        transform: scale(1);
        .name {
            font-size: 27px; } }
    @include r(767) {
        top: -65px;
        left: 50%;
        padding-top: 111px;
        width: 300px;
        height: 158px;
        transform: scale(1) translateX(-50%);
        .name {
            font-size: 24px; } } }

.top-member-mob {
    display: none;
    @include r(1023) {
        display: flex;
        flex: 1 1 100%;
        width: 100%;
        flex-flow: row nowrap;
        position: relative;
        align-items: flex-start;
        justify-content: space-between;
        height: 80px;
        position: absolute;
        top: 50%;
        z-index: 10;
        .member-prev,
        .member-pseudo-prev,
        .member-next,
        .member-pseudo-next {
            display: block;
            width: 50px;
            flex: 1 1 50px;
            max-width: 100px;
            border: none;
            background-color: transparent;
            img {
                width: 50px; } }
        .member-prev img,
        .member-pseudo-prev img {
            transform: rotate(-90deg); }
        .member-next img,
        .member-pseudo-next img {
            transform: rotate(90deg); }
        .member-line {
            flex: 1 1 calc(100% - 100px);
            max-width: calc(100% - 100px); } }
    @include r(767) {
        position: absolute;
        top: 49vh;
        z-index: 25;
        .member-prev,
        .member-pseudo-prev, {
            text-align: left;
            img {
                display: inline-block; } }
        .member-next,
        .member-pseudo-next {
            text-align: right;
            img {
                display: inline-block; } }
        .member-prev,
        .member-pseudo-prev,
        .member-next,
        .member-pseudo-next {
            img {
                width: 40px; } } } }

.team-member-img-full {
    bottom: -20px;
    z-index: 6;
    max-height: 80%;
    &.left {
        left: 0px; }
    &.right {
        right: 15%; }
    @include rmin(1025) {
        position: absolute; }
    @include r(1280) {
        img {
            position: relative;
            z-index: 1; } }
    @include r(1024) {
        width: 250px;
        margin: auto;
        display: block;
        img {
            width: 100%; } } }

.team-member-img-small {
    top: 160px;
    width: 650px;
    height: 450px;
    z-index: 6;
    top: 16%;
    &.left {
        left: 20px; }
    &.right {
        right: 20px;
        @include r(1600) {
            width: 556px;
            height: auto;
            top: 19%; }
        @include r(1400) {
            width: 400px;
            top: 27%;
            right: 11%; }
        @include r(1280) {
            z-index: 0; } }
    @include rmin(1025) {
        position: absolute; }
    @include r(1024) {
        width: 43%;
        top: 236px;
        height: auto;
        max-width: 90%; } }

.team-member-text {
    font-size: 18px;
    color: white;
    line-height: 28px;
    top: 68%;
    left: 50%;
    max-width: 40%;
    @include r(1024) {
        top: 215px;
        left: 3%;
        width: 100%;
        max-width: 90%; }
    @include rmin(1025) {
        position: absolute; } }

.team-member-title {
    font-size: 36px;
    font-family: $fontV;
    color: #EF436E;
    margin-bottom: 0.5em;
    text-transform: uppercase;
    line-height: 1.2;
    @include r(767) {
        font-size: 28px; } }

.team-member__info {
    @include r(1024) {
        display: flex;
        flex-flow: row wrap;
        position: relative;
        padding: 150px 20px 30px;
        .mob-right,
        .mob-left {
            flex: 1 1 50%;
            max-width: 50%;
            position: relative; }
        .team-member-img-small {
            width: 100%;
            & ~ .team-member-img-full {
                margin-top: 70px; } }
        .team-member-text {
            padding-left: 20px; } }
    @include r(767) {
        overflow-y: auto;
        max-height: 100vh;
        padding-top: 85px;
        .mob-right,
        .mob-left {
            flex: 1 1 100%;
            max-width: 100%;
            position: relative; } } }

.people {
    transition: transform .3s ease-in-out;
    .top-member-mob {
        top: 16vh;
        width: 100%;
        z-index: 15;
        .member-prev,
        .member-pseudo-prev {
            margin-left: 10px; }
        .member-next,
        .member-pseudo-next {
            margin-right: 10px; } } }

.people-1 {
    .team-member-img-small.right {
        @include rmin(1500) {
            width: 400px;
            right: 13%;
            top: 19%; }
        @include r(767) {
            margin: auto;
            img {
                margin: auto; } } } }

.people-2 {
    .team-member-img-small.right {
        right: 11%;
        width: 400px;
        top: 5%;
        z-index: 0;
        @include rIn(1025, 1500) {
            display: none; }
        @include rIn(1501, 1599) {
            right: 1%;
            width: 319px;
            top: 12%;
            img {
                width: 100%; } }
        @include rmin(1600) {
            width: 415px;
            img {
                width: 100%; } }
        @include r(1024) {
            position: absolute;
            top: 0;
            z-index: 0;
            width: 100%; }
        @include r(1023) {
            z-index: -1; }
        @include r(767) {
            width: 100%;
            position: static;
            margin: 23px auto 0;
            transform: none; } }
    .team-member-img-full {
        left: 7%;
        @include r(767) {
            display: none; } }
    .team-member-text {
        @media (min-width: 1280px) and (max-height: 800px) {
            max-width: 55%;
            left: 42%; }
        @include r(767) {
            width: 100%;
            max-width: 100%; } } }

.people-3 {
    .team-member-img-small.right {
        @include rIn(1051, 1601) {
            width: 620px;
            height: auto;
            top: 0%;
            z-index: 0;
            right: 10%; }
        @include rIn(1024, 1050) {
            top: -16%;
            right: -12%;
            width: 422px;
            max-width: none; }
        @include r(1023) {
            z-index: -1; }
        @media (min-width: 1280px) and (max-height: 801px) {
            width: 550px !important;
            right: 0 !important; }
        @media (min-width: 1500px) and (max-height: 750px) {
            width: 510px !important;
            right: 0 !important; } }
    .team-member-text {
        @media (min-width: 1500px) and (max-height: 750px) {
            max-width: 55%;
            width: 55%;
            left: 40%; }
        @media (min-width: 1279px) and (max-width: 1499px) and (max-height: 750px) {
            max-width: 50%;
            width: 50%;
            left: 47%; }
        @include rIn(768, 1023) {
            padding-top: 100px; }
        @include r(767) {
            padding: 40px 0 0;
            margin: auto; } } }
// +r(1024)
//     position: absolute
//     top: 0
//     width: 100%
//     transform: scale(1.4)
.people-4 {
    .team-member-img-small.right {
        position: absolute;
        top: 0;
        z-index: 0;
        @include rIn(1023, 1050) {
            top: -17%; }
        @include rIn(768, 1023) {
            img {
                min-width: 400px; } }
        @include r(767) {
            margin: auto;
            max-width: 100%;
            display: block; } }
    .team-member-img-full.left {
        margin-top: 122px;
        @include r(1023) {
            margin-top: 213px; }
        @include r(767) {
            display: none; } }
    .team-member-text {
        @media (min-width: 1280px) and (max-height: 801px) {
            margin-top: -100px; }
        @include rIn(768, 1023) {
            padding-top: 140px; } }
    .mob-left {
        @include r(1023) {
            padding-right: 40px; }
        @include r(767) {
            margin-top: 40px;
            padding-right: 0; } } }

.people-5,
.people-7 {
    .team-member-img-small.right,
    .team-member-img-small.left {
        @include r(1600) {
            max-width: 64vh;
            z-index: 0; } } }

.people-6 {
    .team-member-img-full {
        width: 100%; } }

.people-7 {
    .team-member-text {
        @include r(1370) {
            top: 55%;
            left: 36%; } } }

.people-8 .team-member-img-small.right {
    @include r(1600) {
        top: 19%;
        max-width: 60vh;
        right: 10%;
        z-index: 0; }
    @include rmin(1800) {
        top: 20%;
        right: 20%; } }

.start-slider-people.people-slider-wrapper {
    width: 800vw;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate3d(0, 100%, 0);
    transition: transform .4s ease-in-out;
    z-index: 99;
    .people {
        width: 100vw; }
    [data-member="1"] {
        left: 0; }
    [data-member="2"] {
        left: 100vw; }
    [data-member="3"] {
        left: 200vw; }
    [data-member="4"] {
        left: 300vw; }
    [data-member="5"] {
        left: 400vw; }
    [data-member="6"] {
        left: 500vw; }
    [data-member="7"] {
        left: 600vw; }
    [data-member="8"] {
        left: 700vw; } }

.start-slider-works.portfolio-slider-wrapper {
    width: 700vw;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate3d(0, 100%, 0);
    transition: transform .4s ease-in-out;
    z-index: 99;
    .portfolio-item {
        width: 100vw; }
    [data-work="1"] {
        left: 0; }
    [data-work="2"] {
        left: 100vw; }
    [data-work="3"] {
        left: 200vw; }
    [data-work="4"] {
        left: 300vw; }
    [data-work="5"] {
        left: 400vw; }
    [data-work="6"] {
        left: 500vw; }
    [data-work="7"] {
        left: 600vw; } }

.team-member-credo {
    font-size: 40px;
    position: absolute;
    text-align: center;
    width: 102%;
    left: -1%;
    bottom: 0;
    z-index: 10;
    padding: 20px;
    font-family: $fontV;
    box-shadow: inset -15px -43px 80px black;
    @include r(1024) {
        display: none; } }

.people-1 {
    @include rmin(1025) {
        .team-member-img-full {
            img {
                width: 720px; } } }
    @include r(767) {
        .mob-left {
            margin-top: 40px; } } }

.people-2 {
    @include rmin(1025) {
        .team-member-name {
            position: absolute;
            top: -152px;
            left: calc(50% - 303px);
            padding-top: 252px; }
        .team-member-img-full {
            img {
                width: 365px; } }
        .team-member-img-small {
            top: 3%;
            width: 440px; }
        .team-member-text {
            top: auto;
            bottom: 100px; } }
    .team-member-img-small.right {
        @include rmin(1800) {
            width: 520px; }
        @include r(1023) {
            left: 50%;
            transform: translateX(-50%);
            padding-left: 27px;
            img {
                width: 100%; } } } }

.people-3,
.people-6,
.people-8 {
    @include rmin(1025) {
        .team-member-name {
            position: absolute;
            top: -152px;
            left: calc(50% - 303px);
            padding-top: 252px; }
        .team-member-img-full {
            img {
                width: 380px; } }
        .team-member-img-small {
            top: 3%;
            width: 550px; }
        .team-member-text {
            top: auto;
            bottom: 100px; } } }

.people-3 {
    .team-member-img-full.left {
        @include rmin(1280) {
            left: 7%; } }
    @include rmin(1025) {
        .team-member-img-small {
            width: 42%; } }
    @include r(1025) {
        .team-member-img-small {
            position: absolute; } }

    @include r(767) {
        .team-member-img-small {
            position: static;
            width: 100%; }
        .team-member-img-full {
            display: none !important; }
        .mob-right {
            order: 5;
            max-width: 100%;
            padding: 0;
            margin-top: 186px; } }
    .team-member-img-small.right {
        @include rIn(768, 1023) {
            & > img {
                max-width: 483px;
                margin: auto;
                position: absolute;
                left: -82px;
                top: -69px; } }
        @include r(767) {
            max-width: 100%;
            margin: auto;
            position: absolute;
            left: 50%;
            top: 10px;
            transform: translateX(-50%); } }
    .team-member-img-full.left {
        @include r(1023) {
            margin-top: 138px; } }
    .team-member__info {
        @include rIn(768, 1023) {
            .mob-right {
                padding-left: 40px; } } } }

.people-4 {
    .team-member-img-full {
        @include rmin(1025) {
            img {
                width: 780px; } }
        @include r(1400) {
            img {
                width: 545px; } }
        @media (min-width: 1050px) {
            &.left {
                left: -74px;
                bottom: 50px; } } }
    .team-member-img-small.right {
        @include rmin(1280) {
            top: 140px;
            right: 7%; }
        @media (min-width: 1050px) and (max-height: 899px) {
            top: 78px;
            right: -1%; } }
    .team-member-text {
        @media (min-width: 1500px) and (min-height: 750px) and (max-height: 899px) {
            top: 56%; }
        @media (min-width: 1400px) and (min-height: 750px) and (max-height: 900px) {
            top: 56%; }
        @media (min-width: 1279px) and (max-height: 749px) {
            width: 55%;
            max-width: 55%;
            left: 44%;
            top: auto;
            bottom: 100px; } }
    @include r(767) {
        .team-member-img-small.right {
            position: static;
            margin-bottom: -76px; } } }

.people-5 {
    @include rmin(1025) {
        .team-member-img-full {
            img {
                width: 340px; } }
        .team-member-text {
            max-width: 300px; } }
    @include rmin(1600) {
        .team-member-text {
            left: 35%;
            max-width: 300px; } }
    @include r(1600) {
        .team-member-text {
            left: calc(36% - 150px); } } }

.people-6 {
    @include rmin(1025) {
        .team-member-img-full {
            img {
                width: 740px; } } }
    @include r(1600) {
        .team-member-img-full {
            img {
                width: 545px; } } }
    .team-member-img-small.right {
        max-width: 60vh;
        z-index: 0;
        right: 7%;
        top: 17%;
        @include r(1300) {
            top: 19%; }
        @media (min-width: 1500px) and (max-height: 750px) {
            right: 3%;
            top: 12%; } } }

.people-7 {
    @include rmin(1025) {
        .team-member-img-full {
            right: 4%;
            img {
                width: 340px; } }
        .team-member-text {
            max-width: 273px;
            top: 43%; } } }

.portfolio {
    .inner {
        display: flex;
        flex-flow: column wrap; }
    .prev-page {
        right: 43%;
        top: 7%;
        @media (min-width: 1024px) and (max-height: 750px) {
            right: 43%;
            top: 1%;
            z-index: 10; }
        .bottom-icon,
        .top-icon {
            display: inline-block;
            vertical-align: middle; }
        .top-icon img {
            width: 120px; }
        .left-text {
            display: inline-block;
            vertical-align: middle;
            font-family: $fontV;
            font-size: 22px;
            color: white;
            font-weight: 700;
            margin-left: 10px; } }
    .next-page {
        top: 10%;
        right: 30px;
        text-align: right;
        .left-text {
            display: block;
            font-family: $fontV;
            font-size: 30px; }
        .bottom-icon img {
            transform: rotate(90deg); } }
    .rocket {
        top: 28%;
        left: 39%;
        transform: rotate(45deg);
        @include r(1023) {
            display: none; } } }

.portfolio-small {
    position: absolute;
    top: 13%;
    left: 0;
    z-index: 0;
    width: 100%; }

.to-row-3 {
    display: flex;
    flex-flow: row wrap;
    // padding-top: 9%
    max-width: 94%;
    margin: auto;
    .col {
        max-width: 33.3333%;
        flex: 1 1 33.3333%;
        max-height: 310px;
        z-index: 7;
        &:last-child {
            position: relative;

            &::after {
                content: '';
                z-index: -1;
                position: absolute;
                bottom: -6px;
                @include rmin(1500) {
                    bottom: -30px; }
                @include rmin(1800) {
                    bottom: -74px; }
                right: 10%;
                width: 119px;
                height: 125px;
                background-image: url('../img/stun.png');
                background-repeat: no-repeat;
                transform: rotate(150deg); } }
        & img {
            @include r(1290) {
                transform: scale(1.4); } } } }

.two-work {
    position: relative;
    z-index: 1;
    height: 100%;
    a:not(:last-child) {
        position: absolute;
        width: 90%;
        height: 85%;
        z-index: 1;
        left: 5%;
        z-index: 1;
        @include rmin(1800) {
            height: 100%; } }
    a:last-child {
        position: absolute;
        width: 90%;
        height: 82%;
        bottom: -5%;
        right: 0;
        z-index: 2;
        @include rmin(1800) {
            height: 85%; }
        img {
            margin-top: -10%; } }
    a {
        overflow: hidden;
        &:hover {
            z-index: 3; } } }

.two-works-title {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    z-index: 4; }

.rocket-free-portfolio {
    top: 26%;
    left: 38%;
    transform: rotate(30deg); }

.portfolio-item {
    .inner {
        display: flex;
        align-items: center;
        @include r(1279) {
            flex-flow: row wrap; } }
    .prev-page {
        z-index: 15;
        .bottom-icon {
            display: inline-block;
            vertical-align: middle;
            img {
                transform: rotate(-90deg); } }
        .top-icon {
            display: inline-block;
            vertical-align: middle;
            font-size: 30px;
            color: white;
            margin-left: 10px; }
        @include r(1279) {
            display: none; } }
    .only-close {
        top: 40px;
        right: 40px;
        z-index: 15;
        img {
            width: 75px;
            height: 75px; }
        @include r(767) {
            top: 20px;
            right: 20px;
            img {
                width: 40px;
                height: 40px; } } }
    .portfolio__title {
        background-image: url(../img/portfolio-item-title-bg.png);
        background-size: contain;
        position: absolute;
        top: 0;
        right: 20%;
        width: 343px;
        height: 200px;
        z-index: 9;
        padding-top: 98px;
        text-align: center;
        font-family: $fontV;
        font-size: 30px;
        color: #ABDFD5;
        user-select: none;
        @media (min-width: 1024px) and (max-height: 750px) {
            top: -32px; }
        @include r(1279) {
            background-image: none;
            display: block;
            text-align: center;
            position: relative;
            width: 100%;
            right: 0;
            z-index: 0; }
        @include r(1023) {
            max-height: 140px; }
        @include r(767) {
            height: 156px;
            padding-top: 21px;
            top: 0;
            margin: 20px auto 40px; } }
    .portfolio-elem {
        display: block;
        position: absolute;
        left: 0;
        top: 10%;
        width: 100%;
        @include r(1279) {
            display: none; } }
    .rocket {
        bottom: 19%;
        left: 54%;
        transform: rotate(-25deg);
        width: 150px;
        @include r(1025) {
            display: none; } }
    .plane-img {
        position: absolute;
        top: -5%;
        right: -6%;
        height: 178px;
        @include r(1025) {
            display: none; } }
    .top-member-mob {
        @include rIn(768, 1279) {
            margin-top: -120px; }
        @include r(1023) {
            img {
                margin: auto; } }
        @include rIn(1023, 1050) {
            position: absolute;
            top: 50%;
            left: 0;
            z-index: 10;
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex: 1 1 100%;
            flex-flow: row nowrap;
            align-items: flex-start;
            height: 80px;
            .member-prev,
            .member-pseudo-prev,
            .member-next,
            .member-pseudo-next {
                display: block;
                width: 50px;
                flex: 1 1 50px;
                max-width: 100px;
                border: none;
                background-color: transparent;
                img {
                    width: 50px; } }
            .member-prev img,
            .member-pseudo-prev img {
                transform: rotate(-90deg); }
            .member-next img,
            .member-pseudo-next img {
                transform: rotate(90deg); }
            .member-line {
                flex: 1 1 calc(100% - 100px);
                max-width: calc(100% - 100px); }
            .member-prev,
            .member-pseudo-prev {
                text-align: left;
                padding-left: 30px;
                max-width: 100px; }
            .member-next,
            .member-pseudo-next {
                text-align: right;
                padding-right: 30px;
                max-width: 100px; } }
        @include r(767) {
            margin-top: -186px; } } }

.section-free.portfolio-item {
    .inner {
        @include rIn(1024, 1050) {
            &::after {
                content: '';
                display: block;
                position: absolute;
                width: 50px;
                height: 50px;
                z-index: 10;
                right: 90px;
                top: 350px;
                background-image: url('../img/move_vertical_arrows_blue.png');
                background-size: cover;
                animation-name: move-vert;
                animation-duration: 10s;
                animation-iteration-count: infinite; } }
        @include r(767) {
            &::after {
                content: '';
                display: block;
                position: absolute;
                width: 50px;
                height: 50px;
                z-index: 10;
                right: 26px;
                top: 197px;
                background-image: url('../img/move_vertical_arrows_blue.png');
                background-size: cover;
                animation-name: move-vert;
                animation-duration: 10s;
                animation-iteration-count: infinite; } } }
    .portfolio-info {
        @include rmin(1700) {
            .left {
                min-width: 800px; } }
        @include r(1279) {
            padding-top: 0;
            margin-top: -50px;
            flex-flow: row wrap;
            .left,
            .right {
                padding: 30px;
                width: 100%;
                max-width: 100%;
                flex: 1 1 100%;
                img {
                    max-width: 100%; } } }
        @include r(1050) {
            max-height: calc(100vh - 200px);
            overflow-y: auto;
            max-width: 80%;
            margin: auto; }

        @include r(767) {
            margin-top: -90px;
            max-width: 80%;
            padding: 0;
            max-height: calc(100vh - 130px);
            .left,
            .right {
                padding: 10px; }
            .right {
                padding-top: 30px; } } } }

.portfolio-info {
    padding: 50px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    position: relative;
    z-index: 2;
    @include r(767) {
        padding: 10px; }
    .left {
        flex: 1 1 500px;
        padding-right: 30px;
        img {
            width: auto;
            display: block; } }

    .right {
        padding-top: 100px;
        flex: 1 1 30%;
        max-width: 30%;
        margin-right: 10%;
        min-width: 300px;
        @include r(1023) {
            margin-right: 0; }
        @include r(767) {
            min-width: 1%; } }
    .right-inner {
        background-image: url('../img/portfolio-text-bg.jpg');
        background-size: contain;
        background-repeat: no-repeat;
        padding: 45px;
        color: white;
        width: 385px;
        height: 586px;
        font-size: 15px;
        @include r(1279) {
            background-image: none;
            padding: 0;
            width: 100%;
            height: auto; }
        .subtitle {
            font-size: 17px;
            text-transform: uppercase;
            margin-bottom: 20px;
            &:not(:first-child) {
                margin-top: 20px; } } }
    @media (min-width: 1024px) and (max-height: 750px) {
        align-items: center;
        .left {
            max-height: 59vh;
            position: relative;
            top: -100px; }
        .right-inner {
            margin-bottom: -40px; } } }

.item1 .portfolio-info {
    .left {
        padding-left: 150px;
        flex: 1 1 100%;
        @include rh(750) {
            top: 0; }
        img {
            @include r(1279) {
                display: block;
                width: 100%;
                position: static; }

            &:not(:last-child) {
                max-height: 32vh;
                margin-bottom: 3vh;
                margin-left: auto;
                @include r(1279) {
                    display: none; } } }
        .animate3 {
            position: absolute;
            bottom: -5%;
            right: 48%;
            width: 600px;
            max-width: 55%;
            @include rIn(1024, 1279) {
                position: static;
                max-width: 400px;
                margin: 0 auto -65px; }
            @include r(1023) {
                position: static;
                margin-bottom: -60px; } } } }

.item2 .portfolio-info {
    .left {
        padding-top: 50px;
        .animate1 {
            margin-bottom: 3vh; } } }

.item3 {
    .portfolio-info {
        align-items: center;
        .left {
            @include rh(750) {
                top: 0; }
            img {
                display: inline-block;
                width: 45%;
                margin-left: 3%; } } } }

.item4 {
    .portfolio-info {
        align-items: flex-end;
        .left {
            @include r(1279) {
                margin: auto;
                max-width: 400px !important; } } }
    .left {
        text-align: center;
        @include rmin(1300) {
            min-width: 650px; }
        img {
            max-width: 470px;
            margin: 0 auto 30px;
            @include r(767) {
                margin-bottom: 20px;
                &:last-child {
                    margin-bottom: 0; } } } } }

.item5 {
    .portfolio-info {
        align-items: center;
        .left img {
            @include rIn(1024, 1279) {
                margin: 0 auto -50px;
                max-width: 400px !important; } } }
    .left img {
        margin: 86px 0 0;
        @include r(1023) {
            margin: 0; } } }

.item6 {
    .portfolio-info {
        align-items: center;
        .left {
            @include r(1300) {
                top: 0; } } }
    .left {
        min-width: 660px;
        @media (min-width: 1024px) and (max-height: 810px) {
            top: 0; }
        img {
            max-width: 427px;
            margin: auto;
            @include r(1300) {
                max-width: 330px; }
            @include rIn(1024, 1279) {
                max-height: 400px; }
            @media (min-width: 1024px) and (max-height: 810px) {
                max-width: none;
                min-width: 1px;
                max-height: 500px; } }
        @include r(1300) {
            max-width: 50%;
            flex: 1 1 50%;
            min-width: 0;
            padding: 40px; } } }

.item7 {
    .portfolio-info {
        align-items: center;
        .left {
            @include r(1300) {
                top: 0; }
            top: 0; }
        .right-inner {
            @include css-lock(13, 15, 1280, 1980);
            .subtitle {
                font-size: 15px;
                text-align: center; } } }
    .left {
        img {
            width: calc(49% - 30px);
            float: left;
            position: static;
            margin: 0 30px 30px 0;
            @include r(767) {
                width: 100%;
                float: none;
                margin: 0 auto 20px; } }
        &::after {
            content: '';
            display: table;
            width: 100%;
            clear: both; } } }

// .portfolio
//     +rmin(1024)
//         transform: translate3d(0, 100% ,0)
//     +r(1023)
//         display: none !important



/*poma*/
@media screen and (max-width: 1380px) {
    .team-member-text {
        font-size: 16px;
        line-height: 1.2; }

    .people-1 .team-member-img-full img {
        width: 618px; } }

//   .people-4 .team-member-img-full img
//     width: 711px
//     +r(1400)
//         width: 545px

//.people-5 .team-member-img-full img
//  width: 300px

//.people-7 .team-member-img-full img
//  width: 550px

//.people-8 .team-member-img-full img
//  width: 480px

//   .six-screen .subtext
//     font-size: 18px
//     line-height: 1.2

//.third-text-wrapper
//  height: 30%
//  font-size: 18px

//   .second-top-left
//     .custom-text, .custom-list
//       font-size: 18px


//   .second-middle-right .custom-text
//     font-size: 18px

//   .second-top-left .custom-list
//     font-size: 22px

// .lapa-nieb7
//   bottom: 7%
//   left: 45%
//   img
//     width: 397px
// .floor
//   z-index: -5
.team-member:hover {
    z-index: 11; }
// @media screen and (max-width: 1500px)
//   .navigation
//     padding: 50px 0 50px 30px

//   .navigaton__logo
//     margin-bottom: 10px

